import { goldColor, blueColor } from 'staticData/common';
import enEN from 'assets/img/flags/shiny/16/GB.png';
import itIT from 'assets/img/flags/shiny/16/IT.png';
import { email, platform, supportEmail } from './hodlieInfos';

export const languages = ['en-EN', 'it-IT'];

export const languagesString = { 'en-EN': 'English', 'it-IT': 'Italiano' };

export const flags = { 'en-EN': enEN, 'it-IT': itIT };

// PAGE LIST

export const AcceleratorBar = {
  invest: {
    'en-EN': 'Your Investment (Now)',
    'it-IT': 'Il Tuo Investimento (Adesso)'
  },
  bnbDiscount: {
    'en-EN': 'BNB Discount',
    'it-IT': 'Sconto BNB'
  }
};

export const Account = {
  profile: {
    'en-EN': 'Profile',
    'it-IT': 'Profilo'
  },
  profileDescr: {
    'it-IT': 'Controlla i tuoi dati nella sezione profilo.',
    'en-EN': 'Check your data in the profile section.'
  },
  billing: {
    'it-IT': 'Abbonamento',
    'en-EN': 'Subscription'
  },
  billingDescr: {
    'it-IT':
      'Controlla i tuoi dettagli di fatturazione nella sezione Abbonamento.',
    'en-EN': 'Check your billing details in the Subscription section.'
  },
  referral: {
    'it-IT': 'Referral',
    'en-EN': 'Referral'
  },
  referralDescr: {
    'it-IT': 'Invita i tuoi amici e guadagna premi nella sezione Referral.',
    'en-EN': 'Invite your friends and earn rewards in the Referral section.'
  },
  settings: {
    'it-IT': 'Impostazioni',
    'en-EN': 'Settings'
  },
  settingsDescr: {
    'it-IT': 'Modifica le tue preferenze nella sezione Impostazioni.',
    'en-EN': 'Change your preferences in the Settings section.'
  },
  pricing: {
    'it-IT': 'Pricing',
    'en-EN': 'Pricing'
  },
  pricingDescr: {
    'it-IT': 'Scopri i nostri piani e prezzi nella sezione Prezzi.',
    'en-EN': 'Discover our plans and prices in the Pricing section.'
  },
  wallet: {
    'it-IT': 'Gestione Wallet',
    'en-EN': 'Wallet Management'
  },
  walletDescr: {
    'it-IT': 'Configura e gestisci le chiavi del tuo portafoglio.',
    'en-EN': 'Set up and manage your wallet keys.'
  },
  becomeTrader: {
    'it-IT': 'Diventa Private',
    'en-EN': 'Become Private'
  },
  becomeTraderDescr: {
    'it-IT': 'Scopri come diventare un Private nella sezione Diventa Private.',
    'en-EN': 'Learn how to become a Private in the Become a Private section.'
  },
  manifest: {
    'it-IT': 'Manifesto',
    'en-EN': 'Manifest'
  },
  manifestDescr: {
    'it-IT': 'Scopri i nostri principi e valori nel Manifesto.',
    'en-EN': 'Discover our principles and values in the Manifest.'
  },
  tutorial: {
    'it-IT': 'Guida Rapida',
    'en-EN': 'Tutorial'
  },
  tutorialDescr: {
    'it-IT':
      'Scopri come utilizzare il nostro servizio nella sezione Guida Rapida.',
    'en-EN': 'Learn how to use our service in the Tutorial section.'
  },
  faq: {
    'it-IT': 'FAQ',
    'en-EN': 'FAQ'
  },
  faqDescr: {
    'it-IT': 'Trova le risposte alle domande più frequenti nella sezione FAQ.',
    'en-EN': 'Find answers to frequently asked questions in the FAQ section.'
  }
};

export const AddKeys = {
  follow: {
    'en-EN': 'Follow our ',
    'it-IT': 'Segui la nostra guida '
  },
  guide: {
    'en-EN': ' guide ',
    'it-IT': ' '
  },
  toConn: {
    'en-EN': 'to manually connect ',
    'it-IT': 'per connettere manualmente '
  },
  yourWall: {
    'en-EN': 'your wallet',
    'it-IT': 'il tuo portafoglio'
  },
  connect: {
    'en-EN': 'We are almost there, now connect your Account ',
    'it-IT': 'Ci siamo quasi, collega ora il tuo Account '
  },
  wallet: {
    'en-EN': ' wallet',
    'it-IT': ''
  },
  confirm: {
    'en-EN': 'Confirm',
    'it-IT': 'Conferma'
  },
  cancel: {
    'en-EN': 'Cancel',
    'it-IT': 'Annulla'
  },
  checkTitle: {
    'en-EN': 'Wallet has been successfully connected',
    'it-IT': 'Il wallet è stato collegato correttamente'
  },
  checkTitle1: {
    'en-EN': 'Now you can run your first strategy!',
    'it-IT': 'Ora puoi creare la tua prima strategia!'
  },
  checkTitle2: {
    'en-EN': 'but',
    'it-IT': 'ma'
  },
  checkTitle3: {
    'en-EN': ' you do not have enough liquidity ',
    'it-IT': ' non hai abbastanza liquidità '
  },
  checkTitle4: {
    'en-EN': 'to launch a strategy.',
    'it-IT': 'per avviare una strategia.'
  },
  goToGuideText: {
    'en-EN': 'Deposit now!',
    'it-IT': 'Deposita ora!'
  },
  goToGuideText1: {
    'en-EN': 'Open your Binance account and make your first deposit.',
    'it-IT': 'Apri il tuo account Binance ed effettua il tuo primo deposito.'
  },
  goToGuideButton: {
    'en-EN': 'Go to the guide',
    'it-IT': 'Vai alla guida'
  },
  waitingText: {
    'en-EN': 'Have you already deposited and are waiting?',
    'it-IT': 'Hai già depositato e stai aspettando?'
  },
  waitingText1: {
    'en-EN':
      'You can try our virtual mode for free for 7 days. No card required.',
    'it-IT':
      'Puoi provare gratuitamente la nostra modalità virtuale per 7 giorni. Nessuna carta richiesta.'
  },
  waitingButton: {
    'en-EN': 'Try virtual mode',
    'it-IT': 'Prova la modalità virtuale'
  },
  create: {
    'en-EN': 'Run now',
    'it-IT': 'Crea ora'
  },
  fastApiConnect: {
    'en-EN': 'Connect Your account',
    'it-IT': 'Connetti il tuo account'
  },
  or: {
    'en-EN': 'Otherwise',
    'it-IT': 'Oppure'
  },
  notButton: {
    'en-EN': "Can't connect your account?",
    'it-IT': 'Non riesci a collegare il tuo account?'
  },
  errorMsg: {
    '000001': {
      'en-EN':
        'The coonnection to your Binance account was unsuccessful. Please try again from another device or connect the account manually. If the problem persists, contact support@hodlie.net.',
      'it-IT':
        "Il tentativo di connessione al tuo account Binance non è andato a buon fine. Riprova da un altro dispositivo oppure connetti l'account manualmente. Se il problema persiste contatta support@hodlie.net"
    },
    '001512': {
      'en-EN':
        'You have already used the fast connection once. Please contact support@hodlie.net or connect the wallet manually',
      'it-IT':
        'Hai già utilizzato la connessione veloce una volta. Connetti il wallet manualmente o contatta il supporto a support@hodlie.net'
    },
    '001464': {
      'en-EN':
        'You have reached the API key limit. Please delete the unused ones to create a new API key',
      'it-IT':
        'Hai raggiunto il massimo numero di API key. Elimina almeno una chiave API e riprova'
    },
    '050010': {
      'en-EN':
        'API key generation failed. Please contact support@hodlie.net or connect the wallet manually',
      'it-IT':
        'Generazione della chiave API fallita. Connetti il wallet manualmente o contatta il supporto a support@hodlie.net'
    },
    '001461': {
      'en-EN':
        'Before connecting the API, you need to make a deposit in your Spot Wallet and try again.',
      'it-IT':
        "Prima di connettere l'API è necessario effettuare un deposito nel tuo Wallet Spot."
    },
    200003904: {
      'en-EN':
        'You need to complete the Intermediate Identity Verification on Binance. Please complete the step on your Binance account and try again',
      'it-IT':
        "Devi completare la Verifica intermedia dell'identità su Binance. Completa questo step su Binance e riprova."
    },
    200003976: {
      'en-EN':
        'Your identity verification on Binance has been rejected. Please complete the identity verification on your Binance Account and try again.',
      'it-IT':
        "La verifica dell'identità (KYC) su Binance è stata rifiutata. Completa la verifica su Binance e riprova."
    },
    200003903: {
      'en-EN':
        'Identity verification not completed yet on Binance. Please verify your identity and try again.',
      'it-IT':
        "Verifica dell'identità (KYC) non completata su Binance. Verifica la tua identità su Binance e riprova."
    },
    200003905: {
      'en-EN':
        'Until the Binance identity verification (KYC) review is complete, you cannot connect.',
      'it-IT':
        "Fino al completamento della revisione della verifica dell'identità (KYC) su Binance non è possibile effettuare il collegamento."
    },
    200004451: {
      'en-EN':
        'You need to open Binance and Accept the new Terms & Conditions before proceeding.',
      'it-IT':
        'Errore nella connessione API. Devi prima aprire Binance e accettare i nuovi termini e condizioni. Una volta fatto, riprova'
    }
  }
};

export const AliveBotsTable = {
  status: {
    'en-EN': 'Status',
    'it-IT': 'Stato'
  },
  active: {
    'en-EN': 'active',
    'it-IT': 'attivo'
  },
  paused: {
    'en-EN': 'paused',
    'it-IT': 'pausa'
  },
  error: {
    'en-EN': 'error',
    'it-IT': 'errore'
  }
};

export const AddLiquidity = {
  title: {
    'en-EN': 'Select the amount of capital to add',
    'it-IT': 'Seleziona la quantità di capitale da aggiungere'
  },
  titleBots: {
    'en-EN': 'Add the capital of your Agents to your AI Portfolio',
    'it-IT': 'Aggiungi il capitale dei tuoi Agents sul tuo AI Portfolio'
  },
  subTitle: {
    'en-EN':
      'As per the communication on 20/11/2024, the operations of the Agents will be interrupted on December 16th. Add the capital of your Agents to your AI Portfolio now to avoid service interruptions.',
    'it-IT': `Come da comunicazione del 20/11/2024, il 16 dicembre l'operatività degli Agents verrà interrotta. <br>Aggiungi ora il capitale dei tuoi Agents al tuo AI Portfolio per evitare interruzioni di servizio.`
  },
  notAllowed: {
    'en-EN': 'Adding liquidity is not allowed',
    'it-IT': 'Aumento di capitale non disponibile'
  },
  amount: {
    'en-EN': 'Amount',
    'it-IT': 'Quantità'
  },
  total: {
    'en-EN': 'Total',
    'it-IT': 'Totale'
  },
  goBack: {
    'en-EN': 'Back',
    'it-IT': 'Indietro'
  },
  confirm: {
    'en-EN': 'Confirm',
    'it-IT': 'Conferma'
  },
  mi10Dollar: {
    'en-EN':
      'You cannot allocate this coin because you have an equivalent value of less than $10',
    'it-IT':
      'Non puoi allocare questa coin perchè possiedi un controvalore minore di $10'
  },
  minImport: {
    'en-EN':
      'For this coin, you can allocate a minimum equivalent value of $10',
    'it-IT': 'Di questa coin puoi allocare un controvalore minimo di $10'
  },
  minImportList: {
    'en-EN':
      'The following coins must have a minimum equivalent value of $10 to be added: ',
    'it-IT':
      'Le seguenti coin devono avere un controvalore minimo di $10 per essere aggiunte: '
  },
  endMessage: {
    'en-EN':
      'The operation was successful and the increase in capital will be visible in ten minutes.',
    'it-IT':
      "L'operazione è andata a buon fine e l'aumento di capitale sarà visibile entro dieci minuti."
  },
  endTitle: {
    'en-EN': 'Capital increase completed!',
    'it-IT': 'Aumento di capitale effettuato!'
  },
  loadingTitle: {
    'en-EN': 'Loading may take a few seconds...',
    'it-IT': 'Il caricamento potrebbe richiedere qualche secondo...'
  },
  coolDown: {
    'en-EN':
      '*a capital increase on the same AI Agent can be made every 4 days',
    'it-IT':
      '*può essere effettuato un aumento di capitale sullo stesso AI Agent ogni 4 giorni'
  },
  coolDownPortfolio: {
    'en-EN':
      '*a capital increase on the same AI Portfolio can be made every 4 days',
    'it-IT':
      '*può essere effettuato un aumento di capitale sullo stesso AI Portfolio ogni 3 giorni'
  },
  managed: {
    'en-EN': 'Managed',
    'it-IT': 'Gestito'
  },
  botsTitle: {
    'en-EN': 'Select one or more Agents to add capital to your Portfolio',
    'it-IT':
      'Seleziona uno o più Agent per aggiungere capitale al tuo Portfolio'
  },
  botsTitle_add: {
    'en-EN': 'The following AI Agents will be added to your Portfolio',
    'it-IT': 'I seguenti AI Agent verranno aggiunti al tuo Portfolio'
  },
  infoBots: {
    'en-EN':
      'Selecting one or more Agents, they will be removed and the capital available to them will be transferred to the AI Portfolio you are creating. No action is required on your part.',
    'it-IT':
      "Selezionando uno o più Agents, questi saranno eliminati e il capitale a loro disposizione verrà spostato sull'AI Portfolio che stai creando. Non è necessaria alcuna azione da parte tua."
  },
  reasons: {
    no_data: { 'en-EN': 'No data', 'it-IT': 'Nessun dato' },
    virtual_capital_qty: {
      'en-EN': 'The amount entered is outside the permitted limits',
      'it-IT': "L'importo inserito è fuori dai limiti consentiti"
    },
    exchange_availability: {
      'en-EN': 'The amount entered is outside the permitted limits',
      'it-IT': "L'importo inserito è fuori dai limiti consentiti"
    },
    real_capital_limit_reached: {
      'en-EN': 'The amount entered is outside the permitted limits',
      'it-IT': "L'importo inserito è fuori dai limiti consentiti"
    },
    min: {
      'en-EN': 'The amount entered is less than the allowed limit',
      'it-IT': "L'importo inserito minore del lmite consentito"
    },
    wait_for_cooldown: {
      'en-EN':
        'To make a next capital increase it is necessary to wait until the following date: ',
      'it-IT':
        'Per affettuare un prossimo aumento di capitale è necessario attendere fino alla seguente data: '
    },
    bot_not_active: {
      'en-EN':
        'Your AI Agent must be active in order to make a capital increase',
      'it-IT':
        'Il tuo AI Agent deve essere attivo per poter effettuare un aumento di capitale'
    },
    portfolio_not_active: {
      'en-EN':
        'Your AI Portfolio must be active in order to make a capital increase',
      'it-IT':
        'Il tuo AI Portfolio deve essere attivo per poter effettuare un aumento di capitale'
    },
    existing_pending_event: {
      'en-EN':
        'A capital increase event is currently in progress, please wait for its conclusion',
      'it-IT':
        'Attualmente è in corso un evento di aumento di capitale, attendere la sua conclusione'
    },
    bot_type_limit_reached: {
      'en-EN':
        'It is not possible to make a capital increase because the maximum capital limit has been reached on this strategy',
      'it-IT':
        'Non è possibile effettuare un aumento di capitale perchè su questa strategia è stato raggiunto il limite di capitale massimo'
    },
    bot_type_capital_qty: {
      'en-EN':
        'It is not possible to make a capital increase because the maximum capital limit has been reached on this strategy',
      'it-IT':
        'Non è possibile effettuare un aumento di capitale perchè su questa strategia è stato raggiunto il limite di capitale massimo'
    },
    real_capital_qty: {
      'en-EN': 'The amount entered is outside the permitted limits',
      'it-IT': "L'importo inserito è fuori dai limiti consentiti"
    }
  },
  waitText: {
    'en-EN': 'We are loading your portfolio data, please wait a few seconds...',
    'it-IT':
      'Stiamo caricando i dati relativi al tuo portafoglio, attendi qualche secondo...'
  }
};

export const AddPWDForm = {
  title: {
    'en-EN': 'Insert new Password',
    'it-IT': 'Inserisci la nuova Password'
  },
  old: {
    'en-EN': 'Old Password',
    'it-IT': 'Vecchia Password'
  },
  new: {
    'en-EN': 'New Password',
    'it-IT': 'Nuova Password'
  },
  repeatNew: {
    'en-EN': 'Repeat New Password',
    'it-IT': 'Ripeti la Nuova Password'
  },
  button: {
    'en-EN': 'Set password',
    'it-IT': 'Cambia la Password'
  },
  okMsg: {
    'en-EN': 'Awesome!',
    'it-IT': 'Fantastico'
  },
  okMsg1: {
    'en-EN': 'The password has been changed',
    'it-IT': 'La password è stata cambiata'
  },
  noLog: {
    'en-EN':
      'To change your password you must be logged in or you must have made a password change request',
    'it-IT':
      'Per cambiare la tua password devi essere loggato o devi aver effettuato una richiesta di cambio password'
  },
  goTo: {
    'en-EN': 'Go to',
    'it-IT': 'Vai al'
  },
  pwdErr: {
    'en-EN':
      'Password must contain at least 8 char, a lowercase letter, an uppercase letter and a number',
    'it-IT':
      'La password deve contenere almeno 8 caratteri, una lettera minuscola, una lettera maiuscola e un numero'
  },
  passNoMatch: {
    'en-EN': 'Passwords do not match',
    'it-IT': 'Le passwords non corrispondono'
  }
};

export const AddPWDFormSignIn = {
  insertNewPwd: {
    'en-EN': 'Insert new Password',
    'it-IT': 'Inserisci la nuova password'
  },
  invalidCode: {
    'en-EN': 'Invalid Code!',
    'it-IT': 'Codice non valido!'
  },
  invalidCodeMsg: {
    'en-EN': 'The code used is invalid. Click below to get a correct link',
    'it-IT':
      'Il codice utilizzato non è valido. Clicca qui sotto per ottenere un link corretto'
  },
  signIn: {
    'en-EN': 'Sign In',
    'it-IT': 'Registrati'
  },
  newPwd: {
    'en-EN': 'New Password',
    'it-IT': 'Nuova Password'
  },
  repeatNewPwd: {
    'en-EN': 'Repeat New Password',
    'it-IT': 'Ripeti la Nuova Password'
  },
  pwd: {
    'en-EN': 'Password',
    'it-IT': 'Password'
  },
  repeatPwd: {
    'en-EN': 'Repeat Password',
    'it-IT': 'Ripeti Password'
  },
  confirm: {
    'en-EN': 'Confirm',
    'it-IT': 'Conferma'
  },
  loginLink: {
    'en-EN': 'Go to Login',
    'it-IT': 'Vai al Login'
  },
  welcome: {
    'en-EN': 'Welcome to Hodlie',
    'it-IT': 'Benvenuto in Hodlie'
  },
  pwdErr: {
    'en-EN':
      'Password must contain at least 8 char, a lowercase letter, an uppercase letter and a number',
    'it-IT':
      'La password deve contenere almeno 8 caratteri, una lettera minuscola, una lettera maiuscola e un numero'
  },
  passNoMatch: {
    'en-EN': 'Passwords do not match',
    'it-IT': 'Le passwords non corrispondono'
  }
};

export const AddUsernameForm = {
  noMatch: {
    'en-EN': 'Username do not match',
    'it-IT': "L'username non corrisponde"
  },
  usernameLonger: {
    'en-EN':
      'Username must be longer than five characters and can only contain letters and numbers',
    'it-IT':
      "L'username deve essere più lungo di cinque caratteri e può contenere solo lettere e numeri"
  },
  pwdControl: {
    'en-EN':
      'Password should contain at least 8 char, a lowercase letter, an uppercase letter and a number',
    'it-IT':
      'La password deve contenere almeno 8 caratteri, di cui una lettere minuscola, una maiuscola e un numero'
  },
  userNotEqual: {
    'en-EN': 'New username cannot be equal to the previous one',
    'it-IT': 'Il nuovo username non può essere uguale a quello precedente'
  },
  toChange: {
    'en-EN':
      'To change your username you must be logged in or you must have made a username change request',
    'it-IT':
      'Per cambiare il tuo username devi aver effettuato il login o devi aver richiesto un cambio username'
  },
  goToLogin: {
    'en-EN': 'Go to Login',
    'it-IT': 'Vai al Login'
  },
  insNewUsername: {
    'en-EN': 'Insert new Username',
    'it-IT': 'Inserisci il nuovo Username'
  },
  newUsername: {
    'en-EN': 'New Username',
    'it-IT': 'Nuovo Username'
  },
  repUsername: {
    'en-EN': 'Repeat New Username',
    'it-IT': 'Ripeti il nuovo Username'
  },
  setUsername: {
    'en-EN': 'Set username',
    'it-IT': 'Cambia username'
  },
  awesome: {
    'en-EN': 'Awesome!',
    'it-IT': 'Fantastico!'
  },
  changeusr: {
    'en-EN': 'The username has been changed',
    'it-IT': "L'username è stato cambiato"
  },
  here: {
    'en-EN': 'Here you can change your username',
    'it-IT': 'In questa pagina puoi cambiare il tuo username'
  }
};

export const AmbassadorWidget = {
  title: {
    'en-EN': 'Do you want Hodlie for FREE?',
    'it-IT': 'Vuoi Hodlie GRATIS?'
  },
  text: {
    'en-EN':
      "Become an Ambassador! Let's share and bring value together, and you'll get Hodlie for free and unlock exclusive features.",
    'it-IT':
      'Diventa un Ambassador! Condividiamo e portiamo valore insieme, e otterrai Hodlie gratuitamente e sbloccherai funzionalità esclusive.'
  },
  learn: {
    'en-EN': 'Learn more',
    'it-IT': 'Scopri di più'
  }
};

export const Announcements = {
  title: { 'en-EN': 'Announcements', 'it-IT': 'Annunci' },
  children: [
    {
      title: {
        'en-EN': 'HODLIE BETA IS OUT NOW',
        'it-IT': 'LA BETA DI HODLIE È ORA DISPONIBILE'
      },
      date: {
        'en-EN': '20th February 2023',
        'it-IT': '20 Febbraio 2023'
      },
      text: {
        'en-EN':
          'Welcome aboard, <font color=' +
          goldColor +
          '>Hodlier</font>! <br /><br />You are officially a beta tester, now you can try our automated trading platform.<br /> Currently,<font color=' +
          goldColor +
          '>  you cannot yet connect your crypto wallet</font> to our app, but you can still trade with a Virtual Wallet and backtest our strategies. The initial amount deposited on the virtual wallet is <font color=' +
          goldColor +
          '>$10,000.00</font> and the maximum number of Agents active at the same time is <font color=' +
          goldColor +
          '>5</font>.<br /> <br /> If you have any questions, please see the <a href="/faq">FAQ</a> and <a href="/support">Support</a> pages, and remember, you can provide us with feedback at any time, it will be appreciated.<br /> <br />Enjoy your trading with Hodlie',
        'it-IT':
          'Benvenuto a bordo, <font color=' +
          goldColor +
          '>Hodlier</font>! <br /><br />Sei ufficialmente un beta tester, ora puoi provare la nostra piattaforma di trading automatizzato.<br /> Al momento,<font color=' +
          goldColor +
          "> non puoi ancora connettere il tuo portafoglio di criptovalute</font> alla nostra app, ma puoi comunque fare trading con un Portafoglio Virtuale e testare le nostre strategie. L'importo iniziale depositato sul portafoglio virtuale è di <font color=" +
          goldColor +
          '>$10,000.00</font> e il numero massimo di Agents che puoi attivare contemporaneamente è <font color=' +
          goldColor +
          '>5</font>.<br /> <br /> Se hai domande, consulta le pagine <a href="/faq">FAQ</a> e <a href="/support">Supporto</a>, e ricorda che puoi fornirci i tuoi feedback in qualsiasi momento!.<br /> <br />Buon trading con Hodlie'
      },
      active: true
    },
    {
      title: {
        'en-EN': 'SOME UPDATES',
        'it-IT': 'ALCUNI AGGIORNAMENTI'
      },
      date: {
        'en-EN': '17th March 2023',
        'it-IT': '17 Marzo 2023'
      },
      text: {
        'en-EN':
          'Hello Hodliers, here we are with the first update of the platform.<br/>Hope you’re enjoying Hodlie and all of its functionalities. Let’s discover the new ones together:<br/><br/> - <font color=' +
          goldColor +
          '>BOT DETAILS</font>: now you can click on the bot icon on the list of your AI Agents and follow closer all the performance and the single trades, step by step.<br/> - <font color=' +
          goldColor +
          '>REWARDS</font>: do stuff, earn rewards, trade free! Check the new section!<br/> - <font color=' +
          goldColor +
          '>ANNOUNCEMENTS</font>: stay tuned with the platform by taking a look at all of the updates.<br/><br/>Additional features:<br/>- You can now run up to <font color=' +
          goldColor +
          ">10 Bots</font> on your Virtual Wallet;<br/>- If you like, you can change your username in the <a href='/account/settings'>Settings</a> section;<br/> - <font color=" +
          goldColor +
          ">Referral code</font> is now active. You can find it on your Profile or <a href='/rewards'>Rewards</a> section.<br/><br/>Stay tuned!",
        'it-IT':
          "Ciao Hodliers, eccoci qui con il primo aggiornamento della piattaforma.<br/>Speriamo che vi stiate divertendo su Hodlie e che stiate apprezzando tutte le sue funzionalità. Scopriamo insieme cosa c'è di nuovo:<br/><br/> - <font color=" +
          goldColor +
          ">DETTAGLIO BOT</font>: ora puoi cliccare sull'icona del bot nella lista dei tuoi AI Agents e seguire nel dettaglio tutte le performance e le singole operazioni effettuate, passo dopo passo.<br/> - <font color=" +
          goldColor +
          '>PREMI</font>: avvia i tuoi bot, guadagna e divertiti a fare trading gratuito! Controlla la nuova sezione!<br/> - <font color=' +
          goldColor +
          ">ANNUNCI</font>: rimani sempre aggiornato sulla piattaforma dando un'occhiata a tutti gli aggiornamenti.<br/><br/>Ulteriori funzionalità:<br/>- Ora puoi avviare fino a <font color=" +
          goldColor +
          ">10 Bot</font> contemporaneamente sul tuo Wallet Virtuale;<br/>- Se lo desideri, puoi cambiare il tuo nome utente nella sezione <a href='/account/settings'>Impostazioni</a>;<br/> - Il <font color=" +
          goldColor +
          ">referral code</font> è ora attivo. Puoi trovarlo nel tuo Profilo o nella sezione <a href='/rewards'>Premi</a>.<br/><br/>Rimani sintonizzato per non perderti le prossime novità!"
      },
      active: true
    },
    {
      title: {
        'en-EN': 'BETA RELEASE 2.0',
        'it-IT': 'BETA RELEASE 2.0'
      },
      date: {
        'en-EN': '21st April 2023',
        'it-IT': '21 Aprile 2023'
      },
      text: {
        'en-EN':
          '<font color=' +
          goldColor +
          ">HELLO</font> Hodliers, what's up? Here we are with the second update of the platform: finally Hodlie is now available in <font color=" +
          goldColor +
          '>MULTI-LANGUAGE</font>. You will now be able to select the default language in the top right menu (Italian or English, at the moment).</br></br>In addition, the following features have been added:</br></br> - <font color=' +
          goldColor +
          '>Dynamic Dashboard</font>:  all the data available in the dashboard are no longer static but constantly updated in real time without the need to reload the page;</br></br> - <font color=' +
          goldColor +
          ">Bot deletion</font>: by clicking on the Active Agents icon, in addition to the ability to pause a bot, it is now possible to permanently delete the bot and recover the virtual portfolio exposure, to then re-invest in another strategy.</br></br>If you have any questions, don't hesitate to send an email to <a href='mailto:support@hodlie.net'>hello@hodlie.net</a>.<br/><br/>Happy Trading!",
        'it-IT':
          '<font color=' +
          goldColor +
          '>CIAO</font> Hodliers, come va? Eccoci qui con il secondo aggiornamento della piattaforma: finalmente Hodlie è disponibile ora in <font color=' +
          goldColor +
          '>MULTILINGUA</font>. Da oggi potrai selezionare la lingua di default nel menù in altro a destra (Italiano o Inglese, al momento).</br></br>Inoltre sono state aggiunte le seguenti funzionalità:</br></br> - <font color=' +
          goldColor +
          '>Dashboard dinamica</font>: tutti i dati disponibili nella dashboard ma si aggiornano costantemente in tempo reale, senza la necessità di ricaricare la pagina;</br></br> - <font color=' +
          goldColor +
          ">Cancellazione del Bot</font>: cliccando sull'icona degli Agents attivi, oltre alla possibilità di mettere in pausa un Agent, è possibile ora cancellarlo definitivamente e recuperare l'esposizione del portafoglio virtuale, per reinvestire quindi in un'altra strategia.</br></br>Per qualsiasi domanda, non esitare a scrivere una mail a <a href='mailto:hello@hodlie.net'>hello@hodlie.net</a>.<br/><br/>Happy Trading!"
      },
      active: true
    },
    {
      title: {
        'en-EN': 'HODLIE IS OUT NOW',
        'it-IT': 'HODLIE È ORA DISPONIBILE'
      },
      date: {
        'en-EN': '25th August 2023',
        'it-IT': '25 Agosto 2023'
      },
      text: {
        'en-EN':
          "Hello Hodliers, it's with great excitement that we announce a significant milestone in our journey: <font color=" +
          goldColor +
          '>Hodlie Beta is making way for the final version of Hodlie</font>, and you will be the only ones to preview it!</br></br><font color=' +
          goldColor +
          '>TRADING ON BINANCE:</font> Connect your Binance wallet via API and start using our strategies, the virtual mode will still be available!</br></br><font color=' +
          goldColor +
          ">NEW INTERFACE:</font> In addition to the dashboard, split between “Virtual” and “Binance”, we've introduced a Home page to give you a clear and immediate view of your wallet. </br></br><font color=" +
          goldColor +
          ">PRO SUBSCRIPTION:</font> For every 100 HODS accumulated (rounded up, don't worry), you will receive one free month in addition to the standard one. For example, 427 HODS = 5 months + 1.</br>(Valid only for BETA testers) </br></br><font color=" +
          goldColor +
          ">AMBASSADOR PROGRAM:</font> Invite friends with your referral code and earn HODS that you can spend on the platform to unlock exclusive benefits and features (more updates to come). If you invite 10 friends (as per the terms specified on the “Rewards” page) you'll become an ambassador! ",
        'it-IT':
          'Ciao Hodliers, è con grande entusiasmo che vi annunciamo una tappa fondamentale del nostro viaggio: <font color=' +
          goldColor +
          '>Hodlie Beta lascia oggi spazio alla versione definitiva di Hodlie</font>, e voi sarete i soli a provarla in anteprima!</br></br><font color=' +
          goldColor +
          '>TRADING SU BINANCE:</font> Collega il tuo portafoglio Binance tramite API e inizia ad utilizzare le nostre strategie, la modalità virtuale ovviamente rimane!</br></br><font color=' +
          goldColor +
          '>NUOVA INTERFACCIA:</font> Oltre alla dashboard, divisa tra “Virtuale” e “Binance”, abbiamo introdotto una pagina Home per darvi una visione chiara e immediata del vostro portafoglio da mobile.</br></br><font color=' +
          goldColor +
          '>ABBONAMENTO PRO:</font> Ogni 100 HODS accumulati (arrotondati per eccesso, tranquilli), avrete una mensilità gratuita in aggiunta a quella standard. Ad esempio, 427 HODS= 5 mesi + 1.</br>(Valido solo per i BETA tester)</br></br><font color=' +
          goldColor +
          '>PROGRAMMA AMBASSADOR:</font> Invita amici tramite il tuo referral code. Guadagna HODS che potrai spendere all’interno della piattaforma per sbloccare vantaggi e funzionalità esclusive (seguiranno aggiornamenti). Se inviti 10 amici (secondo i termini specificati alla pagina “Premi”) diventerai un ambassador!'
      },
      active: true
    },
    {
      title: {
        'en-EN': 'ETHEREUM AVAILABLE NOW',
        'it-IT': 'ETHEREUM ADESSO DISPONIBILE'
      },
      date: {
        'en-EN': '17th November 2023',
        'it-IT': '17 Novembre 2023'
      },
      text: {
        'en-EN':
          'Welcome back, Hodlier!<br/> We have important updates on the platform: <br/><br/><font color=' +
          goldColor +
          ">Grizzly on ETH:</font> You can now launch Grizzly on ETHUSDT. Use the virtual mode and backtester to track the bot's performance in the future and the past.</br></br><font color=" +
          goldColor +
          ">Sharpe ratio:</font> The Sharpe ratio is now available, a measure used to evaluate the return on investment relative to the assumed risk. Find it on your bot's card next to the percentage performance and also on the bot's detail page.</br></br><font color=" +
          goldColor +
          '>Choose to sell or hold:</font> Now you have the option to decide whether to sell or hold the coin when pausing or terminating your bot.</br></br><font color=' +
          goldColor +
          ">Bot name customization:</font> We've added the ability to change your bot's name. If you don't like the suggested name, click on the bot card and modify it (as many times as you want!) by clicking on the pencil icon.</br>",
        'it-IT':
          'Bentornato Hodlier! <br/> Abbiamo importanti novità sulla piattaforma: <br/><br/><font color=' +
          goldColor +
          '>Grizzly su ETH:</font> Da oggi puoi lanciare Grizzly su ETHUSDT, hai a disposizione anche la modalità virtuale e il backtester per seguire l’andamento del bot nel futuro e nel passato.</br></br><font color=' +
          goldColor +
          '>Sharpe ratio:</font> è finalmente disponibile lo sharpe ratio, una misura utilizzata per valutare il rendimento di un investimento in rapporto al rischio assunto. Lo trovi sulla card del tuo bot accanto alle performance percentuali e anche nella pagina di dettaglio del bot.</br></br><font color=' +
          goldColor +
          '>Decidi tu se vendere o tenere:</font> Ora puoi scegliere se vendere o tenere la coin quando metti in pausa o decidi di terminare il tuo bot.</br></br><font color=' +
          goldColor +
          '>Personalizzazione nome bot:</font> Abbiamo inserito la possibilità di modificare il nome del tuo bot. Se il nome suggerito non ti piace, clicca sulla card del bot e modificalo (quante volte vuoi!) cliccando sull’icona della matita.</br>'
      },
      active: true
    },
    {
      title: {
        'en-EN': 'SENTIMENT SECTION AVAILABLE NOW',
        'it-IT': 'SEZIONE SENTIMENT ADESSO DISPONIBILE'
      },
      date: {
        'en-EN': '19th January 2024',
        'it-IT': '19 Gennaio 2024'
      },
      text: {
        'en-EN': `<font color='${goldColor}'>Introducing Sentiment</font>, the new section entirely dedicated to real-time analysis of sentiment associated with news, thanks to the use of our Artificial Intelligence models.</br>
          The analyzed news are listed and can be selected to view the preview and read the content.<br></br>
          Each news is assigned a <font color='${goldColor}'>Score</font>, calculated by multiplying:
          <ul>
          <li>The value of the <font color='${goldColor}'>sentiment</font> of the textual content of the news, which can range from -1 (negative) to +1 (positive);</li>
          <li>The value of the <font color='${goldColor}'>relevance</font> of the news, which can range from 0 (statistically irrelevant) to +1 (statistically very relevant);</li>
        </ul>
        A chart shows the <font color='${goldColor}'>Overall Score (the centroid of the news)</font>, along with the sentiment (indicated by a color scale ranging from red to green), and the relevance (size of each circle).<br>
        Finally, there is the <font color='${goldColor}'>Fear and Greed</font> index, calculated by weighting various parameters including: Volatility, Momentum, Social Media, Surveys, Bitcoin Dominance, Google Trends.<br><br>
        Explore Sentiment now and discover the behind-the-scenes of our AI!`,
        'it-IT': `<font color='${goldColor}'>Vi presentiamo Sentiment</font>, la nuova sezione interamente dedicata all'analisi in tempo reale del sentimento associato alle news, grazie all'impiego dei nostri modelli di Intelligenza Artificiale.</br>
          Le news analizzate vengono riportate in una lista e possono essere selezionate per visualizzarne la preview e leggerne il contenuto.<br></br>
          A ciascuna di esse viene assegnato uno <font color='${goldColor}'>Score</font>, calcolato moltiplicando:
          <ul>
          <li>Il valore del <font color='${goldColor}'>sentimento</font> del contenuto testuale della notizia,che può variare tra -1 (negativo) e +1 (positivo);</li>
          <li>Il valore della <font color='${goldColor}'>rilevanza</font> della notizia, che può variare tra 0 (statisticamente irrilevante) e +1 (statisticamente molto rilevante);</li>
        </ul>
        Un grafico indica lo <font color='${goldColor}'>Score Generale (il baricentro delle news)</font> , insieme al sentimento (indicato da una scala di colori che varia dal rosso al verde), e alla rilevanza, (dimensione di ogni singola circonferenza).<br>
        Infine è presente l’'indice  <font color='${goldColor}'>Fear and Greed</font>, calcolato ponderando vari parametri tra cui: Volatility, Momentum, Social Media, Surveys, Bitcoin Dominance, Google Trends.<br><br>
        Esplora ora Sentiment, e scopri il dietro le quinte della nostrIa AI!`
      },
      active: true
    },
    {
      title: {
        'en-EN': 'New Referral Program and Add Capital!',
        'it-IT': 'Nuovo Referral Program e Aumento di Capitale!'
      },
      date: {
        'en-EN': '2nd April 2024',
        'it-IT': '2 Aprile 2024'
      },
      text: {
        'en-EN': `Big news at Hodlie!<br><br>
        <ol>
        <li>We've listened to your feedback, and our brand new <font color='${goldColor}'>Referral Program</font> is now available! It's the perfect time to share with your friends all the benefits of Hodlie's PRO subscription and earn incredible discounts or gifts!
        Don’t miss this unique opportunity to grow our community and, at the same time, obtain <font color='${goldColor}'>fantastic benefits</font>. Start sharing your enthusiasm for Hodlie now and turn your trading experience into something even more advantageous.</li><br>
        <li>It is now finally possible to increase the capital for your actively running bots!</font><br>
        Do you know what this means?
        <ul>
        <li><font color='${goldColor}'>More capital, more opportunities</font>: with additional funds, your bots will have more strategies to explore, increasing the chances of success</li>
        <li><font color='${goldColor}'>Greater flexibility</font>: you can add capital starting from $100, up to the maximum allowed by the bot, so you can tailor your strategies to your goals</li>
        <li><font color='${goldColor}'>No more farewells</font>: you can now add capital to enhance your bots' operations without having to delete them and start over from scratch</li>
        </ul>
        To do this, just go to the individual bot's page, click on the <i><font color='${goldColor}'>blue button with the + symbol</font></i>,
         select <i><font color='${goldColor}'>Add capital to your bot</font></i>, choose the amount of capital to add, and finally press 
        <i><font color='${goldColor}'>Confirm</font></i></li><br>
        <li>But that's not all, because we've also introduced the <i><font color='${goldColor}'>new Interactive Chart for order visualization</font></i>,
        which will allow you to see when your bots have made buying or selling operations, and at what price. The <i><font color='${goldColor}'>Bot History</font></i> section
        on the bot's detail page will also offer you an overview of all the capital increases made, so you can easily track the history of your bots.</li>
        </ol>`,
        'it-IT': `Grandi novità in casa Hodlie!<br><br>
        <ol>
        <li>Abbiamo ascoltato la vostra voce, ed è ora disponibile il nostro nuovissimo <font color='${goldColor}'>Referral Program</font>! È il momento perfetto per condividere con i vostri amici tutti i benefici dell'abbonamento PRO di Hodlie e guadagnare incredibili sconti o regali!
        Non perdete questa occasione unica di far crescere la nostra community e, allo stesso tempo, ottenere <font color='${goldColor}'>fantastici vantaggi</font>. Inizia ora a condividere il tuo entusiasmo per Hodlie e trasforma la tua esperienza di trading  in qualcosa di ancora più vantaggioso.</li><br>
        <li>è finalmente possibile aumentare il capitale in gestione ai vostri bot già attivi!</font><br>
        Sapete questo cosa significa?
        <ul>
        <li><font color='${goldColor}'>Più capitale, più opportunità</font>: con fondi extra, i vostri bot avranno più strade da esplorare, aumentando le possibilità di successo</li>
        <li><font color='${goldColor}'>Maggiore flessibilità</font>: potete aggiungere capitale a partire da $100, fino al massimo consentito dal bot, così da poter adattare le strategie ai vostri obiettivi</li>
        <li><font color='${goldColor}'>Niente più addii</font>: potrete aggiungi capitale per potenziare le operazioni dei vostri bot senza doverli eliminare e ricreare da zero</li>
        </ul>
        Per farlo, vi basterà andare alla pagina dedicata al singolo bot, cliccare sul <i><font color='${goldColor}'>pulsante azzurro con il simbolo +</font></i>,
         selezionare <i><font color='${goldColor}'>Aggiungi capitale al tuo bot</font></i>, scegliere la quantità di capitale da aggiungere, e infine premere 
        <i><font color='${goldColor}'>Conferma</font></i></li><br>
        <li>Ma non è finita qui, perchè abbiamo inserito anche il <i><font color='${goldColor}'>nuovo Grafico Interattivo per la visualizzazione degli ordini</font></i>,
        che vi permetterà di visualizzare quando i vostri bot hanno effettuato operazioni di acquisto o vendita, e a quale prezzo. La sezione <i><font color='${goldColor}'>Storico Bot</font></i>
        nella pagina di dettaglio del bot vi offrirà inoltre una panoramica di tutti gli aumenti di capitale effettuati, così che possiate tenere traccia della storia dei vostri bot con facilità.</li>
        </ol>`
      },
      active: true
    },
    {
      title: {
        'en-EN': 'SOLANA AVAILABLE NOW',
        'it-IT': 'SOLANA ADESSO DISPONIBILE'
      },
      date: {
        'en-EN': '3rd May 2024',
        'it-IT': '3 Maggio 2024'
      },
      text: {
        'en-EN': `The wait is over: Solana (SOL) is now available for all PRO, PRO+ and Private users.<br><br>
        From this moment, <b><font color='${goldColor}'>you can activate your Dynamo on SOLUSDT</font></b>, seizing the opportunity to invest in one of the most promising digital currencies at the moment.<br><br>
        Don't wait! Start your Dynamo now, also available in virtual mode!`,
        'it-IT': `L'attesa è finita: Solana (SOL) è ora disponibile per tutti gli utenti PRO, PRO+ e Private.<br><br>
          A partire da questo momento, <b><font color='${goldColor}'>puoi attivare il tuo Dynamo su SOLUSDT</font></b>, cogliendo l'opportunità di investire in una delle valute digitali più promettenti del momento.<br><br>
          Non aspettare! Avvia ora il tuo Dynamo, disponibile anche in modalità virtuale!`
      },
      active: true
    }
  ]
};

export const AuthCardLayout = {
  step: {
    'en-EN':
      'Join Hodlie now: your platform for managing your crypto investments',
    'it-IT':
      'Entra ora su Hodlie: la piattaforma per la gestione dei tuoi investimenti in crypto'
  },
  join: {
    'en-EN': 'Join',
    'it-IT': 'Unisciti a '
  },
  welcome: {
    'en-EN': 'Welcome ',
    'it-IT': 'Ciao '
  },
  welcome2: {
    'en-EN': 'Welcome',
    'it-IT': 'Ciao'
  }
};

export const AuthCardLayoutChangePassword = {
  lateralText: {
    'en-EN': 'Here you can change your password',
    'it-IT': 'Qui puoi cambiare la tua password'
  }
};

export const AuthCardLayoutSignIn = {
  lateralText: {
    'en-EN':
      'Get started now and discover how AI can revolutionize the way you invest in cryptocurrencies!',
    'it-IT':
      "Inizia ora e scopri come l'AI può rivoluzionare il tuo modo di investire in criptovalute!"
  }
};

export const AuthCardLayoutForgot = {
  lateralText: {
    'en-EN':
      'Enter the email associated with your account to change your password',
    'it-IT':
      "Inserisci l'indirizzo email associato al tuo account per cambiare la tua password"
  }
};

export const Backtesting = {
  specification: {
    takeProfitBot30: {
      // Deep Scalper
      'en-EN': {
        'AI Models': 'Neural Networks (several types)',
        'Available on': 'Virtual Mode, Binance',
        Asset: 'BTC',
        Quote: 'FDUSD',
        Fee: 'Works only with 0% fee',
        'Average Trading Volume': '~ 40X/day',
        'Average Exposure Time': '~ 20 mins',
        'Average Volume X Transaction': '100% (of managed)',
        'Stop Loss': '2%',
        'Take Profit': 'Dynamic,~ 0.1%'
      },
      'it-IT': {
        'Modelli AI': 'Reti Neurali (diverse tipologie)',
        'Disponibile su': 'Modalità Virtuale, Binance',
        Asset: 'BTC',
        Quote: 'FDUSD',
        Fee: 'Operativo solo con 0% fee',
        'Volume di Trading Medio': '~ 40X/giorno',
        'Tempo Medio di Esposizione': '~ 20 minuti',
        'Volume Medio per Transazione': '100% (del gestito)',
        'Stop Loss': '2%',
        'Take Profit': 'Dinamico, ~ 0.1% '
      }
    },
    iBot30: {
      // Dynamo
      'en-EN': {
        'AI Models': 'Neural Networks (several types)',
        'Available on': 'Virtual Mode, Binance',
        Asset: 'BTC, SOL',
        Quote: 'USDT',
        Fee: '0.1% or lower',
        'Average Trading Volume': '~ 15X/month',
        'Average Exposure Time': '~ from 6 hours to 7 days',
        'Average Volume X Transaction': '~ 5% (of managed)',
        'Stop Loss': '1 - 3.5%',
        'Take Profit': 'NO'
      },
      'it-IT': {
        'Modelli AI': 'Reti Neurali (diverse tipologie)',
        'Disponibile su': 'Modalità Virtuale, Binance',
        Asset: 'BTC, SOL',
        Quote: 'USDT',
        Fee: '0.1% o minore',
        'Volume di Trading Medio': '~ 15X/mese',
        'Tempo Medio di Esposizione': '~ tra 6 ore e 7 giorni',
        'Volume Medio per Transazione': '~ 5% (del gestito)',
        'Stop Loss': '1 - 3.5%',
        'Take Profit': 'NO'
      }
    },
    iBot60: {
      // Grizzly
      'en-EN': {
        'AI Models': 'Neural Networks (several types)',
        'Available on': 'Virtual Mode, Binance',
        Asset: 'BTC, ETH',
        Quote: 'USDT',
        Fee: '0.1% or lower',
        'Average Trading Volume': '~ 5X/month',
        'Average Exposure Time': '~ from 24 hours to 10 days',
        'Average Volume X Transaction': '~ 10% (of managed)',
        'Stop Loss': '1 - 5.5%',
        'Take Profit': 'NO'
      },
      'it-IT': {
        'Modelli AI': 'Reti Neurali (diverse tipologie)',
        'Disponibile su': 'Modalità Virtuale, Binance',
        Asset: 'BTC, ETH',
        Quote: 'USDT',
        Fee: '0.1% o minore',
        'Volume di Trading Medio': '~ 5X/mese',
        'Tempo Medio di Esposizione': '~ tra 24 ore e 10 giorni',
        'Volume Medio per Transazione': '~ 10% (del gestito)',
        'Stop Loss': '1 - 5.5%',
        'Take Profit': 'NO'
      }
    },
    takeProfitBot60: {
      // Centurion
      'en-EN': {
        'AI Models': 'Neural Networks (several types)',
        'Available on': 'Virtual Mode, Binance',
        Asset: 'BTC',
        Quote: 'USDT',
        Fee: '0.1% or lower',
        'Average Trading Volume': '~ 5X/month',
        'Average Exposure Time': '~ from 12 hours to 48 hours',
        'Average Volume X Transaction': '~ 100% (of managed)',
        'Stop Loss': 'Trailing, 2.5%',
        'Take Profit': 'NO'
      },
      'it-IT': {
        'Modelli AI': 'Reti Neurali (diverse tipologie)',
        'Disponibile su': 'Modalità Virtuale, Binance',
        Asset: 'BTC',
        Quote: 'USDT',
        Fee: '0.1% o minore',
        'Volume di Trading Medio': '~ 5X/mese',
        'Tempo Medio di Esposizione': '~ tra 24 ore e 48 ore',
        'Volume Medio per Transazione': '~ 100% (del gestito)',
        'Stop Loss': 'Trailing, 2.5%',
        'Take Profit': 'NO'
      }
    }
  },
  description: {
    'en-EN': 'Description',
    'it-IT': 'Descrizione'
  },
  dataSheet: {
    'en-EN': 'Data Sheet',
    'it-IT': 'Scheda Tecnica'
  }
};

export const BacktestingSoon = {
  working: {
    'en-EN': 'We are working on Backtester, soon will be available!',
    'it-IT': 'Stiamo lavorando al Backtester, tornerà presto disponibile!'
  }
};

export const BacktestingBody = {
  amount: {
    'en-EN': 'Amount',
    'it-IT': 'Quantità'
  },
  fee: {
    'en-EN': 'Fee',
    'it-IT': 'Commissione'
  },
  feeText: {
    'en-EN': 'Fee applied during Backtesting',
    'it-IT': 'Commissione applicata durante la Simulazione'
  },
  botRev: {
    'en-EN': 'Agent Revenue',
    'it-IT': "Ricavi dell'AI Agent"
  },
  market: {
    'en-EN': 'Market Growth',
    'it-IT': 'Crescita di mercato'
  },
  backtest: {
    'en-EN': 'Backtest',
    'it-IT': 'Simula'
  },
  monthly_average_return_12: {
    'en-EN': 'Monthly Average Return (12 months)',
    'it-IT': 'Ritorno Medio Mensile (12 mesi)'
  },
  monthly_average_return: {
    'en-EN': 'Monthly Average Return',
    'it-IT': 'Ritorno Medio Mensile'
  },
  monthly_average_volume_12: {
    'en-EN': 'Monthly Average Volume (12 months)',
    'it-IT': 'Volume Medio Mensile (12 mesi)'
  },
  max_drawdown: {
    'en-EN': 'Max Drawdown',
    'it-IT': 'Drawdown Massimo'
  },
  drawdownDescription: {
    'en-EN':
      'Drawdown measures the distance between the historical maximum of a capital and the subsequent minimum, useful for assessing the maximum loss over a specific period.',
    'it-IT':
      'Il drawdown misura la distanza tra il massimo storico di un capitale e il minimo successivo, utile per valutare la massima perdita in un determinato periodo.'
  },
  average_BTC_exposition: {
    'en-EN': 'Average Bitcoin Exposure',
    'it-IT': 'Esposizione Media su Bitcoin'
  },
  sharpe: {
    'en-EN': 'Sharpe Index',
    'it-IT': 'Indice di Sharpe'
  },
  back: {
    'en-EN': 'Back',
    'it-IT': 'Indietro'
  },
  return: {
    'en-EN': 'Return',
    'it-IT': 'Ritorno'
  },
  monthsProfit: {
    'en-EN': 'Months in profit',
    'it-IT': 'Mesi in profitto'
  },
  next: {
    'en-EN': 'Next',
    'it-IT': 'Avanti'
  },
  virtualMode: {
    'en-EN': 'Virtual Mode',
    'it-IT': 'Modalità Virtuale'
  },
  yourRiskLevel: {
    'en-EN': 'Your risk level is',
    'it-IT': 'Il tuo livello di rischio è'
  },
  discoverYour: {
    'en-EN': 'Discover your',
    'it-IT': 'Scopri il tuo'
  },
  riskForm: {
    'en-EN':
      'Each AI Portfolio has 3 levels of risk: low, medium, and high. To guide you,',
    'it-IT':
      'Ogni AI Portfolio ha 3 livelli di rischio: basso, medio e alto. Per guidarti,'
  },
  riskFormLink: {
    'en-EN':
      'we have created a questionnaire that will give you more insight into your risk tolerance.',
    'it-IT':
      'abbiamo creato un questionario che ti darà qualche indicazione in più sulla tua propensione al rischio.'
  },
  potfolioDetailsSubtitleShort: {
    6: {
      'en-EN': `Market Leaders is a portfolio that uses artificial intelligence to optimize daily exposure of a portfolio composed of Bitcoin (BTC), Ethereum (ETH), Solana (SOL), and a stablecoin (USDT)...`,
      'it-IT': `Market Leaders è un portfolio che utilizza l’intelligenza artificiale per ottimizzare quotidianamente l’esposizione di un portafoglio composto da Bitcoin (BTC), Ethereum (ETH), Solana (SOL) e una stablecoin (USDT)...`
    },
    7: {
      'en-EN':
        'High Volume is a portfolio that uses artificial intelligence to optimize daily exposure to a portfolio composed of Bitcoin (BTC), Ethereum (ETH), Binance Coin (BNB), Solana (SOL), Ripple (XRP), and a stablecoin (USDT)...',
      'it-IT':
        'High Volume è un portfolio che utilizza l’intelligenza artificiale per ottimizzare quotidianamente l’esposizione di un portafoglio composto da Bitcoin (BTC), Ethereum (ETH), Binance Coin (BNB), Solana (SOL), Ripple (XRP) e una stablecoin (USDT)...'
    },
    8: {
      'en-EN':
        'Discovery Altcoins is a portfolio that uses artificial intelligence to optimize daily exposure to a portfolio composed of Bitcoin (BTC), Ethereum (ETH), Binance Coin (BNB) and altcoins such as Solana (SOL), Ripple (XRP), Cardano (ADA), Dogecoin (DOGE), Chainlink (LINK), Polkadot (DOT), Avalanche (AVAX), Polygon (POL), Litecoin (LTC), Shiba Inu (SHIB), Uniswap (UNI) and a stablecoin (USDT)...',
      'it-IT':
        'Discovery Altcoins è un portfolio che utilizza l’intelligenza artificiale per ottimizzare quotidianamente l’esposizione di un portafoglio composto da Bitcoin (BTC), Ethereum (ETH) Binance Coin (BNB) e altcoins come Solana (SOL), Ripple (XRP), Cardano (ADA), Dogecoin (DOGE), Chainlink (LINK), Polkadot (DOT), Avalanche (AVAX), Polygon (POL), Litecoin (LTC), Shiba Inu (SHIB), Uniswap (UNI) e una stablecoin (USDT)...'
    }
  },
  potfolioDetailsSubtitle: {
    6: {
      'en-EN': `
      <p>
        Market Leaders is a portfolio that uses artificial intelligence to optimize daily exposure of a portfolio composed of Bitcoin (BTC), Ethereum (ETH), Solana (SOL) and a stablecoin (USDT).
      </p>
      <h6>Who is Market Leaders suitable for?</h6>
      <p>
        Market Leaders is the ideal product for both those with little knowledge of the cryptocurrency world and for those who, despite considering themselves seasoned crypto investors, prefer to focus only on the most capitalized assets.
      </p>
      <h6>Choose Market Leaders if:</h6>
      <ul>
        <li>
          You want to diversify your investments and enter the crypto world without exposing yourself to little-known and highly volatile assets.
        </li>
        <li>
          You want to invest in Bitcoin but are also interested in being exposed to Ethereum and Solana.
        </li>
      </ul>`,
      'it-IT': `     
      <p>
        Market Leaders è un portfolio che utilizza l’intelligenza artificiale per ottimizzare quotidianamente l’esposizione di un portafoglio composto da Bitcoin (BTC), Ethereum (ETH), Solana (SOL) e una stablecoin (USDT).
      </p>
      <h6>A chi è adatto Market Leaders?</h6>
      <p>
        Market Leaders è il prodotto ideale sia per chi ha poca conoscenza nel mondo delle criptovalute, sia per chi, pur ritenendosi un investitore crypto navigato, preferisce esporsi solo sugli asset più capitalizzati.
      </p>
      <h6>Scegli Market Leaders se: </h6>
      <ul>
        <li>
          Vuoi diversificare i tuoi investimenti e aprirti alle crypto senza esporti su asset poco noti e troppo volatili.
        </li>
        <li>
          Vuoi investire in Bitcoin ma sei interessato anche ad essere esposto su Ethereum e Solana.
        </li>
      </ul>`
    },
    7: {
      'en-EN': `
      <p>
        High Volume is a portfolio that uses artificial intelligence to optimize daily exposure to a portfolio composed of Bitcoin (BTC), Ethereum (ETH), Binance Coin (BNB), Solana (SOL), Ripple (XRP) and a stablecoin (USDT).
      </p>
      <h6>Who is High Volume suitable for?</h6>
      <p>
        High Volume is the ideal product for both those with a basic knowledge of the cryptocurrency world and those who wish to take advantage of the most traded cryptos of the moment and explore trending assets.
      </p>
      <h6>Choose High Volume if:</h6>
      <ul>
        <li>
          You want to diversify your investments by focusing on cryptocurrencies with the highest trading volumes, but don't have time to manage them.
        </li>
        <li>
          You want to compose a wide crypto portfolio without exposing yourself to overly volatile or low-capitalized assets.
        </li>
      </ul>`,
      'it-IT': `
      <p>
        High Volume è un portfolio che utilizza l’intelligenza artificiale per ottimizzare quotidianamente l’esposizione di un portafoglio composto da Bitcoin (BTC), Ethereum (ETH), Binance Coin (BNB), Solana (SOL), Ripple (XRP) e una stablecoin (USDT).
      </p>
      <h6>A chi è adatto High Volume?</h6>
      <p>
        High Volume è il prodotto ideale sia per chi ha una conoscenza di base nel mondo delle criptovalute, sia per chi desidera approfittare delle crypto più scambiate del momento ed esplorare asset di tendenza
      </p>
      <h6>Scegli High Volume se: </h6>
      <ul>
        <li>
          Vuoi diversificare i tuoi investimenti puntando sulle criptovalute con il più alto volume di scambi, ma non hai tempo per gestirle
        </li>
        <li>
          Vuoi comporre un portafoglio di criptovalute ampio, senza esporti su asset troppo volatili o poco capitalizzati.
        </li>
      </ul>`
    },
    8: {
      'en-EN': `
      <p>
        Discovery Altcoins is a portfolio that uses artificial intelligence to optimize daily exposure to a portfolio composed of Bitcoin (BTC), Ethereum (ETH), Binance Coin (BNB) and altcoins such as Solana (SOL), Ripple (XRP), Cardano (ADA), Dogecoin (DOGE), Chainlink (LINK), Polkadot (DOT), Avalanche (AVAX), Polygon (POL), Litecoin (LTC), Shiba Inu (SHIB), Uniswap (UNI) and a stablecoin (USDT).
      </p>
      <h6>Who is Discovery Altcoins suitable for?</h6>
      <p>
        Discovery Altcoins is ideal for investors with a high level of knowledge of the crypto market, who wish to fully explore the market's potential. The most established assets, combined with new ones with high growth potential, make Discovery Altcoins suitable for those looking to broaden their investment horizon and take advantage of the dynamism typical of emerging altcoins.
      </p>
      <h6>Choose Discovery Altcoins if:</h6>
      <ul>
        <li>
          You are knowledgeable about the crypto world and are interested in investing in many emerging cryptocurrencies with high growth potential, but do not have the time to manage them all.
        </li>
        <li>
          You are knowledgeable about the crypto world and are willing to accept higher volatility in exchange for greater growth opportunities.
        </li>
      </ul>`,
      'it-IT': `
      <p>
        Discovery Altcoins è un portfolio che utilizza l’intelligenza artificiale per ottimizzare quotidianamente l’esposizione di un portafoglio composto da Bitcoin (BTC), Ethereum (ETH) Binance Coin (BNB) e altcoins come Solana (SOL), Ripple (XRP), Cardano (ADA), Dogecoin (DOGE), Chainlink (LINK), Polkadot (DOT), Avalanche (AVAX), Polygon (POL), Litecoin (LTC), Shiba Inu (SHIB), Uniswap (UNI) e una stablecoin (USDT).
      </p>
      <h6>A chi è adatto Discovery Altcoins?</h6>
      <p>
        Discovery Altcoins è ideale per gli investitori con un’elevata conoscenza del mercato crypto, che desiderano esplorare a pieno le potenzialità di questo mercato. Gli asset più consolidati, affiancati da quelli nuovi e con un elevato potenziale di crescita, rendono Discovery Altcoins adatto a chi vuole ampliare il proprio orizzonte di investimento e approfittare del dinamismo tipico delle altcoin emergenti.
      </p>
      <h6>Scegli Discovery Altcoins se:</h6>
      <ul>
        <li>
          Conosci il mondo crypto e sei interessato a investire in tante criptovalute emergenti con alto potenziale di crescita, ma non hai tempo per gestirle tutte
        </li>
        <li>
          Conosci il mondo crypto e sei disposto ad accettare una volatilità più elevata in cambio di maggiori opportunità di crescita.
        </li>
      </ul>`
    }
  },
  chooseRiskLevel: {
    'en-EN': 'Risk level',
    'it-IT': 'Livello di rischio'
  },
  chooseRiskLevelShort: {
    'en-EN': 'Risk',
    'it-IT': 'Rischio'
  },
  interval: {
    'en-EN': 'Intervall',
    'it-IT': 'Intervallo'
  },
  composition: {
    'en-EN': 'Composition',
    'it-IT': 'Composizione'
  },
  generalStats: {
    'en-EN': 'General statistics',
    'it-IT': 'Statistiche Generali'
  },
  minCap: {
    'en-EN': 'Minimum Capital',
    'it-IT': 'Capitale minimo'
  },
  execAgain: {
    'en-EN': 'Run the test again',
    'it-IT': 'Esegui di nuovo il test'
  },
  show: {
    'en-EN': 'Show ',
    'it-IT': 'Mostra di '
  },
  more: {
    'en-EN': 'more',
    'it-IT': 'più'
  },
  less: {
    'en-EN': 'less',
    'it-IT': 'meno'
  }
};

export const BacktestingHeader = {
  selectAsset: {
    'en-EN': 'Select an Asset',
    'it-IT': 'Seleziona un Asset'
  },
  selectQuote: {
    'en-EN': 'Select a Quote',
    'it-IT': 'Seleziona una Stablecoin'
  },
  previous: {
    'en-EN': 'Prev',
    'it-IT': 'Indietro'
  },
  next: {
    'en-EN': 'Next',
    'it-IT': 'Avanti'
  },
  riskLevel: {
    'en-EN': 'Select a risk level',
    'it-IT': 'Seleziona un livello di rischio'
  },
  low: {
    'en-EN': 'Low',
    'it-IT': 'Basso'
  },
  high: {
    'en-EN': 'High',
    'it-IT': 'Alto'
  }
};

export const Banner = {
  title: {
    'en-EN': 'Summer Promo',
    'it-IT': 'Summer Promo'
  },
  text_user_first: {
    'en-EN':
      'Take advantage of our Summer Promo and save 20% on the Investor subscription with code <b>SUMMER20</b>. For a limited time, you can also start an AI Portfolio with just $1000!',
    'it-IT':
      "Approfitta della nostra Summer Promo e risparmia il 20% sull'abbonamento Investor con il codice <b>SUMMER20</b>. Per un tempo limitato potrai anche avviare un AI Portfolio con soli 1000$!"
  },
  text_explorer_first: {
    'en-EN':
      'Take advantage of our Summer Promo and save 20% on the upgrade to Investor with code <b>SUMMER20</b>. For a limited time, you can also start an AI Portfolio with just $1000!',
    'it-IT':
      "Approfitta della nostra Summer Promo e risparmia il 20% sull'upgrade a Investor con il codice <b>SUMMER20</b>. Per un tempo limitato potrai anche avviare un AI Portfolio a soli 1000$!"
  },
  text_users_second: {
    'en-EN':
      'Take advantage of our Summer Promo and save 20% on the "Investor" subscription. You can start your AI Portfolio at a discounted price! Use code <b>SUMMER20</b> at checkout.',
    'it-IT': `Approfitta della nostra Summer Promo e risparmia il 20% sull'abbonamento "Investor", potrai avviare il tuo AI Portfolio ad un prezzo scontato! Utilizza il codice <b>SUMMER20</b> in fase di checkout.`
  },
  text_explorer_second: {
    'en-EN':
      'Take advantage of our Summer Promo and save 20% on the "Investor" upgrade. You can start your AI Portfolio at a discounted price! Use code <b>SUMMER20</b> at checkout.',
    'it-IT': `Approfitta della nostra Summer Promo e risparmia il 20% sull'upgrade all'abbonamento "Investor", potrai avviare il tuo AI Portfolio ad un prezzo scontato! Utilizza il codice <b>SUMMER20</b> in fase di checkout.`
  },
  preCountdown: {
    'en-EN': "<b>HURRY</b>, summer won't last forever!",
    'it-IT': "<b>AFFRETTATI</b>, l'estate non dura per sempre!"
  },
  days: {
    'en-EN': 'Days',
    'it-IT': 'Giorni'
  },
  hours: {
    'en-EN': 'Hours',
    'it-IT': 'Ore'
  },
  minutes: {
    'en-EN': 'Minutes',
    'it-IT': 'Minuti'
  },
  seconds: {
    'en-EN': 'Seconds',
    'it-IT': 'Secondi'
  },
  expiredTitle: {
    'en-EN': 'Your subscription has expired',
    'it-IT': 'Il tuo abbonamento è scaduto'
  },
  expiredDescr: {
    'en-EN':
      'Your subscription has expired and your strategies have stopped operating. To restore the functionality',
    'it-IT':
      "Il tuo abbonamento è scaduto e le tue strategie hanno smesso di operare. Per ripristinare l'operatività"
  },
  aiPort: {
    'en-EN': ' of your AI Portfolio',
    'it-IT': ' del tuo AI Portfolio'
  },
  expiredDescr_1: {
    'en-EN': ', restart a subscription now.',
    'it-IT': ', riavvia ora un abbonamento.'
  },
  subTitle: {
    'en-EN':
      'It is communicated that, as of November 19, 2024, on December 16, the operations of the Agents will be interrupted. <br>Start your AI Portfolio now using the capital of your Agents to avoid service interruptions.',
    'it-IT': `Come da comunicazione del 20/11/2024, il 16 dicembre l'operatività degli Agents verrà interrotta. <br>Avvia ora il tuo AI Portfolio utilizzando il capitale dei tuoi Agents, per evitare interruzioni di servizio.`
  },
  subTitle_after: {
    'en-EN':
      'It is communicated that, as of November 19, 2024, on December 16, the operations of the Agents was interrupted. <br>Start your AI Portfolio now using the capital of your Agents..',
    'it-IT': `Come da comunicazione del 20/11/2024, il 16 dicembre l'operatività degli Agents è stata interrotta. <br>Avvia ora il tuo AI Portfolio utilizzando il capitale dei tuoi Agents.`
  },
  subTitle_portfolio: {
    'en-EN':
      'As per the communication on 20/11/2024, the operations of the Agents will be interrupted on December 16th. Add the capital of your Agents to your AI Portfolio now to avoid service interruptions.',
    'it-IT': `Come da comunicazione del 20/11/2024, il 16 dicembre l'operatività degli Agents verrà interrotta. <br>Aggiungi ora il capitale dei tuoi Agents al tuo AI Portfolio per evitare interruzioni di servizio.`
  },
  subTitle_portfolio_after: {
    'en-EN':
      'As per the communication on 20/11/2024, the operations of the Agents was interrupted on December 16th. Add the capital of your Agents to your AI Portfolio now.',
    'it-IT': `Come da comunicazione del 20/11/2024, il 16 dicembre l'operatività degli Agents è stata interrotta. <br>Aggiungi ora il capitale dei tuoi Agents al tuo AI Portfolio.`
  },
  titleConvert: {
    'en-EN':
      'Start an AI Portfolio using the capital currently allocated to your Agents',
    'it-IT':
      'Avvia un AI Portfolio utilizzando il capitale allocato attualmente sui tuoi Agents'
  },
  titleConvert_portfolio: {
    'en-EN': 'Add the capital of your Agents to your AI Portfolio',
    'it-IT': 'Aggiungi il capitale dei tuoi Agents sul tuo AI Portfolio'
  },
  copy: {
    'en-EN': 'Copy to clipboard',
    'it-IT': 'Copia negli appunti'
  },
  copied: {
    'en-EN': 'Copied!',
    'it-IT': 'Copiato!'
  },
  titleAlert: {
    'en-EN': '',
    'it-IT': 'Su Hodlie è arrivato il Black Friday!'
  }
};

export const Billings = {
  traderPlan: {
    'en-EN': 'Private Plan',
    'it-IT': 'Piano Private'
  },
  investorPlan: {
    'en-EN': 'Investor Plan',
    'it-IT': 'Piano Investor'
  },
  explorerPlan: {
    'en-EN': 'Explorer Plan',
    'it-IT': 'Piano Explorer'
  },
  manage: {
    'en-EN': 'Manage',
    'it-IT': 'Gestisci'
  },
  becomeTrader: {
    'en-EN': 'Become a Private',
    'it-IT': 'Diventa un Private'
  },
  lastSubscriptions: {
    'en-EN': 'Last Subscriptions',
    'it-IT': 'Ultimi abbonamenti'
  },
  previous: {
    'en-EN': 'Previous',
    'it-IT': 'Precedente'
  },
  ongoing: {
    'en-EN': 'Ongoing',
    'it-IT': 'In corso'
  },
  total: {
    'en-EN': 'Total',
    'it-IT': 'Totale'
  },
  products: {
    'en-EN': 'Products',
    'it-IT': 'Prodotti'
  },
  quantity: {
    'en-EN': 'Quantity',
    'it-IT': 'Quantità'
  },
  amount: {
    'en-EN': 'Amount',
    'it-IT': 'Importo'
  },
  firstQuarter: {
    'en-EN': 'First Quarter',
    'it-IT': 'Primo trimestre'
  },
  traderInfrastructure: {
    'en-EN': 'Dedicated Infrastructure',
    'it-IT': 'Infrastruttura Dedicata'
  },
  managementFee: {
    'en-EN': 'Management Fee',
    'it-IT': 'Commissione di Gestione'
  },
  performanceFee: {
    'en-EN': 'Performance Fee',
    'it-IT': 'Commissione sulla Performance'
  },
  note: {
    'en-EN': 'Note:',
    'it-IT': 'Nota:'
  },
  noteDescr: {
    'en-EN':
      'It is not possible to downgrade the subscription or change the payment timing (from monthly to annual, and vice versa) automatically. In both cases you must first cancel your current subscription and then sign up for a new subscription plan.',
    'it-IT':
      "Non è possibile effettuare il downgrade dell'abbonamento o modificare la tempistica del pagamento (da mensile a annuale, e viceversa)  in maniera automatica. In entrambi i casi è necessario annullare prima l'abbonamento attuale e successivamente sottoscrivere un nuovo piano di abbonamento."
  },
  noSub: {
    'en-EN': 'You don’t have an active subscription. Activate it now!',
    'it-IT': 'Non hai nessun abbonamento attivo. Attivalo ora!'
  },
  noSubButton: {
    'en-EN': 'Activate',
    'it-IT': 'Attiva'
  },
  subsFrom: {
    'en-EN': 'Subscriber from',
    'it-IT': 'Abbonato da'
  },
  subEnd: {
    'en-EN': 'End subscription',
    'it-IT': 'Scadenza abbonamento'
  },
  manageSubscription: {
    'en-EN': 'Subscription Management',
    'it-IT': 'Gestione Abbonamento'
  },
  monthly: {
    'en-EN': 'Monthly',
    'it-IT': 'Mensile'
  },
  yearly: {
    'en-EN': 'Yearly',
    'it-IT': 'Annuale'
  },
  subType: {
    'en-EN': 'Subscription type',
    'it-IT': 'Tipo di Abbonamento'
  },
  trial: {
    'en-EN': 'trial',
    'it-IT': 'in prova'
  },
  subDetails: {
    'en-EN': 'View your subscription details',
    'it-IT': 'Visualizza i dettagli del tuo abbonamento'
  },
  subDetailsButton: {
    'en-EN': 'View details',
    'it-IT': 'Visualizza dettagli'
  },
  investorUpgrade: {
    'en-EN': 'Upgrade to Investor now',
    'it-IT': "Effettua ora l'upgrade a Investor"
  },
  investorUpgradeDescr: {
    'en-EN':
      'Get AI Portfolios and manage your assets up to a total of $25.000 in value',
    'it-IT':
      'Ottieni gli AI Portfolio e gestisci i tuoi asset fino ad un totale di $25.000 in controvalore'
  },
  investorUpgradeDescrABTest: {
    'en-EN': 'Invest and manage more than $10.000 with AI Portfolios',
    'it-IT': 'Investi e gestisci con gli AI Portfolio più di $10.000'
  },
  for: {
    'en-EN': ' for ',
    'it-IT': ' a '
  },
  privateUpgrade: {
    'en-EN': 'Would you like to manage more than $25.000 with Hodlie?',
    'it-IT': 'Vuoi gestire con Hodlie più di $25.000?'
  },
  privateUpgradeDescr: {
    'en-EN':
      'Become a Private user now: manage your assets without limits and receive dedicated support',
    'it-IT':
      "Diventa ora Private: gestisci i tuoi assets senza limiti e ottieni un'assistenza dedicata"
  },
  updateMethod: {
    'en-EN': 'Update payment method',
    'it-IT': 'Aggiorna metodo di pagamento'
  },
  updateButtton: {
    'en-EN': 'Update',
    'it-IT': 'Aggiorna'
  },
  editSub: {
    'en-EN': 'Edit subscription',
    'it-IT': 'Modifica abbonamento'
  },
  editSubButtton: {
    'en-EN': 'Edit',
    'it-IT': 'Modifica'
  },
  upgradeButtton: {
    'en-EN': 'Upgrade',
    'it-IT': "Effettua l'upgrade"
  },
  delete: {
    'en-EN': 'Delete your Subscription',
    'it-IT': 'Annulla il tuo Abbonamento'
  },
  deleteDescr: {
    'en-EN':
      'Are you sure? Asset management will be in your hands and we will no longer be able to help you',
    'it-IT':
      'Sei sicuro? La gestione degli asset sarà in mano tua e non potremo più aiutarti'
  },
  deleteButtton: {
    'en-EN': 'Delete',
    'it-IT': 'Elimina'
  },
  subDetailsText: {
    'en-EN': 'Subscription Details',
    'it-IT': 'Dettagli Abbonamento'
  },
  subManageText: {
    'en-EN': 'Manage Subscription',
    'it-IT': 'Gestisci Abbonamento'
  },
  deleteModalTitle: {
    'en-EN': 'Attention',
    'it-IT': 'Attenzione'
  },
  deleteModalMessage_1: {
    'en-EN': `By canceling your subscription, you will <b>lose</b> the special rate reserved for customers who, like you, believed in us from the beginning. This rate, <b>now locked for you</b>, cannot be recovered in case of reactivation, and the new subscription will be subject to updated conditions and <a href='https://hodlie.ai/abbonamenti/' target='_blank'>prices</a>.`,
    'it-IT': `Disdicendo il tuo abbonamento <b>perderai</b> la tariffa speciale riservata ai clienti che come te hanno creduto in noi dall'inizio. Questa tariffa, <b>ora bloccata per te</b>, non potrà essere recuperata in caso di riattivazione, e il nuovo abbonamento sarà soggetto alle condizioni e ai <a href='https://hodlie.ai/abbonamenti/' target='_blank'>prezzi aggiornati</a>.`
  },
  deleteModalMessage_2: {
    'en-EN': `The prices and conditions of our subscriptions have been updated. By continuing your subscription without cancellations, you will maintain the current rate and conditions. In case of cancellation, the starting rate will no longer be recoverable, and to reactivate the service, new conditions and <a href='https://hodlie.ai/abbonamenti/' target='_blank'>updated prices</a> will apply.`,
    'it-IT': `I prezzi e le condizioni dei nostri abbonamenti sono stati aggiornati. Continuando il tuo abbonamento senza disdette, manterrai la tariffa e le condizioni attuali. In caso di disdetta, la tariffa di partenza non sarà più recuperabile e, per riattivare il servizio, saranno applicate le nuove condizioni e <a href='https://hodlie.ai/abbonamenti/' target='_blank'>prezzi aggiornati</a>.`
  },
  deleteModalMessage_3: {
    'en-EN': `If you decide to cancel your subscription, Hodlie will stop managing your funds. Strategies will enter an error state, ceasing to operate. If you cancel your subscription now, it will end on the date specified at the time of subscription.`,
    'it-IT': `Se decidi di disdire il tuo abbonamento, Hodlie smetterà di gestire i tuoi fondi. Le strategie entreranno in stato di errore, smettendo di operare. Se disdici il tuo abbonamento ora, questo terminerà nella data prevista in fase di sottoscrizione.`
  },
  backButton: {
    'en-EN': 'Back',
    'it-IT': 'Indietro'
  },
  delbutton: {
    'en-EN': 'Delete',
    'it-IT': 'Elimina'
  }
};

export const BnbDiscountModal = {
  title: {
    'en-EN': 'BNB Discount',
    'it-IT': 'Sconto BNB'
  },
  generalText: {
    'en-EN':
      'Do you want to change your grant to use BNB for Discount Fee Program? <br>This will change the selection for all your active strategies connected to Binance, and the change will impact also in Binance itself.',
    'it-IT':
      "Vuoi modificare la concessione per l'utilizzo dello sconto BNB sulle commissioni di Binance? Il cambiamento riguarderà tutte le strategie attive collegate a Binance, e impatterà anche su Binance stesso."
  },
  walletAgreement: {
    'en-EN':
      "If you are sure, let's procede with the editing of this setting in the Wallet page.",
    'it-IT':
      'Se sei sicuro, procedi con la modifica di questa impostazione nella pagina Wallet.'
  },
  actualSetting: {
    'en-EN': 'Your actual setting is:',
    'it-IT': 'La tua impostazione attuale è:'
  },
  bnbDiscountPositiveText: {
    'en-EN': 'Agree',
    'it-IT': 'Acconsento'
  },
  bnbDiscountNegativeText: {
    'en-EN': 'NOT Agree',
    'it-IT': 'NON Acconsento'
  },
  moreInfo: {
    'en-EN':
      'For more information click <a href="/bnbdiscountpage"><b><u>here</u></b></a>.',
    'it-IT':
      'Per maggiori informazioni clicca <a href="/bnbdiscountpage"><b><u>qui</u></b></a>.'
  },
  goBack: {
    'en-EN': 'Go back',
    'it-IT': 'Torna indietro'
  },
  goToWallet: {
    'en-EN': 'Go to Wallet',
    'it-IT': 'Vai al Wallet'
  }
};

export const BnbDiscountPage = {
  titleCard: {
    'en-EN': 'BNB Discount Program',
    'it-IT': 'Programma di Sconto BNB'
  },
  texts: [
    {
      'en-EN':
        'Whenever a transaction is made in the financial markets, fees (commissions) are paid to the Exchange on which the transaction is made. Hodlie does not charge any fees for transactions made, so transaction costs depend exclusively on the Exchange on which the Service relies. These transaction costs are charged by the Exchange by withholding a certain percentage of the coin at that Exchange.',
      'it-IT':
        'Ogni qualvolta viene effettuata una transazione sui mercati finanziari, vengono pagati dei costi (commissioni) all’Exchange sui cui viene svolta la transazione. Hodlie non applica alcuna commissione per le operazioni effettuate, pertanto i costi di transazione dipendono esclusivamente dall’Exchange su cui il Servizio si appoggia. Tali costi di transazione vengono addebitati dall’Exchange trattenendo una determinata percentuale della coin presso l’Exchange stesso.'
    },
    {
      'en-EN':
        'In case the selected Exchange is <a href="https://www.binance.com" target="_blank" rel="noreferrer"><b>Binance</b></a>, the User via setting on his Binance account can choose to pay transaction fees by means of BNB token (Binance Coin), in order to obtain a <b><font color=' +
        goldColor +
        '>25%</font></b> reduction in fee cost.',
      'it-IT':
        'Nel caso in cui l’Exchange selezionato sia <a href="https://www.binance.com" target="_blank" rel="noreferrer"><b>Binance</b></a>, l’Utente tramite impostazione sul suo account di Binance può scegliere di pagare le commissioni di transazione mediante il token BNB (Binance Coin), al fine di ottenere una riduzione del <b><font color=' +
        goldColor +
        '>25%</font></b> costo delle commissioni.'
    },
    {
      'en-EN':
        "Upon connection of the Binance Wallet at Hodlie via the KEY API, Hodlie queries the User's account at the Exchange in order to check which setting is enabled about the mode for payment of transaction fees (whether to use BNB in case of availability - as set by default - or not).",
      'it-IT':
        "Al momento del collegamento del Wallet di Binance presso Hodlie tramite l'API KEY, Hodlie interroga l’account dell’Utente presso l’Exchange al fine di verificare quale impostazione è attivata circa la modalità per il pagamento delle commissioni di transazione (se utilizzare BNB in caso di disponibilità - come impostato di default - oppure no)."
    },
    {
      'en-EN':
        'In case the User has set  <b><font color=' +
        blueColor +
        ">AGREE</font></b> as choice on Binance, Hodlie accommodates this decision by using a small reserve of the capital allocated by the user at the strategy (1.5% of the capital, with a minimum of $6) to buy the BNB token, which is useful for paying commissions at Binance. By doing so, the user benefits from Binance's BNB discount, even in the operation of Hodlie's strategies.",
      'it-IT':
        "Nel caso l'Utente abbia impostato <b><font color=" +
        blueColor +
        ">ACCONSENTO</font></b> come scelta su Binance, Hodlie si adatta a tale decisione, utilizzando una piccola riserva del capitale allocato dall'utente presso la strategia (l'1.5% del capitale, con un minimo di 6$) per comprare il token BNB, utile per pagare le commissioni presso Binance. Così facendo, l'utente usufuisce dello sconto BNB di Binance, anche nell'operatività delle strategie di Hodlie."
    },
    {
      'en-EN':
        'Otherwise, Hodlie will not use any strategy capital reserve to purchase the BNB token and Binance fees will be scaled in full, as in the Exchange itself.',
      'it-IT':
        "In caso contrario, Hodlie non utilizzerà alcuna riserva del capitale delle strategie per disporre del token BNB, e le commissioni di Binance verranno scalate per intero, come sull'Exchange stesso."
    },
    {
      'en-EN':
        "On the <a href='/wallet'><b><u>Wallet</u></b></a> page, the User may decide to change his decision at any time. So, Hodlie will take action on the account at the Exchange in order to make the settings consistent with the choice made (e.g., if the User decides to pay commissions in BNB but does not have the option selected at the Exchange, Hodlie will be authorized to change the setting at the Exchange to accommodate the User's request). In addition, the strategy's operation on Hodlie will adapt to the new decision by buying or selling the BNB token reserve, which is used in the strategies own operation.",
      'it-IT':
        "Nella pagina <a href='/wallet'><b><u>Wallet</u></b></a>, l'Utente può decidere di modificare la propria decisione in qualsiasi momento. In tal caso, Hodlie interverrà sull’account presso l’Exchange al fine di rendere le impostazioni coerenti con la scelta effettuata (ad esempio, se l’Utente decidesse di pagare le commissioni in BNB ma non avesse l’opzione selezionata presso l’Exchange, Hodlie sarà autorizzata a modificare l’impostazione presso l’Exchange per soddisfare la richiesta dell’Utente). Inoltre, l'operatività delle strategie su Hodlie si adatterà alla nuova decisione, comprando o vendendo la riserva di token BNB, utilizzata nell'operatività delle stesse."
    }
  ]
};

export const BotCardLanguage = {
  CreationDate: {
    'en-EN': 'AI Agent started on ',
    'it-IT': 'AI Agent avviato il '
  },
  edit: {
    'en-EN': "change your Agent's name",
    'it-IT': 'cambia il nome del tuo Agent'
  },
  cancel: {
    'en-EN': 'cancel',
    'it-IT': 'annulla'
  },
  confirm: {
    'en-EN': 'confirm',
    'it-IT': 'conferma'
  },
  initialAmount: {
    'en-EN': 'Initial amount',
    'it-IT': 'Capitale iniziale'
  }
};

export const BotCategory = {
  AI: {
    'en-EN': 'Artificial Intelligence',
    'it-IT': 'Intelligenza Artificiale'
  },
  AIsubtitle: {
    'en-EN':
      'AI Agents powered by AI. They manage everything: buy and sell orders, volume and timing.',
    'it-IT':
      'AI Agents gestiti da AI. Si occupano di tutto: ordini di acquisto e vendita, volume e timing.'
  },
  grid: {
    'en-EN': 'Grid Strategy',
    'it-IT': 'Strategia a Griglia'
  },
  tech: {
    'en-EN': 'Technical Analysis',
    'it-IT': 'Analisi Tecnica'
  }
};

export const BotDashboard = {
  title: {
    'en-EN': 'Speak directly with the Hodlie team',
    'it-IT': 'Parla direttamente col team di Hodlie'
  },
  description: {
    'en-EN': 'Need support? We can help. Here is how you can contact us:',
    'it-IT':
      'Hai bisogno di supporto? Possiamo aiutarti. Ecco come contattarci:'
  },
  watsText: {
    'en-EN': 'Write to us on WhatsApp',
    'it-IT': 'Scrivici su WhatsApp'
  },
  watsBadge: {
    'en-EN': 'Only for Investor / Private',
    'it-IT': 'Solo per Investor / Private'
  },
  sendMail: {
    'en-EN': 'Send an email to ',
    'it-IT': 'Invia una mail a '
  },
  videocall: {
    'en-EN': 'Check available slots and book a videocall',
    'it-IT': 'Consulta gli slot disponibili e prenota una videocall'
  },
  noBotTitle: {
    'en-EN': 'You do not have an active Portfolio',
    'it-IT': 'Al momento non hai un Portfolio attivo'
  },
  noBotText: {
    'en-EN':
      'Your funds are not currently managed by AI Portfolio: to continue exploiting the potential of AI, choose the Portfolio that best suits your needs and start it!',
    'it-IT':
      "I tuoi fondi non sono attualmente gestiti da un AI Portfolio: per continuare a sfruttare le potenzialità dell'AI, scegli il Portfolio che più si adatta alle tue esigenze e avvialo!"
  },
  noBotButton: {
    'en-EN': 'Invest',
    'it-IT': 'Investi'
  },
  isPendingTitle: {
    'en-EN': 'Your ',
    'it-IT': 'Il tuo '
  },
  isPendingTitle_2: {
    'en-EN': ' is being prepared!',
    'it-IT': ' è in fase di preparazione!'
  },
  isPendingText: {
    'en-EN':
      'You will receive an email within few minutes to confirm the successful creation of your AI Portfolio.',
    'it-IT':
      "Riceverai un'email entro qualche minuto per confermare la buona riuscita della creazione del tuo AI Portfolio."
  },
  riskLevel: {
    'en-EN': 'Risk Level',
    'it-IT': 'Livello di Rischio'
  }
};

export const BotDetails = {
  NotificationNotGranted: {
    'en-EN': 'Grant notifications permissions to receive orders updates',
    'it-IT':
      'Abilita le notifiche per ricevere aggiornamenti sugli ordini effettuati dai tuoi Agents'
  },
  ReceiveNotifications: {
    'en-EN': 'Notifications',
    'it-IT': 'Notifiche'
  },
  loading: {
    'en-EN': 'loading',
    'it-IT': 'in lavorazione'
  },
  preparation: {
    'en-EN': 'The Agent is being prepared, details will be available shortly.',
    'it-IT':
      'Il tuo Agent è in preparazione, i dettagli saranno disponibili a breve'
  },
  dashboardLink: {
    'en-EN': 'Go to Dashboard',
    'it-IT': 'Vai alla Dashboard'
  },
  notificationsInfo: {
    'en-EN':
      'Enable notifications to receive updates on transactions executed by this Agent',
    'it-IT':
      'Attiva le notifiche per ricevere aggiornamenti sulle transazioni effettuate da questo Agent'
  },
  notAvailable: {
    'en-EN': 'NA',
    'it-IT': 'ND'
  },
  noOrderbanner: {
    'en-EN':
      'The history of orders placed by your Agents is temporarily unavailable and will be restored shortly.',
    'it-IT':
      'Lo storico degli ordini effettuati dal tuo Agents è temporaneamente non disponibile e verrà ripristinato a breve.'
  }
};

export const botsDescription = {
  iBot30: {
    'en-EN':
      'AI Agent driven by Hodlie deep learning and reinforcement learning models. A very adaptive strategy, it learns from its mistakes and changes the route quickly.',
    'it-IT':
      'Strategia di trading guidato dai modelli di deep learning e reinforcement learning di Hodlie. Una strategia che impara dai propri errori e cambia rapidamente rotta.'
  },
  iBot60: {
    'en-EN':
      'AI Agent driven by Hodlie deep learning models. Focused on macro-trends, fit the bear markets very well.',
    'it-IT':
      'Strategia di trading guidato da modelli di deep learning di Hodlie. Focalizzato sui macrotrend, si adatta molto bene ai mercati ribassisti.'
  },
  takeProfitBot30: {
    'en-EN':
      'AI Agent driven by Hodlie deep learning models. Constructed according to a scalping strategy, exploits market inefficiencies. Moves capital many times and remains exposed for a short period of time',
    'it-IT':
      'Strategia di trading guidato dai modelli di deep learning di Hodlie. Costruito secondo una strategia di scalping, sfrutta le inefficienze del mercato. Sposta il capitale molte volte e rimane esposto per un breve periodo di tempo.'
  },
  takeProfitBot60: {
    'en-EN':
      'AI Agent driven by Hodlie deep learning models. Optimized to reduce risk, it remains exposed to the market as little as possible in order to minimize potential portfolio losses',
    'it-IT':
      'Strategia di trading guidato da modelli di deep learning di Hodlie. Ottimizzato per ridurre il rischio, resta esposto il meno possibile al fine di minimizzare le potenziali perdite del portafoglio'
  }
};

export const BotInfo = {
  botInfoPop: {
    'en-EN':
      'Information about the Agent like name, category and strategy. Click on the Agent to get more details',
    'it-IT':
      "Informazioni sull'Agent come nome, categoria e strategia utilizzata. Clicca sull'Agent per avere ulteriori dettagli."
  },
  status: {
    'en-EN': 'Status',
    'it-IT': 'Stato'
  },
  investment: {
    'en-EN': 'Investment',
    'it-IT': 'Investimento'
  },
  investmentPop: {
    'en-EN': 'The initial amount of the Agent',
    'it-IT': "Il capitale iniziale dell'Agent"
  },
  now: {
    'en-EN': 'Now',
    'it-IT': 'Adesso'
  },
  nowPop: {
    'en-EN': 'The current amount',
    'it-IT': 'Il capitale attuale'
  },
  performancePop: {
    'en-EN': 'The earnings since the Agent started up to now',
    'it-IT':
      "I guadagni dal momento in cui l'Agent è stato avviato fino ad ora."
  },
  market: {
    'en-EN': 'Market',
    'it-IT': 'Mercato'
  },
  marketPop: {
    'en-EN': 'The market gain since the Agent started up to now',
    'it-IT': "La crescita del mercato dall'avvio dell'Agent fino ad ora."
  },
  changeStatus: {
    'en-EN': 'Change Status',
    'it-IT': 'Cambia Stato'
  },
  noBot: {
    'en-EN': "You haven't run a strategy yet",
    'it-IT': 'Non hai ancora creato una strategia'
  },
  active: {
    'en-EN': 'active',
    'it-IT': 'attivo'
  },
  paused: {
    'en-EN': 'paused',
    'it-IT': 'pausa'
  },
  error: {
    'en-EN': 'error',
    'it-IT': 'errore'
  },
  more: {
    'en-EN': 'more',
    'it-IT': 'apri'
  },
  notAvailable: {
    'en-EN': 'NA',
    'it-IT': 'ND'
  },
  notAvailablePopOver: {
    'en-EN':
      'This value will be available after two weeks from the Agent start or capital increase',
    'it-IT':
      "Questo valore sarà disponibile dopo due settimane dall'avvio dell'Agent o dall'aumento di capitale"
  },
  underManagement: {
    'en-EN': 'Managed Capital:',
    'it-IT': 'Controvalore in gestione:'
  },
  invested: {
    'en-EN': 'Invested',
    'it-IT': 'Investito'
  },
  managed: {
    'en-EN': 'Managed',
    'it-IT': 'Gestito'
  }
};

export const botsBadge = {
  iBot30: {
    'en-EN': [
      ['high-risk', 'warning'],
      ['reinforcement', 'warning']
    ],
    'it-IT': [
      ['alto-rischio', 'warning'],
      ['reinforcement', 'warning']
    ]
  },
  iBot60: {
    'en-EN': [
      ['medium-risk', 'primary'],
      ['macro', 'primary']
    ],
    'it-IT': [
      ['medio-rischio', 'primary'],
      ['macro', 'primary']
    ]
  },
  takeProfitBot30: {
    'en-EN': [
      ['low-risk', 'success'],
      ['high-volume', 'success'],
      ['long-term', 'success']
    ],
    'it-IT': [
      ['basso-rischio', 'success'],
      ['grossi-volumi', 'success'],
      ['lungo-termine', 'success']
    ]
  },
  takeProfitBot60: {
    'en-EN': [
      ['Low-risk', 'secondary'],
      ['high-volume', 'secondary'],
      ['macro', 'secondary']
    ],
    'it-IT': [
      ['basso-rischio', 'secondary'],
      ['grossi-volumi', 'secondary'],
      ['macro', 'secondary']
    ]
  },
  onlyVirtual: {
    'en-EN': 'only virtual',
    'it-IT': 'solo virtuale'
  },
  showMore: {
    'en-EN': 'show more',
    'it-IT': 'scopri di più'
  },
  showText: {
    'en-EN':
      'Want to unlock it? Go to the <a href="/rewards">referral</a> page and follow the procedure.',
    'it-IT':
      'Vuoi sbloccarlo? Vai alla pagina <a href="/rewards">referral</a> e segui la procedura.'
  }
};

export const BotState = {
  title: {
    'en-EN': 'Agent Status',
    'it-IT': "Stato dell'Agent"
  },
  subtitle: {
    'en-EN': 'Here you can change the Agent status or delete it',
    'it-IT': "Qui puoi cambiare lo stato dell'Agent oppure eliminarlo"
  },
  activeTooltip: {
    'en-EN': 'Pause the Agent',
    'it-IT': "Metti in pausa l'Agent"
  },
  pausedTooltip: {
    'en-EN': 'Restart the Agent',
    'it-IT': "Fai ripartire l'Agent"
  },
  terminatedTooltip: {
    'en-EN': 'You cannot restart a terminated Agent',
    'it-IT': 'Non puoi far ripartire un Agent eliminato'
  },
  stoppedAgentTooltip: {
    'en-EN': 'It is no longer possible to restart your Agent, please delete it',
    'it-IT': 'Non è più possibile far ripartire il tuo Agent, eliminalo'
  },
  addTooltip: {
    'en-EN': 'Add capital to your Agent',
    'it-IT': 'Aggiungi capitale al tuo Agent'
  },
  noAddTooltip: {
    'en-EN': 'Is not possible to add capital to your Agent',
    'it-IT': 'Non è possibile aggiungere capitale al tuo Agent'
  },
  delete: {
    'en-EN': 'Delete Agent',
    'it-IT': "Elimina l'Agent"
  },
  noDelete: {
    'en-EN': 'You cannot delete a terminated strategy',
    'it-IT': 'Non puoi eliminare un Agent eliminato'
  },
  changeBnbDiscount: {
    'en-EN': 'Change the grant to use the BNB Discount',
    'it-IT': "Modifica la concessione per l'utilizzo dello sconto BNB"
  },
  soon: {
    'en-EN': 'This feature will be available soon',
    'it-IT': 'Questa funzione sarà presto disponibile'
  }
};

export const botStatusError = {
  errorText407: {
    'en-EN':
      'Error 407: API Key not valid. Please go to the Wallet page, delete your API Key, and create a new one to resolve the issue.',
    'it-IT':
      'Errore 407: API Key non valida. Ti preghiamo di dirigerti alla sezione Wallet, cancellare la tua API Key e ricrearne una nuova per risolvere il problema.'
  },
  errorText410: {
    'en-EN':
      'Error 410: Your AI Portfolio has stopped operating due to a misalignment between your Binance portfolio and Hodlie. To restore functionality, delete this AI Portfolio and recreate it.',
    'it-IT':
      "Errore 410: Il tuo AI Portfolio ha smesso di operare a causa di un disallineamento tra il tuo portafoglio Binance e Hodlie. Per ripristinare l'operatività, cancella questo AI Portfolio e ricrealo."
  },
  clickText: {
    'en-EN': ' Click here to learn more.',
    'it-IT': ' Clicca qui per saperne di più.'
  }
};

export const botStatusLang = {
  ACTIVE: {
    'en-EN': 'active',
    'it-IT': 'attivo'
  },
  PAUSED: {
    'en-EN': 'paused',
    'it-IT': 'in pausa'
  },
  TERMINATED: {
    'en-EN': 'terminated',
    'it-IT': 'terminato'
  },
  ERROR: {
    'en-EN': 'error',
    'it-IT': 'errore'
  }
};

export const botStoryTelling = {
  // Deep Scalper
  takeProfitBot30: {
    'en-EN': `Deep Scalper is a tireless miner, armed with <b>scalping algorithms</b>, who constantly ventures into the intricate galleries of trading in search of small precious profits. 
    He carefully digs, continuously and <b>tirelessly, remaining exposed to volatility as little as possible</b>, in order to reduce risk and avoid being overwhelmed by market avalanches. When AI signals suggest exposure, he uses them as a compass, determining the exact point to place a take-profit limit order.
     On the other hand, when it's time to retreat, he sets a lower buy limit order to limit losses.<br><br> If, due to a lack of available capital, the limit order is not "filled", Deep Scalper acts quickly, swiftly repositioning the order to close or reopen the position. 
     His incessant work leads him to make <b>up to 50 trades a day</b>, leveraging <b>every fragment of his capital</b> to maximize the effectiveness of his work, like a miner chasing every little spark.<br><br> Although he is a workaholic, <b>Deep Scalper has his limits</b>, and can only operate under certain market conditions. 
     In particular, it is necessary for the Exchange he operates on to have zero fees on a certain asset-quote pair, high volumes, and minimal spread in the order table. If these conditions disappear, <b>Deep Scalper will automatically signal an "error" state</b>, ceasing to operate to avoid entering an unfavorable situation. <br><br>`,
    'it-IT': `Deep Scalper è un instancabile minatore, che armato di <b>algoritmi scalping</b>, si avventura costantemente nelle intricate gallerie del trading alla ricerca di piccoli preziosi profitti. Scava con cura, continuamente e <b>instancabilmente, rimanendo esposto alla volatilità il meno possibile</b>, al fine di ridurre il rischio ed evitare di essere travolto dalle frane di mercato.
Quando i segnali dell’AI gli suggeriscono di esporsi, li utilizza come una bussola, determinando il punto esatto per piazzare un ordine limite take profit. D’altra parte, quando è giunto il momento di ritirarsi, imposta un ordine limite buy più basso, per limitare le perdite.<br><br>
Se, per mancanza di disponibilità di capitali, l'ordine limite non viene “fillato”, Deep Scalper agisce rapidamente, riposizionando velocemente l'ordine per chiudere o riaprire la posizione. Il suo lavoro incessante lo porta a compiere <b>fino a 50 operazioni al giorno</b>, sfruttando <b>ogni frammento del suo capitale</b> al fine di massimizzare l’efficacia del suo lavoro, come un minatore che rincorre ogni piccola scintilla.<br><br>
Pur essendo uno stacanovista, <b>Deep Scalper ha i suoi limiti</b>, e può operare solamente  in determinate circostanze di mercato. In particolare occorre che l’Exchange in cui si muove presenti commissioni a zero presso una determinata coppia asset-quote, che ci siano volumi elevati, e che lo spread della relativa order table sia minimo. Nell’ipotesi in cui queste condizioni svaniscano, <b>Deep Scalper automaticamente segnalerà uno stato di “errore”</b>, smettendo di operare al fine di evitare di entrare in una situazione sfavorevole. <br><br>`
  },
  // Dynamo
  iBot30: {
    'en-EN': `AI Agent powered by Deep learning and Reinforcement learning models. It's a strategy that learns from its mistakes and quickly adapts to constantly evolving market conditions.<br><br> Designed to be a tireless hunter of opportunities, <b>when it receives the right information from AI, it acts promptly, deciding in a flash when to open or close positions</b>. 
    Like lightning, it divides the available capital into orders distributed over different time frames. <br><br> 
    To limit losses in the event of a market crash, Dynamo leverages stop-loss orders. Periodically, it reassesses its exposure, making decisions based on indications received from AI models. 
    Additionally, <b>this Agent is constantly engaged in learning from its mistakes in order to optimize the operational management of the strategy</b>.`,
    'it-IT': `AI Agent guidato dai modelli di Deep learning e Reinforcement learning. Si tratta di una strategia che impara dai propri errori e si adatta rapidamente alle condizioni di mercato in continua evoluzione.<br><br>
Progettato per essere un instancabile cacciatore di opportunità, <b>quando riceve le giuste informazioni dall’AI, agisce con prontezza, decidendo in un lampo quando aprire o chiudere posizioni</b>. Come un fulmine, divide il capitale disponibile in ordini distribuiti su diverse fasce orarie. <br><br>
Per limitare le perdite in caso di crollo del mercato, Dynamo sfrutta gli ordini stop-loss. Periodicamente, rivaluta la sua esposizione, prendendo decisioni in base alle indicazioni ricevute dai modelli di AI. Inoltre, <b>questo Agent è costantemente impegnato nell'apprendimento dai propri errori al fine di ottimizzare la gestione operativa della strategia</b>. `
  },
  // Grizzly
  iBot60: {
    'en-EN': `Grizzly is a strategy that specializes in <b>active management of the underlying asset</b>. Like a bear, it uses artificial intelligence models as a scent guide to navigate the market forest, deciding whether to open or liquidate its position.<br><br> 
    When it receives the input to move, <b>Grizzly buys the underlying coin by dividing its loot into multiple orders, wisely spreading them over different time frames</b>. 
    When it feels the need to leave the territory, it follows the same logic, liquidating the position cautiously. Its secret to limit losses? Stop-loss orders, sharp claws ready to protect it from sudden market crashes.<br><br> 
    Periodically, Grizzly reassesses its exposure based on the indications it receives from the models. 
    Naturally trained to reduce investment risk, before taking any steps in the market forest, it carefully verifies that various factors are consistent with its decision. 
    Therefore, <b>it may happen that for several days it goes into hibernation remaining liquid, or stays awake and exposed on the reference asset</b>.<br><br> 
    Its characteristics make it particularly suitable for bearish market phases, its habitat, where it stands out and operates at its best.`,
    'it-IT': `Grizzly è una strategia che si occupa di effettuare una <b>gestione attiva dell’asset sottostante</b>. Come un orso, utilizza i modelli di intelligenza artificiale come guida olfattiva per muoversi nella foresta del mercato, decidendo così se aprire o liquidare la propria posizione.
Quando riceve l’input per muoversi, <b>Grizzly compra la coin sottostante suddividendo il suo bottino in più ordini, spalmandoli con saggezza su diverse fasce orarie</b>. Quando sente il bisogno di abbandonare il territorio, segue la stessa logica, liquidando la posizione con prudenza. Il suo segreto per limitare le perdite? Gli ordini stop-loss, affilati artigli pronti a proteggerlo dai crolli improvvisi del mercato.<br><br>
Ciclicamente, Grizzly rivaluta la propria esposizione a seconda delle indicazioni che riceve dai modelli. Addestrato per natura a ridurre il rischio degli investimenti, prima di fare qualsiasi passo nella foresta del mercato verifica attentamente che diversi fattori siano coerenti con la sua decisione. Pertanto, <b>può capitare che per diversi giorni vada in letargo rimanendo liquido, o resti sveglio ed esposto sull’asset di riferimento</b>.<br><br> 
Le sue caratteristiche lo rendono particolarmente adatto alle fasi di mercato ribassista, il suo habitat, in cui si distingue e opera nel migliore dei modi. 
`
  }, // Centurion
  takeProfitBot60: {
    'en-EN': `Centurion is a true guardian of the portfolio, <b>constantly on the front lines to shield capital from its arch-enemy: market volatility.</b> Equipped with advanced algorithms, he acts decisively to minimize risk and only enters the field after a signal from AI to avoid missteps. 
  As a vigilant warrior, he constantly monitors price fluctuations and <b>demonstrates great caution</b>, enabling him to defend the capital and avoid losses. <br><br> 
  Remaining liquid for most of the time, he skillfully avoids potential traps and <b>protects assets from excessive exposures</b>. He acts prudently, entering the market only when recognizing a significant profit opportunity, while ensuring the safety of the invested capital.<br><br>
  Moving with cunning, he efficiently utilizes his capital movements, leveraging all available resources. His targeted strategy and cautious approach make him a reliable ally in portfolio management..`,
    'it-IT': `Centurion è un vero e proprio guardiano del portafoglio, <b>costantemente in prima linea per proteggere il capitale dal suo acerrimo nemico: la volatilità di mercato.</b> Dotato di algoritmi avanzati, si adopera con decisione per ridurre al minimo il rischio e scende in campo solo dopo un segnale proveniente dall’AI per evitare di compiere passi falsi. Da guerriero attento, vigila costantemente le fluttuazioni dei prezzi e <b>mostra una grande prudenza</b>, che gli permette di difendere il capitale ed evitare le sconfitte. <br><br> 
Mantenendosi liquido per la maggior parte del tempo, evita abilmente potenziali trappole e <b>protegge gli asset da esposizioni eccessive</b>. Agisce con cautela, entrando nel mercato solo quando riconosce un'opportunità di guadagno significativa, garantendo nel contempo la sicurezza del capitale investito.<br><br>
Muovendosi con astuzia, utilizza efficientemente i movimenti del suo capitale, sfruttando tutte le risorse a sua disposizione. La sua strategia mirata e la prudenza nell'operare lo rendono un affidabile alleato nella gestione dei portafogli.`
  },
  // Centurion
  comingCent: `********* è un vero e proprio guardiano del portafoglio, <b>costantemente in prima linea per proteggere il capitale dal suo acerrimo nemico: la volatilità di mercato.</b> Dotato di algoritmi avanzati, si adopera con decisione per ridurre al minimo il rischio e scende in campo solo dopo un segnale proveniente dall’AI per evitare di compiere passi falsi. Da guerriero attento, vigila costantemente le fluttuazioni dei prezzi e <b>mostra una grande prudenza</b>, che gli permette di difendere il capitale ed evitare le sconfitte. <br><br> 
Mantenendosi liquido per la maggior parte del tempo, evita abilmente potenziali trappole e <b>protegge gli asset da esposizioni eccessive</b>. Agisce con cautela, entrando nel mercato solo quando riconosce un'opportunità di guadagno significativa, garantendo nel contempo la sicurezza del capitale investito.<br><br>
Muovendosi con astuzia, utilizza efficientemente i movimenti del suo capitale, sfruttando tutte le risorse a sua disposizione. La sua strategia mirata e la prudenza nell'operare lo rendono un affidabile alleato nella gestione dei portafogli.`
};

export const BotSummary = {
  params: {
    'en-EN': 'Agent Parameters',
    'it-IT': "Parametri dell'Agent"
  },
  type: {
    'en-EN': 'Agent Type',
    'it-IT': 'Tipologia di Agent'
  },
  typeAgent: {
    'en-EN': 'AI Agent',
    'it-IT': 'AI Agent'
  },
  amount: {
    'en-EN': 'Amount',
    'it-IT': 'Quantità'
  },
  fee: {
    'en-EN': 'Fee',
    'it-IT': 'Commissione'
  },
  feeExchangeText: {
    'en-EN': 'Decided by Exchange',
    'it-IT': "Decisa dall'Exchange"
  },
  bnbDiscount: {
    'en-EN': 'BNB Discount',
    'it-IT': 'Sconto BNB'
  },
  bnbDiscountPositiveText: {
    'en-EN': 'Agree',
    'it-IT': 'Acconsento'
  },
  bnbDiscountNegativeText: {
    'en-EN': 'NOT Agree',
    'it-IT': 'NON Acconsento'
  },
  total: {
    'en-EN': 'Total Investment:',
    'it-IT': 'Totale Investito:'
  },
  virtualMode: {
    'en-EN': 'Virtual Mode',
    'it-IT': 'Modalità Virtuale'
  },
  riskLevel: {
    'en-EN': 'Risk Level',
    'it-IT': 'Livello di Rischio'
  }
};

export const ButtonGrid = {
  low: {
    'en-EN': 'Low',
    'it-IT': 'Basso'
  },
  medium: {
    'en-EN': 'Medium',
    'it-IT': 'Medio'
  },
  high: {
    'en-EN': 'High',
    'it-IT': 'Alto'
  }
};

export const CardDropdown = {
  bot_statistics: {
    'en-EN': 'Statistics',
    'it-IT': 'Statistiche'
  },
  orders: {
    'en-EN': 'Orders',
    'it-IT': 'Ordini'
  },
  delete: {
    'en-EN': 'Delete',
    'it-IT': 'Elimina'
  },
  restart: {
    'en-EN': 'Manually restart',
    'it-IT': 'Riavvia manualmente'
  },
  restartSuccess: {
    'en-EN':
      'The restart was successful! <br> Within a few seconds the page will update automatically.',
    'it-IT':
      'Il riavvio è stato effettuato correttamente! <br> Entro pochi secondi la pagina si aggiornerà automaticamente.'
  },
  changeStatusText: {
    'en-EN':
      'Portfolio was successfully restarted! <br> Within a few seconds the page will update automatically.',
    'it-IT':
      'Il portfolio è stato riavviato correttamente! <br> Entro pochi secondi la pagina si aggiornerà automaticamente.'
  },
  monday: {
    'en-EN': 'Monday',
    'it-IT': 'Lunedì'
  },
  tuesday: {
    'en-EN': 'Tuesday',
    'it-IT': 'Martedì'
  },
  wednesday: {
    'en-EN': 'Wednesday',
    'it-IT': 'Mercoledì'
  },
  thursday: {
    'en-EN': 'Thursday',
    'it-IT': 'Giovedì'
  },
  friday: {
    'en-EN': 'Friday',
    'it-IT': 'Venerdì'
  },
  saturday: {
    'en-EN': 'Saturday',
    'it-IT': 'Sabato'
  },
  sunday: {
    'en-EN': 'Sunday',
    'it-IT': 'Domenica'
  },
  active: {
    'en-EN': 'active',
    'it-IT': 'attivo'
  },
  paused: {
    'en-EN': 'paused',
    'it-IT': 'pausa'
  },
  error: {
    'en-EN': 'error',
    'it-IT': 'errore'
  },
  info: {
    'en-EN': 'Portfolio Info',
    'it-IT': 'Informazioni Portfolio'
  },
  state: {
    'en-EN': 'State',
    'it-IT': 'Stato'
  },
  assets: {
    'en-EN': 'Asset List',
    'it-IT': 'Lista Asset'
  },
  risks: {
    'en-EN': 'Risk Level',
    'it-IT': 'Livello di Rischio'
  },
  creationDate: {
    'en-EN': 'Start Date',
    'it-IT': 'Data Creazione'
  },
  startValue: {
    'en-EN': 'Start Amount',
    'it-IT': 'Controvalore Iniziale'
  },
  addedCapital: {
    'en-EN': 'Added Capital',
    'it-IT': 'Capitale Aggiunto'
  },
  underManagement: {
    'en-EN': 'Under Management',
    'it-IT': 'In Gestione'
  },
  pacError: {
    'en-EN':
      'We encountered an issue performing the expected recurring boost for your AI Portfolio. The operation was not successful because the expected liquidity could not be withdrawn due to insufficient balance on your Binance wallet. Please check the availability of funds and manually reactivate your recurring increase.',
    'it-IT':
      "Abbiamo riscontrato un problema nell'esecuzione dell'aumento ricorrente previsto per il tuo AI Portfolio. L'operazione non è andata a buon fine poiché non è stato possibile prelevare la liquidità prevista per saldo insufficiente sul tuo portafoglio Binance. Ti invitiamo a verificare la disponibilità dei fondi e a riattivare manualmente il tuo aumento ricorrente."
  },
  startDate: {
    'en-EN': 'Start Date',
    'it-IT': 'Data Inizio'
  },
  mode: {
    'en-EN': 'Mode',
    'it-IT': 'Modalità'
  },
  quantity: {
    'en-EN': 'Quantity',
    'it-IT': 'Quantità'
  },
  deletePAC: {
    'en-EN': 'Delete recurring increase',
    'it-IT': 'Elimina aumento ricorrente'
  },
  editPAC: {
    'en-EN': 'Edit recurring increase',
    'it-IT': 'Modifica aumento ricorrente'
  },
  deletePACText: {
    'en-EN':
      '“Are you sure you want to eliminate the recurring increase for this AI Portfolio?”',
    'it-IT':
      "Sei sicuro di voler eliminare l'aumento ricorrente per questo AI Portfolio?"
  },
  recurringIncrease: {
    'en-EN': 'Recurring Increase',
    'it-IT': 'Aumento Ricorrente'
  },
  amount: {
    'en-EN': 'Amount',
    'it-IT': 'Quantità'
  },
  minAmountError: {
    'en-EN': 'The amount entered must be greater than $50',
    'it-IT': 'La quantità inserita deve essere superiore a 50$'
  },
  pacDisclaimer: {
    'en-EN':
      '*Hodlie takes care of setting up a recurring plan to increase capital under management. Please remember, however, that for correct operation, it is necessary to set the "Recurring Purchase" functionality directly on Binance, in order to always have a desired quantity of assets available.',
    'it-IT':
      '*Hodlie si occupa di impostare un piano ricorrente per aumentare il capitale in gestione. Si ricorda però che è necessario, per un corretto funzionamento, impostare la funzionalità di "Acquisto Ricorrente" direttamente su Binance, al fine di avere sempre una quantità desiderata di asset disponibili.'
  },
  recurringIncreaseEdit: {
    'en-EN':
      'Your recurring increase has been edit successfully! <br> Within a few seconds the page will update automatically.',
    'it-IT':
      'Il tuo aumento ricorrente è stato modificato correttamente! <br> Entro pochi secondi la pagina si aggiornerà automaticamente.'
  },
  recurringIncreaseDeleted: {
    'en-EN':
      'Your recurring increase has been deleted successfully! <br> Within a few seconds the page will update automatically.',
    'it-IT':
      'Il tuo aumento ricorrente è stato eliminato correttamente! <br> Entro pochi secondi la pagina si aggiornerà automaticamente.'
  },
  confirm: {
    'en-EN': 'Confirm',
    'it-IT': 'conferma'
  },
  close: {
    'en-EN': 'Close',
    'it-IT': 'Chiudi'
  },
  back: {
    'en-EN': 'Back',
    'it-IT': 'Indietro'
  },
  years: {
    'en-EN': ' years ago',
    'it-IT': ' anni fa'
  },
  months: {
    'en-EN': ' months ago',
    'it-IT': ' mesi fa'
  },
  days: {
    'en-EN': ' days ago',
    'it-IT': ' giorni fa'
  },
  hours: {
    'en-EN': ' hours ago',
    'it-IT': ' ore fa'
  },
  minutes: {
    'en-EN': ' minutes ago',
    'it-IT': ' minuti fa'
  },
  seconds: {
    'en-EN': ' seconds ago',
    'it-IT': ' secondi fa'
  },
  monthly: {
    'en-EN': 'Monthly',
    'it-IT': 'Mensile'
  },
  weekly: {
    'en-EN': 'Weekly',
    'it-IT': 'Settimanale'
  },
  buy: {
    'en-EN': 'Buy',
    'it-IT': 'Comprati'
  },
  sell: {
    'en-EN': 'Sell',
    'it-IT': 'Venduti'
  },
  orderHistory: {
    'en-EN': 'Orders History',
    'it-IT': 'Storico Ordini'
  },
  noOrders: {
    'en-EN': 'There are no orders.',
    'it-IT': 'Non sono presenti ordini.'
  },
  noOrdersLastTime: {
    'en-EN':
      "Even if you don't see any trades in the last +48h, your Portfolio is still working for you.",
    'it-IT':
      'Anche se non vedi operazioni nelle ultime +48h, il tuo Portfolio sta continuando a lavorare per te.'
  },
  pacDetails: {
    'en-EN': 'Saving plan details',
    'it-IT': 'Dettagli aumento ricorrente'
  },
  selectInterval: {
    'en-EN': 'Select interval',
    'it-IT': 'Seleziona il periodo'
  },
  noStats: {
    'en-EN': 'Statistics are currently unavailable.',
    'it-IT': 'Le statistiche non sono attualmente disponibili.'
  },
  eventPending: {
    'en-EN':
      'An increase in capital is underway, the data for your AI Portfolio will be available shortly',
    'it-IT':
      'Un aumento di capitale è in corso, a breve saranno disponibili i dati del tuo AI Portfolio'
  },
  statsData: {
    'en-EN': '* The data in the statistics section are updated as of ',
    'it-IT': '* I dati presenti nella sezione statistiche sono aggiornati al '
  },
  statsDataAdd: {
    'en-EN':
      '** In the case of a capital increase, the monthly percentage is calculated using the Time-Weighted Rate of Return (TWR)',
    'it-IT':
      '** In caso di aumento di capitale, la percentuale mensile viene calcolata tramite il Time-Weighted Rate of Return (TWR)'
  }
};

export const categoryList = {
  'Artificial Intelligence': {
    'en-EN': 'Artificial Intelligence',
    'it-IT': 'Intelligenza Artificiale'
  },
  'Technical Analysis': {
    'en-EN': 'Technical Analysis',
    'it-IT': 'Analisi Tecnica'
  }
};

export const ChangeBotStatus = {
  title: {
    'en-EN': 'Are you sure?',
    'it-IT': 'Sei sicuro?'
  },
  textIfActive: {
    'en-EN':
      'Pausing it allows you to decide whether to keep the current asset quantity or sell them all.',
    'it-IT':
      "Metterlo in pausa ti consente di decidere se mantenere l'attuale quantità di asset o vendere tutto."
  },
  textIfActiveReal: {
    'en-EN':
      ' To prevent your strategy from encountering errors upon resuming, it is important not to move the dedicated funds.',
    'it-IT':
      ' Affinché la tua strategia non vada in errore al momento del riavvio è importante non spostare i fondi dedicati ad esso.'
  },
  textIfActive1: {
    'en-EN': 'Select below whether to hold or sell the asset',
    'it-IT': "Seleziona qui sotto se mantenere o vendere l'asset"
  },
  textIfPaused: {
    'en-EN': 'By continuing, the Agent will resume its operations.',
    'it-IT': 'Continuando il tuo Agent riprenderà ad effettuare operazioni.'
  },
  continue: {
    'en-EN': 'Do you want to continue?',
    'it-IT': 'Vuoi continuare?'
  },
  goBack: {
    'en-EN': 'No, go back',
    'it-IT': 'No, torna indietro'
  },
  yes: {
    'en-EN': 'Yes',
    'it-IT': 'Si'
  },
  hold: {
    'en-EN': 'Hold',
    'it-IT': 'Mantieni'
  },
  sell: {
    'en-EN': 'Sell',
    'it-IT': 'Vendi'
  },
  amount: {
    'en-EN': 'Amount',
    'it-IT': 'Quantità'
  },
  total: {
    'en-EN': 'Total',
    'it-IT': 'Totale'
  }
};

export const ChangeVersionModal = {
  titleAndroid: {
    'en-EN': 'A new version of the app is available on the Google Play Store',
    'it-IT': "Una nuova versione dell'app è disponibile sul Google Play Store"
  },
  downloadNow: {
    'en-EN': 'Download now:',
    'it-IT': 'Scaricala ora:'
  },
  srcIconAndroid: {
    'en-EN':
      'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png',
    'it-IT':
      'https://play.google.com/intl/en_us/badges/static/images/badges/it_badge_web_generic.png'
  },
  textAndroid: {
    'en-EN': 'Disponibile su Play Store',
    'it-IT': 'Get it on Play Store'
  },
  close: {
    'en-EN': 'Close',
    'it-IT': 'Chiudi'
  },
  newVersion: {
    'en-EN': 'Update App',
    'it-IT': "Aggiorna l'App"
  }
};

export const ComingSoon = {
  working: {
    'en-EN': 'We are working on this page',
    'it-IT': 'Stiamo lavorando a questa pagina'
  },
  dashboardLink: {
    'en-EN': 'Go to Dashboard',
    'it-IT': 'Vai alla Dashboard'
  }
};

export const Community = {
  title: {
    'en-EN': "Don't miss anything, join our community now!",
    'it-IT': 'Non perderti nulla, unisciti alla nostra community adesso!'
  },
  where: {
    'en-EN': 'You can find us here:',
    'it-IT': 'Puoi trovarci qui:'
  }
};

export const ConfirmMailForm = {
  notFoundTitle: {
    'en-EN': 'Link not found',
    'it-IT': 'Link non trovato'
  },
  notFoundText: {
    'en-EN':
      'To obtain a new link, return to the Dashboard and request another email to verify your address.',
    'it-IT':
      "Per ottenere un nuovo link, torna alla Dashboard e richiedi un'altra email per la verifica del tuo indirizzo."
  },
  goToProfile: {
    'en-EN': 'Go to Profile',
    'it-IT': 'Vai al profilo'
  },
  foundTitle: {
    'en-EN': 'Email Confirmed',
    'it-IT': 'Mail Confermata'
  },
  foundText: {
    'en-EN': 'Now your registration is complete.',
    'it-IT': 'Ora la tua registrazione è completa.'
  },
  foundText2: {
    'en-EN': 'Now return to the Home to proceed.',
    'it-IT': 'Torna ora alla Home per proseguire.'
  }
};

export const ConfirmMailModal = {
  text: {
    'en-EN': 'To proceed, you need to verify your email address',
    'it-IT': 'Per procedere, è necessario verificare il tuo indirizzo email'
  },
  text1: {
    'en-EN':
      "If you haven't done so yet, check your inbox and Spam folder. If you don't find the email, click below to request a new one.",
    'it-IT':
      "Se non lo hai ancora fatto, controlla la tua posta in arrivo e la cartella Spam. Se non trovi l'email, clicca qui sotto per richiederne una nuova."
  },
  button: {
    'en-EN': 'Send verification email',
    'it-IT': 'Invia email di verifica'
  },
  msg: {
    'en-EN': "Email Sent! Check your spam folder if you can't find it",
    'it-IT': 'Mail Inviata! Controlla lo spam se non riesci a trovarla'
  },
  close: {
    'en-EN': 'Close',
    'it-IT': 'Chiudi'
  },
  smallMsg: {
    'en-EN': 'If you need support, write to us at ',
    'it-IT': 'Se hai bisogno di supporto, scrivici a '
  }
};

export const CreateBot = {
  title: {
    'en-EN': 'Create Bot',
    'it-IT': 'Creazione Bot'
  },
  titleInvest: {
    'en-EN': 'Invest',
    'it-IT': 'Investi'
  },
  subtitleInvest: {
    'en-EN':
      'Discover our strategies and find the one best suited to your needs',
    'it-IT':
      'Scopri le nostre strategie e trova quella più adatta alle tue esigenze'
  },
  subtitle: {
    'en-EN': 'Build, setup and run your own AI Agent',
    'it-IT': 'Crea, configura ed avvia il tuo Agent personale.'
  },
  insertPositive: {
    'en-EN': 'Insert a positive number',
    'it-IT': 'Inserisci un numero positivo'
  },
  acceptedPositive: {
    'en-EN': 'Only positive numbers accepted',
    'it-IT': 'Sono accettati solo numeri positivi'
  },
  acceptedNotNegative: {
    'en-EN': 'Negative numbers are not allowed',
    'it-IT': 'Non sono accettati numeri negativi'
  },
  maximumValuePositive: {
    'en-EN': 'The maximum allowed number is',
    'it-IT': 'Il massimo valore consentito è '
  },
  lessAmount: {
    'en-EN': 'The amount must be less than the wallet credit',
    'it-IT': "L'importo deve essere inferiore al credito del wallet"
  },
  notAvailable: {
    'en-EN': 'Not Available',
    'it-IT': 'Strategia Reale Non Disponibile'
  },
  notAvailableText: {
    'en-EN':
      'To start your first strategy you must have completed all the previous steps',
    'it-IT':
      'Per avviare la tua prima strategia devi aver completato tutti i passaggi precedenti'
  },
  complete: {
    'en-EN': 'Complete',
    'it-IT': 'Completa'
  },
  errorMsgBot: {
    'en-EN': '"There was an error launching the strategy."',
    'it-IT': "C'è stato un errore durante l'avvio della strategia."
  },
  months: {
    'en-EN': [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    'it-IT': [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre'
    ]
  },
  shortMonths: {
    'en-EN': [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ],
    'it-IT': [
      'Gen',
      'Feb',
      'Mar',
      'Apr',
      'Mag',
      'Giu',
      'Lug',
      'Ago',
      'Set',
      'Ott',
      'Nov',
      'Dec'
    ]
  },
  noData: {
    'en-EN': 'No data available',
    'it-IT': 'Nessun dato disponibile'
  },
  assetManagement: {
    'en-EN': 'AI Portfolios',
    'it-IT': 'AI Portfolio'
  },
  allProducts: {
    'en-EN': 'All Products',
    'it-IT': 'Tutti i Prodotti'
  },
  assetManagementSubtitle: {
    'en-EN':
      'Investment strategies on multiple crypto-assets that are periodically rebalanced depending on the predefined risk level',
    'it-IT':
      'Strategie di investimento su molteplici crypto-asset che vengono ribilanciate periodicamente a seconda del livello di rischio predefinito'
  },
  allProductsSubtitle: {
    'en-EN': 'Single asset trading strategies ',
    'it-IT': 'Strategie di trading su singoli asset'
  },
  assetManagementSubtitleInside: {
    'en-EN':
      'The AI Portfolio <b>actively manages your cryptocurrencies by operating in the market</b> and <b>optimizing the asset composition in real-time</b> based on your personal risk profile.',
    'it-IT':
      'L’AI Portfolio <b>gestisce le tue criptovalute operando attivamente sul mercato</b> e <b>ottimizzando la composizione degli asset in tempo reale,</b> sulla base del tuo personale profilo di rischio.'
  },
  description: {
    'en-EN':
      "Build your cryptocurrency investment with the AI Portfolio. <b>Choose the assets you want to invest in and the risk level that suits you best</b>: Hodlie's artificial intelligence will do the rest, managing your portfolio completely automatically.<br><br>Based on your <b>knowledge of the crypto world</b> and your <b>risk tolerance</b>, we constantly optimize your investment. Your assets are constantly monitored and <b>rebalanced according to market trends</b>, following a strategy that reflects the risk level you have chosen.<br><br>Whether you want to entrust Hodlie with your entire portfolio or just a part of it, <b>the AI Portfolio offers solutions for every need</b>. The available packages are based on two criteria: <b>asset allocation</b>, to be selected based on your familiarity with the market, and <b>risk tolerance</b>, which reflects how you are willing to invest. By combining these two parameters, you can access <b>nine different Portfolio options, designed to perfectly fit your profile</b>.<br>You can also gradually and recurrently increase capital, adapting the investment to your journey.",
    'it-IT':
      "Costruisci il tuo investimento in criptovalute con l’AI Portfolio. <b>Scegli gli asset su cui vuoi investire e il livello di rischio più adatto a te</b>: l’intelligenza artificiale di Hodlie farà il resto, gestendo il tuo portafoglio in modo completamente automatizzato.<br><br>In base alla tua <b>conoscenza del mondo cripto</b> e alla tua <b>propensione al rischio</b>, ottimizziamo costantemente il tuo investimento. I tuoi asset vengono monitorati costantemente e <b>ribilanciati in base all’andamento del mercato</b>, seguendo una strategia che rispecchia il livello di rischio che hai scelto.<br><br>Che tu voglia affidare a Hodlie l’intero portafoglio o solo una parte, <b>l’AI Portfolio ti offre soluzioni per ogni necessità</b>. I pacchetti disponibili si basano su due criteri: l'<b>asset allocation</b>, da selezionare in base alla tua familiarità con il mercato, e la <b>propensione al rischio</b>, che riflette il modo in cui sei disposto a investire. Combinando questi due parametri, puoi accedere a <b>nove diverse opzioni di Portfolio, pensate per adattarsi perfettamente al tuo profilo</b>.<br>Potrai anche incrementare gradualmente e in maniera ricorrente il capitale, adattando l’investimento al tuo percorso."
  },
  descriptionLess: {
    'en-EN':
      'Build your cryptocurrency investment with the AI Portfolio. <b>Choose the assets you want to</b>...',
    'it-IT':
      'Costruisci il tuo investimento in criptovalute con l’AI Portfolio. <b>Scegli gli asset su cui</b>...'
  },
  yourRiskLevel: {
    'en-EN': 'Your risk level is:',
    'it-IT': 'Il tuo livello di rischio è:'
  },
  selectPortfolio: {
    'en-EN': 'Select an AI Portfolio',
    'it-IT': 'Scegli un AI Portfolio'
  },
  formTitle: {
    'en-EN': 'Your Risk Profile',
    'it-IT': 'Il tuo Profilo di Rischio'
  },
  formDescr: {
    'en-EN':
      'The first step is to get to know you better: this will help us identify the Risk Profile most similar to yours. You will be able to change your answers in the future.',
    'it-IT':
      'Il primo passo è conoscerti meglio: questo ci aiuterà ad individuare il Profilo di Rischio più simile al tuo. Potrai cambiare le tue risposte in futuro.'
  },
  formButton: {
    'en-EN': 'Discover your risk profile',
    'it-IT': 'Scopri il tuo profilo di rischio'
  },
  formTitle_started: {
    'en-EN': 'Resume the form from where you left off',
    'it-IT': 'Riprendi il questionario da dove hai lasciato'
  },
  formDescr_started: {
    'en-EN':
      'We have saved your responses, resume from where you left off or start over',
    'it-IT':
      'Abbiamo salvato le tue risposte, riprendi da dove hai lasciato o ricomincia da capo'
  },
  formButton_started: {
    'en-EN': 'resume',
    'it-IT': 'riprendi'
  },
  formButton_started_2: {
    'en-EN': 'start over',
    'it-IT': 'ricomincia da capo'
  },
  year: {
    'en-EN': 'Year',
    'it-IT': 'Anno'
  },
  virtualMode: {
    'en-EN': 'Virtual Mode',
    'it-IT': 'Modalità Virtuale'
  },
  whyTitle: {
    'en-EN': 'Why choose an AI Portfolio?',
    'it-IT': 'Perché scegliere un AI Portfolio?'
  },
  whoTitle: {
    'en-EN': 'Who should invest with the AI Portfolio?',
    'it-IT': 'Chi dovrebbe investire con l’AI Portfolio?'
  },
  whyText: {
    'en-EN':
      'An AI Portfolio is a customized investment experience: Hodlie allows investors to give all or part of their crypto portfolio under management in order to manage the assets within it. The variety of AI Portfolios available meets a wide variety of needs, goals, and risk appetite of each investor. In addition, you can gradually add capital to your Portfolio.',
    'it-IT':
      'L’AI Portfolio è un’esperienza customizzata di investimento: Hodlie permette agli investitori di dare in gestione la totalità o una parte del proprio portafoglio crypto al fine di gestirne gli asset al suo interno. La varietà di AI Portfolio disponibili risponde alle più disparate necessità, obiettivi e propensione al rischio di ciascun investitore. Inoltre, è possibile aggiungere gradualmente capitale al proprio Portfolio.'
  },
  whoText: {
    'en-EN':
      'The AI Portfolio is designed for those investors who want exposure to different crypto assets and who want to but do not have the time to be able to do so, and want to benefit from automated management based on their risk profile. Whatever your investor profile and the level of risk you want to expose yourself to, there is a Portfolio to suit your needs.',
    'it-IT':
      'L’AI Portfolio è pensato per quegli investitori che desiderano esporsi su diversi asset crypto e che vogliono ma non hanno il tempo per poterlo fare, e vogliono beneficiare di una gestione automatica basata sulla propria propensione al rischio. Qualunque sia il tuo profilo da investitore e il livello di rischio a cui vuoi esporti, esiste un Portfolio adatto alle tue esigenze.'
  },
  finalDisclaimerFee: {
    'en-EN':
      'Returns are calculated by applying a fee of 0.075% + a spread of 0.025% for each transaction.',
    'it-IT':
      'Rendimenti calcolati applicando una fee dello 0.075% + uno spread dello 0.025% per ogni transazione.'
  },
  finalDisclaimerFirst: {
    'en-EN': '*Data obtained from backtesting simulations on a capital of $',
    'it-IT': '*Dati ottenuti da simulazioni di backtesting su un capitale di $'
  },
  finalDisclaimerSecond: {
    'en-EN':
      '. It is important to remember that the results of backtesting simulations do not guarantee future performance and that every investment involves risk.',
    'it-IT':
      '. È importante ricordare che i risultati delle simulazioni di backtesting non garantiscono performance future e che ogni investimento comporta dei rischi.'
  },
  riskDisclaimer: {
    'en-EN': 'Find out here how we calculate the risk level of AI Portfolios.',
    'it-IT': 'Scopri qui come calcoliamo il livello di rischio dei Portfolio AI'
  },
  show: {
    'en-EN': 'Show ',
    'it-IT': 'Mostra di '
  },
  more: {
    'en-EN': 'more',
    'it-IT': 'più'
  },
  less: {
    'en-EN': 'less',
    'it-IT': 'meno'
  },
  discoverGuide: {
    'en-EN': 'Need help?',
    'it-IT': 'Hai bisogno di aiuto?'
  },
  gotoguide: {
    'en-EN': 'Go to guides',
    'it-IT': 'Vai alle Guide'
  }
};

export const CreatePortfolioModal = {
  title: {
    'en-EN':
      'Start an AI Portfolio using the capital currently allocated to your Agents',
    'it-IT':
      'Avvia un AI Portfolio utilizzando il capitale allocato attualmente sui tuoi Agents'
  },
  subTitle: {
    'en-EN':
      'It is communicated that, as of November 19, 2024, on December 16, the operations of the Agents will be interrupted. <br>Start your AI Portfolio now using the capital of your Agents to avoid service interruptions.',
    'it-IT': `Come da comunicazione del 20/11/2024, il 16 dicembre l'operatività degli Agents verrà interrotta. <br>Avvia ora il tuo AI Portfolio utilizzando il capitale dei tuoi Agents, per evitare interruzioni di servizio.`
  },
  riskLevelSelect: {
    'en-EN': 'Select risk level',
    'it-IT': 'Seleziona il livello di rischio'
  },
  portfolioDetails: {
    'en-EN': 'Go to the details of this AI Portfolio',
    'it-IT': 'Vai ai dettagli di questo AI Portfolio'
  }
};

export const Dashboard = {
  marketData: {
    'en-EN': 'Market Data',
    'it-IT': 'Dati di Mercato'
  },
  activities: {
    'en-EN': 'Activities',
    'it-IT': 'Attività'
  },
  createbotText: {
    'en-EN': 'No started startegies available',
    'it-IT': 'Non sono presenti strategie avviate'
  },
  createbotButton: {
    'en-EN': 'Create an AI Agent',
    'it-IT': 'Crea un Agent'
  },
  goToDashboard: {
    'en-EN': 'Go to Dashboard',
    'it-IT': 'Vai alla Dashboard'
  },
  add: {
    'en-EN': 'Add',
    'it-IT': 'Aggiungi'
  },
  last: {
    'en-EN': 'last ',
    'it-IT': 'ultime '
  }
};

export const DeleteBotModal = {
  title: {
    'en-EN': 'Are you sure?',
    'it-IT': 'Sei sicuro?'
  },
  text0: {
    'en-EN': 'If you delete this strategy, you ',
    'it-IT': 'Se cancelli questa strategia, tu '
  },
  text: {
    'en-EN': 'If you delete this strategy, you ',
    'it-IT': 'Se cancelli questa strategia, tu '
  },
  text1: {
    'en-EN': "won't",
    'it-IT': 'non'
  },
  text2: {
    'en-EN':
      ' be able to restart it, but you can only create a new one. The final amount will be restored in your wallet, updated based on its profits and losses.',
    'it-IT':
      ' potrai più riavviarla, ma potrai solo crearne una nuova. L’importo finale verrà ripristinato nel tuo portafoglio, aggiornato in base ai suoi ricavi e alle sue perdite.'
  },
  text3: {
    'en-EN': 'NB: the new starting amount to create an Agent is $500.',
    'it-IT': 'NB: il nuovo importo di partenza per creare un Agent è di $500.'
  },
  text4: {
    'en-EN':
      'Deleting it also allows you to decide whether to keep the current asset quantity or sell them all.',
    'it-IT':
      "Eliminandolo puoi inoltre decidere se mantenere l'attuale quantità di asset o vendere tutto."
  },
  text5: {
    'en-EN': 'Select below whether to hold or sell the asset',
    'it-IT': "Seleziona qui sotto se mantenere o vendere l'asset"
  },
  continue: {
    'en-EN': 'Do you want to continue?',
    'it-IT': 'Vuoi continuare?'
  },
  goBack: {
    'en-EN': 'No, go back',
    'it-IT': 'No, torna indietro'
  },
  yesDel: {
    'en-EN': 'Yes, delete',
    'it-IT': 'Si, cancella'
  },
  hold: {
    'en-EN': 'Hold',
    'it-IT': 'Mantieni'
  },
  sell: {
    'en-EN': 'Sell',
    'it-IT': 'Vendi'
  },
  textConvert: {
    'en-EN':
      'Deleting this Agent, you can decide whether to keep your FDUSD or convert everything to USDT. This process will allow you to immediately use the restored amount to start another bot!',
    'it-IT':
      'Eliminando questo Agent, puoi decidere se mantenere i tuoi FDUSD o convertire tutto in USDT. Questo procedimento, ti permetterà di utilizzare subito l’importo ripristinato per avviarne un altro!'
  },
  textConvert1: {
    'en-EN': 'Select below whether to hold FDUSD or convert to USDT',
    'it-IT': 'Seleziona qui sotto se mantenere FDUSD o convertirli in USDT'
  },
  convertCoin: {
    'en-EN': 'convert to USDT',
    'it-IT': 'Converti in USDT'
  },
  holdCoin: {
    'en-EN': 'hold FDUSD',
    'it-IT': 'Mantieni FDUSD'
  }
};

export const DoKYC = {
  title: {
    'en-EN': 'Verify your identity (KYC)',
    'it-IT': 'Verifica la tua identità (KYC)'
  },
  name: {
    'en-EN': 'Name',
    'it-IT': 'Nome'
  },
  surname: {
    'en-EN': 'Surname',
    'it-IT': 'Cognome'
  },
  tax_code: {
    'en-EN': 'Tax Code',
    'it-IT': 'Codice Fiscale'
  },
  mobile: {
    'en-EN': 'Mobile Number',
    'it-IT': 'Numero di Cellulare'
  },
  address: {
    'en-EN': 'Residential Address',
    'it-IT': 'Indirizzo di Residenza'
  },
  address_city: {
    'en-EN': 'City',
    'it-IT': 'Città'
  },
  address_country: {
    'en-EN': 'Country',
    'it-IT': 'Stato'
  },
  tos: {
    'en-EN':
      'I have read and accepted the <a href="/termsandconditions" target="_blank" rel="noreferrer">Terms & Conditions</a>',
    'it-IT':
      'Ho letto e accettato i <a href="/termsandconditions" target="_blank" rel="noreferrer">Termini e Condizioni</a>'
  },
  verify: {
    'en-EN': 'Verify',
    'it-IT': 'Verifica'
  },
  placeFeedback: {
    'en-EN':
      'To proceed, make sure you have selected an address from the drop-down menu',
    'it-IT':
      'Per procedere, assicurati di aver selezionato un indirizzo dal menù a tendina'
  },
  processing: {
    'en-EN': 'We are checking your identity',
    'it-IT': 'Stiamo verificando i tuoi dati'
  },
  retry: {
    'en-EN': 'Try Again',
    'it-IT': 'Riprova'
  },
  retryText: {
    'en-EN':
      'There was an error during the identity verification process, please try again!',
    'it-IT':
      "C'è stato un errore durante il processo di verifica dell'identità, riprova!"
  },
  retryText1: {
    'en-EN': 'If the problem persists, contact us at',
    'it-IT': 'Se il problema persiste contattaci a '
  },
  endProcessing: {
    'en-EN': 'Your KYC is under review,',
    'it-IT': "La tua verifica dell'identità (KYC) è in fase di revisione,"
  },
  endProcessing1: {
    'en-EN': 'you will receive an email upon completion!',
    'it-IT': 'al completamento riceverai una mail!'
  },
  close: {
    'en-EN': 'Close',
    'it-IT': 'Chiudi'
  },
  has_binance: {
    'en-EN': 'Do you have a Binance Account?',
    'it-IT': 'Hai già un account Binance?'
  },
  no: {
    'en-EN': 'No',
    'it-IT': 'No'
  },
  yes: {
    'en-EN': 'Yes',
    'it-IT': 'Si'
  },
  error_name: {
    'en-EN': 'Insert a valid name',
    'it-IT': 'Inserire un nome valido'
  },
  error_surname: {
    'en-EN': 'Insert a valid surname',
    'it-IT': 'Inserire un cognome valido'
  },
  error_tax_code: {
    'en-EN': 'Insert a valid tax code',
    'it-IT': 'Inserire un codice fiscale valido'
  },
  error_mobile: {
    'en-EN': 'Insert a valid mobile number',
    'it-IT': 'Inserire un numero di cellulare valido'
  },
  checkTitle: {
    'en-EN': 'Identity verification was successful',
    'it-IT': "La verifica dell'identità è stata effettuata correttamente"
  },
  checkTitle2: {
    'en-EN': 'You can now proceed to connect your Binance wallet.',
    'it-IT': 'Ora puoi procedere al collegamento del wallet di Binance. '
  },
  checkTitle3: {
    'en-EN': "Don't have one yet?",
    'it-IT': 'Non ne hai ancora uno?'
  },
  checkTitle4: {
    'en-EN': '',
    'it-IT': ''
  },
  goToGuideText: {
    'en-EN': 'Create it now!',
    'it-IT': 'Crealo ora!'
  },
  goToGuideText1: {
    'en-EN': 'Create your Binance account and make your first deposit.',
    'it-IT': 'Crea il tuo account Binance ed effettua il tuo primo deposito.'
  },
  goToGuideButton: {
    'en-EN': 'Go to Binance',
    'it-IT': 'Vai su Binance'
  },
  waitingText: {
    'en-EN': 'Try our virtual mode!',
    'it-IT': 'Prova la nostra modalità virtuale!'
  },
  waitingText1: {
    'en-EN':
      'You can try our virtual mode for free for 7 days. No card required.',
    'it-IT':
      'Puoi provare gratuitamente la nostra modalità virtuale per 7 giorni. Nessuna carta richiesta.'
  },
  waitingButton: {
    'en-EN': 'Try virtual mode',
    'it-IT': 'Prova la modalità virtuale'
  }
};

export const Done = {
  yourPersonal: {
    'en-EN': 'Your personal AI Agents ',
    'it-IT': 'Il tuo AI Agents '
  },
  your: {
    'en-EN': 'Your personal ',
    'it-IT': 'Il tuo '
  },
  check: {
    'en-EN': 'Check its status',
    'it-IT': 'Controlla il suo stato'
  },
  videoDescr: {
    'en-EN': 'How it works',
    'it-IT': 'Scopri come funziona'
  },
  running: {
    'en-EN': ' is successfully running!',
    'it-IT': ' è attualmente attivo!'
  },
  near: {
    'en-EN': ' will be available soon!',
    'it-IT': ' sarà presto disponibile!'
  },
  soonText: {
    'en-EN': '',
    'it-IT':
      'Tra pochi minuti potrai visualizzare tutte le informazioni ad esso relative'
  },
  newBot: {
    'en-EN': 'Create new AI Agent',
    'it-IT': 'Crea un nuovo AI Agent'
  },
  dashboardLink: {
    'en-EN': 'Go to Dashboard',
    'it-IT': 'Vai alla Dashboard'
  },
  walletLink: {
    'en-EN': 'Go to Dashboard',
    'it-IT': 'Vai alla Dashboard'
  },
  walletVirtualLink: {
    'en-EN': 'Go to Virtual Wallet',
    'it-IT': 'Vai al Wallet Virtuale'
  },
  runBot: {
    'en-EN': 'Run',
    'it-IT': 'Avvia'
  },
  del: {
    'en-EN': 'Delete',
    'it-IT': 'Annulla'
  },
  head: {
    'en-EN': 'Your Hodlie subscription is active!',
    'it-IT': 'Il tuo abbonamento su Hodlie è attivo!'
  },
  body: {
    'en-EN':
      'You can now proceed with the launch of your strategy: it seems that you have left one pending, here are the details:',
    'it-IT':
      'Puoi ora procedere con il lancio della tua strategia: sembra che tu ne abbia lasciato in sospeso una, ecco i dettagli:'
  },
  body_what: {
    'en-EN': 'What do you want to do?',
    'it-IT': 'Cosa vuoi fare?'
  },
  button_0: {
    'en-EN': 'Recreate it from scratch',
    'it-IT': 'Ricreara da zero'
  },
  strategy: {
    'en-EN': 'Strategy',
    'it-IT': 'Strategia'
  },
  coins: {
    'en-EN': 'Coins',
    'it-IT': 'Coin'
  },
  value: {
    'en-EN': 'Value',
    'it-IT': 'Controvalore'
  }
};

export const EnableNotifications = {
  NotificationNotGranted: {
    'en-EN': 'Grant notifications permissions to receive Hodlie updates',
    'it-IT': 'Abilita le notifiche per ricevere aggiornamenti da Hodlie'
  },
  confirm: {
    'en-EN': 'Confirm',
    'it-IT': 'Conferma'
  },
  del: {
    'en-EN': 'Cancel',
    'it-IT': 'Annulla'
  },
  textModal: {
    proEnable: {
      title: {
        'en-EN': 'Do you want to enable PRO notifications?',
        'it-IT': 'Vuoi attivare le notifiche PRO?'
      },
      text: {
        'en-EN':
          "Do you want to stay informed about your bots' operations? Activate notifications now, we promise only good news, no spam!",
        'it-IT':
          'Vuoi rimanere sempre informato sull’operatività dei tuoi bot? Attiva ora le notifiche, ti promettiamo solo buone notizie, niente spam!'
      }
    },
    proDisable: {
      title: {
        'en-EN': 'Do you want to disable PRO notifications?',
        'it-IT': 'Vuoi disabilitare le notifiche PRO?'
      },
      text: {
        'en-EN':
          'Are you sure you want to disable notifications? You will miss out on all the news about the performance of your bots!',
        'it-IT':
          "Sei sicuro di voler disabilitare le notifiche? Ti perderai tutte le notizie sull'andamento dei tuoi bot!"
      }
    },
    botEnable: {
      title: {
        'en-EN': 'Do you want to ENABLE notifications for this bot?',
        'it-IT': 'Vuoi ATTIVARE le notifiche per questo bot?'
      },
      text: {
        'en-EN':
          'By enabling notifications, you will receive updates on transactions made by this Bot!',
        'it-IT':
          'Attivando le notifiche riceverai aggiornamenti sulle transazioni effettuate da questo Bot!'
      }
    },
    botDisable: {
      title: {
        'en-EN': 'Do you want to DISABLE notifications for this bot?',
        'it-IT': 'Vuoi DISABILITARE le notifiche per questo bot?'
      },
      text: {
        'en-EN':
          'By disabling notifications, you will no longer receive updates on transactions made by this Bot!',
        'it-IT':
          'Disabilitando le notifiche NON riceverai più aggiornamenti sulle transazioni effettuate da questo Bot!'
      }
    }
  }
};

export const endpointInterface = {
  response: {
    'en-EN': 'Webserver Error, try to reload Page',
    'it-IT': 'Errore del server web, prova a ricaricare la pagina'
  }
};

export const FirstAccess = {
  title: {
    'en-EN': 'Hi <font color=' + goldColor + '>Hodlier</font>!',
    'it-IT': 'Ciao <font color=' + goldColor + '>Hodlier</font>!'
  },
  text: {
    'en-EN':
      `<p className="m-0">
    Welcome to a world fully managed by AI: Hodlie is the first platform dedicated to cryptocurrency trading that utilizes Artificial Intelligence to dynamically manage your portfolio.
  </p>
  <p className="ms-3">
    Getting started is simple: <br />
    <ul>
      <li>
        Connect your Binance wallet via API key on the
        <b>
          <font color='` +
      goldColor +
      `'> Wallet</font>
        </b>
        page
      </li>
      <li>
        Start a Pro subscription on the
        <b>
          <font color='` +
      goldColor +
      `'> Go Pro </font>
        </b>
        page or by clicking the green banner at the top (
        <b>
          <font color='` +
      goldColor +
      `'>
            the first 7 days are free, try it without commitment
          </font>
        </b>
        )
      </li>
      <li>
        Go to the
        <b>
          <font color='` +
      goldColor +
      `'> Bot Creation </font>
        </b>
        page and choose the strategy that best suits your needs (define the capital to invest, select an Asset and a Stablecoin)
      </li>
      <li>
        <b>
          <font color='` +
      goldColor +
      `'>
            Start a strategy and let Hodlie do the rest!
          </font>
        </b>
      </li>
    </ul>
  </p>
  <p>
    Not quite ready to test our strategies with your crypto portfolio? You can try our strategies by starting them in virtual mode, using the $10,000 wallet we provide.
    <br />
    <br />
    More than 50% of our users take less than 5 minutes to start their first strategy. How long will it take you?
  </p>`,
    'it-IT':
      `
    <p className="m-0">
      Ti diamo il benvenuto in un mondo totalmente gestito dall’AI: Hodlie è
      la prima piattaforma dedicata al trading di criptovalute che utilizza
      l’Intelligenza Artificiale per gestire dinamicamente il tuo
      portafoglio.
    </p>
    <p className="ms-3">
      Iniziare è semplice: <br />
      <ul>
        <li>
          Collega il tuo portafoglio Binance tramite API-key alla pagina
          <b>
            <font color='` +
      goldColor +
      `'> Wallet</font>
          </b>
        </li>
        <li>
          Avvia un abbonamento Pro alla pagina
          <b>
            <font color='` +
      goldColor +
      `'> Passa a Pro </font>
          </b>
          o cliccando sul banner verde in alto (
          <b>
            <font color='` +
      goldColor +
      `'>
              i primi 7 giorni sono gratuiti, provalo senza impegno
            </font>
          </b>
          )
        </li>
        <li>
          Vai alla pagina
          <b>
            <font color='` +
      goldColor +
      `'> Investi </font>
          </b>
          e scegli la strategia che più si avvicina alle tue esigenze
          (definisci il capitale da investire, seleziona un Asset e una
          Stablecoin)
        </li>
        <li>
          <b>
            <font color='` +
      goldColor +
      `'>
              Avvia una strategia e lascia che Hodlie faccia il resto!
            </font>
          </b>
        </li>
      </ul>
    </p>
    <p>
      Non sei ancora pronto a testare le nostre strategie con il tuo portafoglio crypto?
      Puoi testare le nostre strategie avviandole in modalità
      virtuale, utilizzando il portafoglio di 10k $ che ti diamo a
      disposizione
      <br />
      <br />
      Più del 50% dei nostri utenti ci mette meno di 5 minuti ad avviare la sua prima strategia, tu quanto ci metterai?
    </p>`
  },
  endText: {
    'en-EN': 'Enjoy your trading with Hodlie',
    'it-IT': 'Goditi il trading con Hodlie'
  },
  closing: {
    'en-EN': 'Close',
    'it-IT': 'Chiudi'
  }
};

export const Form = {
  onboard: {
    'en-EN': 'Answer this short questionnaire to launch your first AI strategy',
    'it-IT':
      'Rispondi a questo breve questionario per lanciare la tua prima strategia di AI'
  },
  0: {
    'en-EN': 'A short form to get to know each other better!',
    'it-IT': 'Un breve questionario per conoscerci meglio!'
  },
  error: {
    'en-EN': 'The requested form is not available',
    'it-IT': 'Il questionario richiesto non è disponbile'
  },
  goHome: {
    'en-EN': 'Go to Home',
    'it-IT': 'Vai alla Home'
  },
  confirm: {
    'en-EN': 'Confirm',
    'it-IT': 'Conferma'
  },
  skip: {
    'en-EN': 'Skip Form',
    'it-IT': 'Salta il questionario'
  },
  botTitle: {
    'en-EN': 'This is the recommended virtual AI Strategy for you',
    'it-IT': 'Questa è la strategia di AI virtuale consigliata per te'
  },
  botTitle1: {
    'en-EN': 'Launch it to see it immediately on your Dashboard',
    'it-IT': 'Lancialo per vederlo subito nella tua Dashboard'
  },
  botCrypto: {
    'en-EN': 'Recommended currency',
    'it-IT': 'Valuta consigliata'
  },
  botAmount: {
    'en-EN': 'Virtual amount',
    'it-IT': 'Investimento virtuale'
  },
  start: {
    'en-EN': 'Start',
    'it-IT': 'Avvia'
  },
  continueTitle: {
    'en-EN': 'Do you want to continue?',
    'it-IT': 'Vuoi continuare?'
  },
  notEnough: {
    'en-EN': "You don't have enough virtual dollars to launch it",
    'it-IT': 'Non hai abbastanza dollari virtuali per lanciarlo'
  },
  repeat: {
    'en-EN': 'Repeat Form',
    'it-IT': 'Ripeti il Questionario'
  },
  doForm: {
    'en-EN': 'Complete Form',
    'it-IT': 'Compila il Questionario'
  },
  advised: {
    'en-EN': 'Your recommended Agent',
    'it-IT': 'Agent consigliato per te'
  },
  completeText: {
    'en-EN':
      'Complete the form to discover the ideal Bot that suits your preferences!',
    'it-IT':
      'Effettua il questionario per farti suggerire il Bot perfetto per te!'
  },
  usernameTitle: {
    'en-EN': 'To get started choose your username',
    'it-IT': 'Per cominciare scegli il tuo username'
  },
  usernameLonger: {
    'en-EN':
      'Username must be longer than five characters and can only contain letters and numbers',
    'it-IT':
      "L'username deve essere più lungo di cinque caratteri e può contenere solo lettere e numeri"
  },
  continueTitleNoBot: {
    'en-EN': 'Do you want to help us grow?',
    'it-IT': 'Vuoi aiutarci a crescere?'
  },
  back: {
    'en-EN': 'Back',
    'it-IT': 'Indietro'
  },
  backToPort: {
    'en-EN': 'Choose a Portfolio',
    'it-IT': 'Scegli Portfolio'
  }
};

export const Footer = {
  reservedRight: {
    'en-EN': 'All rights reserved',
    'it-IT': 'Tutti i diritti riservati'
  },
  tos: {
    'en-EN': 'Terms & Conditions',
    'it-IT': 'Termini e Condizioni'
  },
  tou: {
    'en-EN': 'Terms of Use',
    'it-IT': 'Condizioni di utilizzo'
  }
};

export const FAQ = {
  subtitle: {
    'en-EN':
      "Below you'll find answers to the questions we get asked the most about to join with Hodlie",
    'it-IT':
      'Qui sotto puoi trovare le risposte alle domande più frequenti su Hodlie'
  },
  sections: {
    Hodlie: [
      {
        question: {
          'en-EN':
            'What is <b><font color=' + blueColor + '>Hodlie</font></b>?',
          'it-IT': 'Cos’è <b><font color=' + blueColor + '>Hodlie</font></b>?'
        },
        answer: {
          'en-EN':
            '<b><font color=' +
            blueColor +
            '>Hodlie</font></b> is an <b><font color=' +
            blueColor +
            '>automated cryptocurrency trading platform</font></b>. By connecting your Binance portfolio through API key integration you can let AI-based strategies handle your investments. </br><b><font color=' +
            blueColor +
            '>This technology is user-friendly</font></b>: with just a few simple steps, you can launch your AI strategies to invest in cryptocurrencies on your behalf, conducting trades 24/7.',
          'it-IT':
            '<b><font color=' +
            blueColor +
            '>Hodlie</font></b> è una <b><font color=' +
            blueColor +
            '>piattaforma di trading automatico di criptovalute</font></b>. Puoi collegare il tuo portafoglio di Binance tramite chiave API e lasciare che le strategie basate su Intelligenza Artificiale gestiscano i tuoi investimenti. </br> Si tratta di una <b><font color=' +
            blueColor +
            '>tecnologia adatta a tutti</font></b>: in pochi semplici passaggi, puoi lanciare la tua strategia di AI che investe in criptovalute al posto tuo, eseguendo operazioni 24 ore al giorno, 7 giorni a settimana.'
        },
        active: true
      },
      {
        question: {
          'en-EN':
            'What is a <b><font color=' + blueColor + '>AI Agent</font></b>?',
          'it-IT':
            'Cos’è un <b><font color=' + blueColor + '>AI Agent</font></b>?'
        },
        answer: {
          'en-EN':
            'With <i>AI Agent</i>, we mean a software program that automates trading activity by <b><font color=' +
            blueColor +
            '>actively managing the wallet</font></b> based on specific pre-set parameters and in a completely autonomous manner.',
          'it-IT':
            'Con <i>AI Agent</i> si intende un programma software che automatizza l’attività di trading esercitando una  <b><font color=' +
            blueColor +
            '>gestione attiva del portafoglio</font></b> in base a parametri specifici preimpostati e in maniera completamente autonoma.'
        },
        active: true
      },
      {
        question: {
          'en-EN':
            'Why is a <b><font color=' +
            blueColor +
            '>AI Agent</font></b> preferable to traditional trading?',
          'it-IT':
            'Perché il <b><font color=' +
            blueColor +
            '>AI Agent</font></b> è preferibile al classico trading?'
        },
        answer: {
          'en-EN':
            '<b><font color=' +
            blueColor +
            '>Speed and Efficiency:</font></b> AI Agents can execute trades much faster than humans as they are not limited by physical or emotional constraints. They quickly analyze market data and perform operations in milliseconds, maximizing profit opportunities and reducing the risk of losses. </br> <b><font color=' +
            blueColor +
            '>Consistency:</font></b> AI Agents follow a set of rules and algorithms that eliminate the possibility of human errors caused by emotions that often interfere with trading decisions. This consistency leads to faster and more accurate operations. </br><b><font color=' +
            blueColor +
            '>24/7 Availability:</font></b> AI Agents operate 24 hours a day, 7 days a week, without the need for rest or breaks. This allows constant monitoring of markets and opportunities. </br><b><font color=' +
            blueColor +
            '>Computational Power:</font></b> AI Agents have the ability to analyze billions of data points and market information, capabilities that far exceed those of a human trader. This leads to more informed and strategic trading decisions.',
          'it-IT':
            '<b><font color=' +
            blueColor +
            '>Velocità ed Efficienza:</font></b> I bot di trading possono eseguire scambi molto più velocemente degli esseri umani poiché non sono limitati da vincoli fisici o emozionali. Analizzano rapidamente i dati di mercato ed effettuano operazioni in pochi millisecondi, massimizzando le opportunità di profitto e riducendo il rischio di perdite. </br><b><font color=' +
            blueColor +
            '>Coerenza:</font></b> I bot di trading seguono un insieme di regole e algoritmi che eliminano la possibilità di errori umani causati dalle emozioni che spesso interferiscono nelle decisioni di trading. Questa coerenza porta ad operazioni più rapide e accurate. </br><b><font color=' +
            blueColor +
            '>Disponibilità 24/7:</font></b> I bot di trading funzionano 24 ore al giorno, 7 giorni alla settimana, senza bisogno di riposo o di pause. Questo permette il monitoraggio costante dei mercati e delle opportunità da cogliere. </br><b><font color=' +
            blueColor +
            '>Potenza computazionale:</font></b> I bot di trading hanno la capacità di analizzare miliardi di dati e informazioni di mercato, capacità che vanno molto oltre quelle di un trader umano. Ciò porta a decisioni di trading più informate e strategiche.'
        },
        active: true
      },
      {
        question: {
          'en-EN':
            'What is <b><font color=' +
            blueColor +
            '>artificial intelligence</font></b>?',
          'it-IT': 'Intelligenza artificiale? Di cosa si tratta?'
        },
        answer: {
          'en-EN':
            'We have developed a <b><font color=' +
            blueColor +
            '>complex architecture of artificial neural networks</font></b> capable of processing and analyzing billions of financial data. In particular, our models have been trained on various types of combined data: historical data of major cryptocurrencies (such as BTC and ETH), stock market, and major global stock indices, market sentiment analysis, user behavioral models, natural language processing on major financial news of recent years, but also controversial speculations that have led to significant price fluctuations, and more. After being trained, tested, and validated, <b><font color=' +
            blueColor +
            '>our AI can listen in real-time to all this information and decide accordingly whether to operate on the market</font></b>, all in a few milliseconds.',
          'it-IT':
            'Abbiamo sviluppato una <b><font color=' +
            blueColor +
            '>complessa architettura di reti neurali</font></b> artificiali in grado di elaborare e analizzare miliardi di dati finanziari. In particolare, i nostri modelli sono stati addestrati su vari tipi di dati combinati tra loro: dati storici di importanti criptovalute (come BTC ed ETH), mercato azionario e importanti indici di borsa globali, analisi del sentiment di mercato, modelli comportamentali degli utenti, elaborazione del linguaggio naturale su importanti notizie finanziarie degli ultimi anni, ma anche speculazioni controverse che hanno portato a grandi fluttuazioni di prezzo, e altro ancora. Dopo essere state addestrate, testate e convalidate, <b><font color=' +
            blueColor +
            '>le nostre AI sono in grado di ascoltare in tempo reale tutte queste informazioni e di decidere di conseguenza se operare sul mercato</font></b>, tutto in pochi millisecondi.'
        },
        active: true
      },
      {
        question: {
          'en-EN':
            'How can I trust <b><font color=' + blueColor + '>AI</font></b>?',
          'it-IT':
            'Come posso fidarmi dell’<b><font color=' +
            blueColor +
            '>AI</font></b>?'
        },
        answer: {
          'en-EN':
            'Within the Hodlie platform, there are two fundamental tools that can help evaluate the performance of an AI Agents and gain confidence in its operation: <i>backtesting</i> and <i>Virtual</i>. <br></br><b><font color=' +
            blueColor +
            '>Backtesting</font></b> is the process that allows testing a trading strategy using historical market data and evaluating the performance of a bot over a significant period of time, and seeing <b><font color=' +
            blueColor +
            '>how it has performed in the past under different market conditions.</font></b><br><br> <b><font color=' +
            blueColor +
            '>Virtual</font></b> is a simulated trading environment where bots execute buy and sell operations using a virtual portfolio, allowing users to test bot performance in real-time under different market conditions without risking any capital.<br><br> It is important to remember that no trading strategy can guarantee success at all times and that all investments involve risk.',
          'it-IT':
            'All’interno della piattaforma di Hodlie sono disponibili due strumenti fondamentali che possono aiutare a valutare le performance di un bot di trading basato sull’AI e ad acquisire fiducia sul suo funzionamento: il <i>backtesting</i> e il <i>Virtual</i>. <br></br>Il <b><font color=' +
            blueColor +
            '>backtesting</font></b> è il processo che permette di testare una strategia di trading utilizzando dati storici di mercato e che consente di valutare le prestazioni di un bot per un periodo significativo di tempo, e di vedere <b><font color=' +
            blueColor +
            '>come ha performato nel passato in diverse condizioni di mercato.</font></b><br><br> Il <b><font color=' +
            blueColor +
            '>Virtual</font></b> è un ambiente di trading simulato in cui i bot eseguono operazioni di acquisto e di vendita utilizzando un portafoglio virtuale, consentendo agli utenti di testare le prestazioni dei bot nelle diverse condizioni di mercato in tempo reale, senza rischiare alcun capitale.<br><br> È importante ricordare che nessuna strategia di trading può garantire sempre il successo e che tutti gli investimenti comportano un rischio.'
        },
        active: true
      },
      {
        question: {
          'en-EN':
            'Is <b><font color=' +
            blueColor +
            '>Hodlie</font></b> a regulated platform?',
          'it-IT': 'Hodlie è una piattaforma regolamentata?'
        },
        answer: {
          'en-EN':
            '<b><font color=' +
            blueColor +
            '>Hodlie (Hodlie s.r.l.) complies with current regulations in Italy.</font></b> The platform is registered in the special section of the Exchange Register, reserved for providers of services related to the use of virtual currency and providers of digital wallet services (art. 17-bis, paragraphs 8-bis and ter D.Lgs. 141/2010). This register can be consulted on the OAM (Agents and Mediators Organization) portal.',
          'it-IT':
            '<b><font color=' +
            blueColor +
            '>Hodlie (Hodlie s.r.l.) è conforme alla normativa vigente in Italia.</font></b> La piattaforma è iscritta nella sezione speciale del Registro delle Cambiavalute, riservata ai prestatori di servizi relativi all’utilizzo di valuta virtuale e ai prestatori di servizi di portafoglio digitale (art. 17-bis, commi 8-bis e ter D.Lgs. 141/2010). Tale registro è consultabile sul portale dell’OAM (Organismo Agenti e Mediatori)'
        },
        active: true
      }
    ],
    Account: [
      {
        question: {
          'en-EN':
            'What is <b><font color=' +
            blueColor +
            '>KYC</font></b> and why is it required?',
          'it-IT':
            'Cos’è il <b><font color=' +
            blueColor +
            '>KYC</font></b> e perché è richiesto?'
        },
        answer: {
          'en-EN':
            'KYC (<b><font color=' +
            blueColor +
            '>K</font></b>now <b><font color=' +
            blueColor +
            '>Y</font></b>our <b><font color=' +
            blueColor +
            '>C</font></b>ustomer) consists of specific procedures that Hodlie, by legal obligation, must follow to protect its users. It is a <b><font color=' +
            blueColor +
            '>recognition process</font></b> to verify their identity and assess potential risks or illegal intentions. This regulation has been imposed by the European Union and falls under the AMLD (Anti Money Laundering Directives).',
          'it-IT':
            'Il KYC (<b><font color=' +
            blueColor +
            '>K</font></b>now <b><font color=' +
            blueColor +
            '>Y</font></b>our <b><font color=' +
            blueColor +
            '>C</font></b>ustomer) consiste in alcune procedure specifiche che Hodlie, per obbligo di legge, deve seguire per proteggere i propri utenti. Si tratta di un <b><font color=' +
            blueColor +
            ">processo di riconoscimento</font></b> per verificarne l'identità e valutare potenziali rischi o intenzioni illegali. Questa normativa è stata imposta dall’Unione Europea e rientra nelle AMLD (Anti Money Laundry Directives)."
        },
        active: true
      },
      {
        question: {
          'en-EN':
            'Why did my <b><font color=' + blueColor + '>KYC</font></b> fail?',
          'it-IT':
            'Perché il mio <b><font color=' +
            blueColor +
            '>KYC</font></b> non è andato a buon fine?'
        },
        answer: {
          'en-EN':
            'The identity verification procedure may fail if the user uses a document that is not in good condition or lacks recognition photos. To perform KYC, <b><font color=' +
            blueColor +
            '>you can use the ID card, Passport, or Driving License</font></b>. To ensure a successful process, it is necessary to ensure that the document is valid, all data is readable, and it is correctly photographed (in focus, straight, in full, from both sides, and for Passport and paper ID, both closed and open). If the verification fails, a revision request is automatically sent, which must be manually approved by our team.',
          'it-IT':
            'La procedura di verifica dell’identità potrebbe non andare a buon fine qualora l’utente utilizzi un documento che non sia in buone condizioni o che sia privo di foto di riconoscimento. Per effettuare il KYC <b><font color=' +
            blueColor +
            '>è possibile utilizzare la Carta d’identità, il Passaporto oppure la Patente di guida</font></b>. Affinché vada a buon fine, è necessario assicurarsi che il documento sia in corso di validità e che tutti i dati siano leggibili, inoltre è importante fotografarlo correttamente (a fuoco, dritto, per intero, da entrambi i lati e, per il Passaporto e la Carta d’identità cartacea, sia chiuso che aperto). Nel caso in cui la verifica non sia andata a buon fine, viene inviata automaticamente una richiesta di revisione della stessa, che dovrà essere approvata manualmente dal nostro team.'
        },
        active: true
      },
      {
        question: {
          'en-EN': 'How can I delete my account?',
          'it-IT': 'Come posso eliminare il mio account?'
        },
        answer: {
          'en-EN':
            'To delete your account, simply write to <a href="mailto:support@hodlie.net"><b><u>support@hodlie.net</u></b></a> with the email used during registration and specify your username. The support team will proceed with the deletion as quickly as possible.',
          'it-IT':
            'Per eliminare il proprio account basta scrivere a <a href="mailto:support@hodlie.net"><b><u>support@hodlie.net</u></b></a> con l’email utilizzata al momento della registrazione e specificando l’username. Il team di supporto si occuperà di procedere con la cancellazione nel minor tempo possibile.'
        },
        active: true
      }
    ],
    Bot: [
      {
        question: {
          'en-EN': 'How do I start a bot?',
          'it-IT': 'Come avvio un bot?'
        },
        answer: {
          'en-EN': `
              <ol>
                <li>
                  If you already have a Binance wallet, proceed to the next
                  step, otherwise 
                  <b>
                    <font color='${blueColor}'>sign up and deposit</font>
                  </b>
                  .
                </li>
                <li>
                  <b>
                    <font color='${blueColor}'>
                      Connect your Binance wallet, via API-Key
                    </font>
                  </b>
                  , to the Wallet page. Make sure you have sufficient funds, the
                  available value in your wallet must be equal to or greater
                  than
                  <b>
                    <font color='${blueColor}'>$200</font>
                  </b>
                  . This amount can only be composed of the asset (es: BTC), the
                  stable coin (es: USDT), or
                  the sum of the two.
                </li>
                <li>
                  Make sure your funds are available in a section called "Spot"
                  on Binance.
                </li>
                <li>
                  <b>
                    <font color='${blueColor}'>Subscribe to a plan</font>
                  </b>
                  : check the <a href="/pricing">Pricing</a> for more
                  details.
                </li>
                <li>
                  Now all you have to do is launch your first bot: go to the
                  <a href="/invest">Bot Creation</a> section, choose the
                  strategy that best suits your needs, allocate the capital, and
                  start!
                </li>
                <li>
                  <b>
                    <font color='${blueColor}'>Now you can relax</font>
                  </b>
                  : monitor your bot and wait to see the first results.
                </li>
              </ol> 
              <br />
              <small>
                Attention: It is not possible to create more than 5 bots on the
                same trading pair.
              </small>
            `,
          'it-IT': `
              <ol>
                <li>
                  Se già possiedi un portafoglio Binance passa al prossimo step,
                  in caso contrario 
                  <b>
                    <font color='${blueColor}'>iscriviti e deposita</font>
                  </b>
                  .
                </li>
                <li>
                  <b>
                    <font color='${blueColor}'>
                      Collega il tuo portafoglio Binance, tramite API-Key
                    </font>
                  </b>
                  , alla pagina Wallet. Controlla di avere sufficienti fondi, il
                  controvalore disponibile nel tuo portafoglio deve essere pari
                  o superiore a
                  <b>
                    <font color='${blueColor}'>$200</font>
                  </b>
                  . Tale importo può essere costituito solo dall’asset
                  (es: BTC), dalla sola stable coin (es: USDT) o dalla somma dei due.
                </li>
                <li>
                  Assicurati che i tuoi fondi siano disponibili in una sezione
                  denominata “Spot” su Binance.
                </li>
                <li>
                  <b>
                    <font color='${blueColor}'>Sottoscrivi un abbonamento</font>
                  </b>
                  : consulta il <a href="/pricing">Princing</a> per maggiori
                  dettagli.
                </li>
                <li>
                  Ora non ti resta che lanciare il tuo primo bot: vai alla
                  sezione <a href="/invest">Creazione bot</a>, scegli la
                  strategia che più si adatta alle tue esigenze, il capitale da
                  allocare e avvia!
                </li>
                <li>
                  <b>
                    <font color='${blueColor}'>Adesso puoi rilassarti</font>
                  </b>
                  : monitora il tuo bot e attendi di vedere i primi risultati.
                </li>
              </ol> 
              <br />
              <small>
                Attenzione: Non è possibile creare più di 5 bot sulla stessa
                coppia di trading.
              </small>
            `
        },
        active: true
      },
      {
        question: {
          'en-EN': 'How do Hodlie bots operate?',
          'it-IT': 'Come operano i bot di Hodlie?'
        },
        answer: {
          'en-EN': `
              The bots operate only through
              <b>
                <font color='${blueColor}'>spot trading</font>
              </b>
              , opening and closing only
              <b>
                <font color='${blueColor}'>long positions</font>
              </b>
              , and apply compound interest. To better understand the operation of each bot, it is advisable
              to read the description and consult the respective technical
              sheet. Both can be accessed by clicking on the individual bots
              within the section dedicated to 
              <a href="/invest">Bot Creation</a> (Create on mobile).
              <br />
              <b>
                <font color='${blueColor}'>
                   
                  In order to avoid interfering with the operation of the bots
                  on Hodlie, it is very important that the funds dedicated to
                  the bots are not automatically or manually moved from the spot
                  wallet on Binance.
                </font>
              </b>
            `,
          'it-IT': `
              I bot operano solo tramite
              <b>
                <font color='${blueColor}'>trading spot</font>
              </b>
              , aprendo e chiudendo unicamente
              <b>
                <font color='${blueColor}'>posizioni long</font>
              </b>
              , ed applicano l'interesse composto. Per poter comprendere meglio l’operatività di ciascuno, è
              consigliabile leggere la descrizione e consultare la relativa
              scheda tecnica. Entrambe sono raggiungibili cliccando sui singoli
              bot all’interno della sezione dedicata alla 
              <a href="/invest">Creazione bot</a> (Crea su mobile). 
              <br />
              <b>
                <font color='${blueColor}'>
                  Al fine di non creare interferenze con l’operatività dei bot
                  su Hodlie, è molto importante che i fondi dedicati ai bot non
                  vengano spostati automaticamente o manualmente dal portafoglio
                  spot su Binance.
                </font>
              </b>
            `
        },
        active: true
      },
      {
        question: {
          'en-EN': 'Are there transaction fees?',
          'it-IT': 'Vengono applicate commissioni sulle transazioni?'
        },
        answer: {
          'en-EN':
            'The commissions are established by the Exchange based on the trading pair (e.g. BTC-USDT) and are applied to the individual transactions carried out by the bots.',
          'it-IT':
            'Le commissioni sono stabilite dall’Exchange in base alla coppia di trading (es: BTC-USDT) e vengono applicate alle singole transazioni effettuate dai bot.'
        },
        active: true
      },
      {
        question: {
          'en-EN': 'Can I add capital to my bot?',
          'it-IT': 'Posso aggiungere capitale al mio bot?'
        },
        answer: {
          'en-EN':
            'Yes, it is possible to add capital starting from a minimum of $100 up to the maximum limit allowed based on the type of bot, once every 4 days.',
          'it-IT':
            'Sì, è possibile aggiungere capitale a partire da un minimo di $100 fino al limite massimo consentito in base alla tipologia di bot, una volta ogni 4 giorni.'
        },
        active: true
      },
      {
        question: {
          'en-EN': 'Can I pause my bot?',
          'it-IT': 'Posso mettere in pausa il mio bot?'
        },
        answer: {
          'en-EN':
            'You can pause the bots at any time. Pausing a bot allows you to decide whether to keep the asset quantity or sell everything. To prevent the bot from error upon restart, it is important not to move the funds dedicated to it. By restarting, it will resume its operation from the moment it was paused.',
          'it-IT':
            'Puoi mettere in pausa i bot in qualsiasi momento. Mettere in pausa un bot consente di decidere se mantenere la quantità di asset o vendere tutto. Affinché il bot non vada in errore al momento del riavvio è importante non spostare i fondi dedicati ad esso. Riavviandolo, riprenderà la propria operatività dal momento in cui è stato messo in pausa.'
        },
        active: true
      },
      {
        question: {
          'en-EN': 'Can I delete my bot?',
          'it-IT': 'Posso eliminare il mio bot?'
        },
        answer: {
          'en-EN':
            'You can delete the bots at any time. Deleting a bot means it cannot be restarted. By deleting it, you can decide whether to keep the asset quantity or sell everything. The final amount of the bot will be restored in the portfolio and updated based on its earnings or losses. It will always be possible to create another using the same strategy or use the restored amount to start a different type of bot.',
          'it-IT':
            'Puoi eliminare i bot in qualsiasi momento. Eliminare un bot significa non poterlo più riavviare. Eliminandolo è possibile decidere se mantenere la quantità di asset o vendere tutto. L’importo finale del bot verrà ripristinato nel portafoglio e aggiornato in base ai suoi ricavi o alle sue perdite. Sarà sempre possibile crearne un altro utilizzando la stessa strategia oppure utilizzare l’importo ripristinato per avviare una tipologia differente di bot.'
        },
        active: true
      },
      {
        question: {
          'en-EN': 'Why did my bot encounter an error?',
          'it-IT': 'Perché il mio bot è andato in errore?'
        },
        answer: {
          'en-EN':
            'Bot may encounter an error and interrupt its operation. Below are the most common errors and how to proceed to restore the bot to regular operation. </br> <b>407 → Invalid API Key</b><br> Recreate the API Key (no need to reconnect Hodlie and Binance). </br> </br> <b>408 → Inconsistent BNB discount status between Hodlie and the Exchange</b></br> Check that the BNB discount is enabled or disabled on both Hodlie and Binance, the status between the two must be consistent. </br> </br> <b>410 → Insufficient liquidity</b></br> Make sure you have sufficient liquidity in the spot wallet for the bot to resume operation. Then restart the bot.',
          'it-IT':
            'E’ possibile che i bot entrino in stato di errore interrompendo la propria operatività. Di seguito sono riportati gli errori più comuni e come comportarsi affinché il bot riprenda ad operare regolarmente. </br> <b>407 → API Key non valida</b><br> Ricreare l’API Key (non è necessario effettuare nuovamente il collegamento tra Hodlie e Binance). </br> </br> <b>408 → Stato dello sconto BNB non consistente tra Hodlie e l’Exchange</b></br> Controllare che lo sconto BNB sia abilitato o disabilitato sia su Hodlie che su Binance, lo stato tra i due deve essere consistente. </br> </br> <b>410 → Liquidità insufficiente</b></br> Assicurarsi di avere nel wallet spot la liquidità necessaria al bot per poter riprendere l’operatività. Quindi ricreare il bot. '
        },
        active: true
      },
      {
        question: {
          'en-EN': 'Why has my bot not traded for days?',
          'it-IT': 'Perchè il mio bot non compie operazioni da giorni?'
        },
        answer: {
          'en-EN': `
              Each strategy behaves differently depending on the risk level and
              operates based on what it deems most appropriate according to the
              logic it has been trained with.
              <b>
                <font color='${blueColor}'>
                   
                  If the bots do not make transactions for a certain period of
                  time, it means that the market conditions necessary for an
                  operation have not occurred.
                </font>
              </b> 
              <br /> In order to better understand the different strategies, it
              is advisable to consult the technical sheet of each bot and read
              its description on the <a href="/invest">Create Bot</a> page.
            `,
          'it-IT': `
              Ogni strategia si comporta in maniera diversa a seconda del
              livello di rischio ed opera sulla base di ciò che reputa più
              opportuno secondo la logica con cui è stata allenata.
              <b>
                <font color='${blueColor}'>
                   
                  Se i bot non effettuano transazioni per un certo periodo di
                  tempo significa che non si sono verificate le condizioni di
                  mercato tali da richiedere un'operazione.
                </font>
              </b> 
              <br /> Al fine di poter comprendere meglio le diverse strategie, è
              consigliabile consultare la scheda tecnica di ciascun bot e di
              leggerne la descrizione alla pagina <a href="/invest">Creazione bot</a>.
            `
        },
        active: true
      }
    ],
    Exchange: [
      {
        question: {
          'en-EN': 'Can I deposit/withdraw my money on Hodlie?',
          'it-IT': 'Posso depositare/prelevare i miei soldi su Hodlie?'
        },
        answer: {
          'en-EN': `No, <b>
            <font color='${blueColor}'>
            It is NOT possible to deposit funds on Hodlie and at the same time Hodlie does NOT have authorization to withdraw from users' wallets or transfer part of its capital.
            </font>
          </b><br> Hodlie performs operations on the Exchange (currently Binance) that the user connects to the platform and can only use those funds to make trades.`,
          'it-IT': `No, <b>
            <font color='${blueColor}'>
            NON è possibile depositare fondi su Hodlie e allo stesso tempo Hodlie NON ha l'autorizzazione per effettuare prelievi dal portafoglio degli utenti né trasferire parte del suo capitale.
            </font>
          </b><br> Hodlie esegue operazioni sull’Exchange (attualmente Binance) che l’utente collega alla piattaforma e può utilizzare quei fondi solo per realizzare gli scambi.`
        },
        active: true
      },
      {
        question: {
          'en-EN': 'How can I connect my Wallet?',
          'it-IT': 'Come posso collegare il mio Wallet?'
        },
        answer: {
          'en-EN': `Currently, only integration with Binance is available. This means that you cannot use Hodlie bots without first connecting your Binance wallet to Hodlie using an API Key. <b>
            <font color='${blueColor}'>
            To successfully connect your wallet, it is important to carefully follow our step-by-step <a href='/connect-binance-wallet'>guide</a>.
            </font>
          </b>`,
          'it-IT': `Attualmente è disponibile solo l’integrazione con Binance. Ciò significa che non è possibile utilizzare i bot di Hodlie senza prima connettere, tramite API Key, il portafoglio di Binance a Hodlie. <b>
            <font color='${blueColor}'>
            Per riuscire a collegare il wallet è importante seguire con attenzione, passo per passo, la nostra <a href='/connect-binance-wallet' style='text-decoration: underline'>guida</a>.
            </font>
          </b>`
        },
        active: true
      },
      {
        question: {
          'en-EN': 'How can I deposit on Binance?',
          'it-IT': 'Come posso depositare su Binance?'
        },
        answer: {
          'en-EN': `Our updated <a href='/deposit-binance'>guide</a> for depositing on Binance is available on the Wallet page.`,
          'it-IT': `Nella pagina Wallet è disponibile la nostra <a href='/deposit-binance'>guida</a> aggiornata per depositare su Binance.`
        },
        active: true
      },
      {
        question: {
          'en-EN': 'Are my API keys safe?',
          'it-IT': 'Le mie chiavi API sono al sicuro?'
        },
        answer: {
          'en-EN': `The API Key, which allows the bots to perform buy and sell operations, has a set of permissions and Hodlie only requests permission for trading. <b>
            <font color='${blueColor}'>
            Therefore, it is not possible to make withdrawals or perform other operations using the API Key
            </font>
          </b> (as specified in the Terms and Conditions section). Additionally, our team consists of security experts, so the platform's architecture already has significant solutions for protecting API keys.`,
          'it-IT': `L’API Key, che permette ai bot di effettuare operazioni di compravendita, ha una serie di permessi e Hodlie chiede di abilitare solo quello per fare trading. <b>
            <font color='${blueColor}'>
            Non è quindi possibile effettuare prelievi ed altre operazioni tramite API Key
            </font>
          </b> (come specificato nella sezione Termini e condizioni). Inoltre, il nostro team è composto da esperti di sicurezza, per cui l’architettura della piattaforma vanta già notevoli soluzioni per la protezione delle chiavi API.`
        },
        active: true
      }
    ],
    pro: [
      {
        question: {
          'en-EN': 'How can I modify my subscription?',
          'it-IT': 'Come posso modificare il mio abbonamento?'
        },
        answer: {
          'en-EN': `To change your Explorer or Investor subscription from <b>
          <font color='${blueColor}'>
          monthly to yearly</font></b>, simply go to the <b>
            <font color='${blueColor}'>
            Account
            </font>
          </b> page and select <b>
          <font color='${blueColor}'>
          Pricing</font></b>. By clicking on the <b>
        <font color='${blueColor}'>
        Go to yearly
        </font>
      </b> button, you will be taken to the Stripe page where you can proceed directly with the payment.`,
          'it-IT': `Per modificare il proprio abbonamento Explorer o Investor  <b>
          <font color='${blueColor}'>
          da mensile ad annuale</font></b>, basta dirigersi alla pagina <b>
            <font color='${blueColor}'>
            Account
            </font>
          </b> e selezionare <b>
          <font color='${blueColor}'>
          Pricing</font></b>. Cliccando sul bottone <b>
        <font color='${blueColor}'>
        Passa ad annuale
        </font>
      </b> si accede alla pagina di Stripe in cui è possibile procedere direttamente con il pagamento.`
        },
        active: true
      },
      {
        question: {
          'en-EN': 'Can I upgrade my plan?',
          'it-IT': 'Posso fare l’upgrade?'
        },
        answer: {
          'en-EN': `It is possible to <b>
          <font color='${blueColor}'>
          upgrade from Explorer to Investor
          </font>
          </b> by going to the <b>
          <font color='${blueColor}'>
          Account
          </font>
          </b> page and selecting <b>
          <font color='${blueColor}'>
          Pricing</font></b>. By clicking on the <b>
          <font color='${blueColor}'>
          Upgrade
          </font>
          </b> button, you will be taken to the Stripe page where you can proceed directly with the payment.`,
          'it-IT': `E’ possibile effettuare <b>
          <font color='${blueColor}'>
          l’upgrade da Explorer a Investor
          </font>
          </b> dirigendosi alla pagina <b>
            <font color='${blueColor}'>
            Account
            </font>
          </b> e selezionando <b>
          <font color='${blueColor}'>
          Pricing</font></b>. Cliccando sul bottone <b>
        <font color='${blueColor}'>
        Upgrade
        </font>
      </b> si accede alla pagina di Stripe in cui è possibile procedere direttamente con il pagamento.`
        },
        active: true
      },
      {
        question: {
          'en-EN': 'How can I cancel my subscription?',
          'it-IT': 'Come posso annullare il mio abbonamento?'
        },
        answer: {
          'en-EN': `To cancel your subscription, simply select the <b>
                  <font color='${blueColor}'>
                  Account
                  </font>
                </b> page and click on <b>
                <font color='${blueColor}'>
                Subscription
                </font>
              </b>. In the top right corner, you will find the <b>
              <font color='${blueColor}'>
              Manage
              </font>
            </b> button, which will take you to the Stripe page where you can cancel your plan by clicking on <b>
            <font color='${blueColor}'>
            Cancel
            </font>
          </b>. The subscription will remain active until the end of the subscription period, during which you can renew it by following the same procedure.`,
          'it-IT': `Per annullare il proprio abbonamento basta selezionare la pagina <b>
            <font color='${blueColor}'>
            Account
            </font>
          </b> e cliccare su <b>
          <font color='${blueColor}'>
          Abbonamento
          </font>
        </b>. In alto a destra è presente il bottone <b>
        <font color='${blueColor}'>
        Gestisci
        </font>
      </b> tramite il quale si accede alla pagina di Stripe in cui è possibile annullare il proprio piano semplicemente cliccando su <b>
      <font color='${blueColor}'>
      Annulla
      </font>
    </b>. L’abbonamento resta disponibile fino al termine del periodo di sottoscrizione, durante il quale è possibile rinnovarlo effettuando la stessa procedura.`
        },
        active: true
      },
      {
        question: {
          'en-EN':
            'Is the subscription cost deducted from the bot-managed capital?',
          'it-IT':
            'Il costo dell’abbonamento viene prelevato dal capitale gestito dai bot?'
        },
        answer: {
          'en-EN': `Hodlie DOES NOT have authorization to withdraw funds from users' wallets. <b>
            <font color='${blueColor}'>
            Therefore, the subscription payment is made at the end of the 7-day free trial period through Stripe
            </font>
          </b>, a certified provider with which you can pay using a credit or reloadable card.`,
          'it-IT': `Hodlie NON ha l'autorizzazione per prelevare fondi dal portafoglio degli utenti. <b>
            <font color='${blueColor}'>
            Pertanto, il pagamento dell'abbonamento avviene al termine dei 7 giorni di prova gratuita tramite Stripe
            </font>
          </b>, un provider certificato con il quale è possibile pagare utilizzando carta di credito o ricaricabile.`
        },
        active: true
      }
    ],
    Referral: {
      'en-EN': `Discover our <a href="/rewards">Referral</a> page.`,
      'it-IT': `Scopri la nostra pagina <a href="/rewards">Referral</a>.`
    },
    help: {
      'en-EN': `Check out our <a href="/support">Support</a> section.`,
      'it-IT': `Consulta la nostra sezione <a href="/support">Supporto</a>.`
    }
  }
};

export const FeedbackPopUp = {
  morePop: {
    'en-EN': 'Do you want to receive these updates more often?',
    'it-IT': 'Vuoi ricevere più spesso queste informazioni?'
  },
  closing: {
    'en-EN': 'Close',
    'it-IT': 'Chiudi'
  }
};

export const ForgotPasswordForm = {
  email: {
    'en-EN': 'Email address',
    'it-IT': 'Indirizzo Email'
  },
  forgotPwd: {
    'en-EN': 'Forgot your Password?',
    'it-IT': 'Password Dimenticata?'
  },
  sendLink: {
    'en-EN': 'Send reset link',
    'it-IT': 'Invia il link di reset password'
  },
  // okMsg: {
  //   'en-EN':
  //     'You are currently in our waiting list. Soon, you will receive through email the instructions to access the platform!',
  //   'it-IT':
  //     "Sei attualmente in lista di attesa. Presto, riceverai via mail le istruzioni per l' accesso alla piattaforma."
  // }
  okMsg: {
    'en-EN':
      'If the email address entered is correct, you will receive an email to change your account password',
    'it-IT':
      "Se l'indirizzo mail inserito è corretto riceverai una mail per cambiare la password del tuo account."
  },
  okMsg1: {
    'en-EN': "If you can't find the email, check your spam.",
    'it-IT': "Se non riesci a trovare l'email, controlla nello spam."
  }
};

export const GoProModal = {
  title: {
    'en-EN': 'To perform this operation, you need to be an Explorer',
    'it-IT': "Per effettuare l'operazione devi essere un utente Explorer"
  },
  button: {
    'en-EN': 'Become Explorer',
    'it-IT': 'Diventa Explorer'
  }
};

export const GoProWidget = {
  title: {
    'en-EN': 'Get Hodlie PRO',
    'it-IT': 'Ottieni Hodlie PRO'
  },
  text: {
    'en-EN': ' off with annual payment',
    'it-IT': ' di sconto con pagamento annuale'
  },
  real: {
    'en-EN': 'Real trading',
    'it-IT': 'Real trading'
  },
  max: {
    'en-EN': '$ 10.000 max cap',
    'it-IT': '$ 10.000 max cap'
  },
  up: {
    'en-EN': 'Up to 20 Bots',
    'it-IT': 'Fino a 20 Bot'
  },
  pro: {
    'en-EN': 'Go PRO',
    'it-IT': 'Passa a PRO'
  },
  month: {
    'en-EN': 'month',
    'it-IT': 'mese'
  }
};

export const GreetingCard = {
  notifications: [
    {
      title: [
        {
          'en-EN': ' orders',
          'it-IT': ' ordini'
        },
        {
          'en-EN': ' have been executed in the last ',
          'it-IT': ' sono stati eseguiti nelle ultime '
        },
        {
          'en-EN': ' hours from your AI Agents',
          'it-IT': ' ore dai tuoi AI Agents'
        }
      ]
    },
    {
      title: [
        {
          'en-EN': '',
          'it-IT': 'Il rendimento di '
        },
        {
          'en-EN': "'s performance has been ",
          'it-IT': ' è stato del '
        },
        {
          'en-EN': '% for the past ',
          'it-IT': '% nelle ultime '
        },
        {
          'en-EN': ' hours',
          'it-IT': ' ore'
        }
      ]
    }
  ],
  hi: {
    'en-EN': 'Hi',
    'it-IT': 'Ciao'
  },
  your: {
    'en-EN': 'Your ',
    'it-IT': 'Il tuo '
  },
  realWalletPre: {
    'en-EN': 'Binance ',
    'it-IT': ''
  },
  realWalletAfter: {
    'en-EN': '',
    'it-IT': ' Binance'
  },
  virtualWalletPre: {
    'en-EN': 'Virtual ',
    'it-IT': ''
  },
  virtualWalletAfter: {
    'en-EN': '',
    'it-IT': ' Virtuale'
  },
  walletInfo: {
    'en-EN': 'The displayed value in dollars may not be up-to-date',
    'it-IT':
      'Il controvalore visualizzato in dollari potrebbe non essere aggiornato'
  },
  realWallet: {
    'en-EN': 'Managed Capital',
    'it-IT': 'Controvalore'
  },
  virtualWallet: {
    'en-EN': 'Virtual Wallet',
    'it-IT': 'Wallet Virtuale'
  },
  revenues: [
    {
      'en-EN': 'Last ',
      'it-IT': 'Profitto nelle ultime '
    },
    {
      'en-EN': ' hours Revenue',
      'it-IT': ' ore'
    }
  ],
  greatingNight: {
    'en-EN': ' Good night',
    'it-IT': 'Buonasera'
  },
  greatingMorning: {
    'en-EN': ' Good morning',
    'it-IT': 'Buongiorno'
  },
  greatingAfternoon: {
    'en-EN': ' Good afternoon',
    'it-IT': 'Buon pomeriggio'
  },
  greatingEvening: {
    'en-EN': ' Good evening',
    'it-IT': 'Buonasera'
  },
  more: {
    'en-EN': 'show more',
    'it-IT': 'vedi di più'
  },
  hide: {
    'en-EN': 'hide',
    'it-IT': 'nascondi'
  },
  add: {
    title: {
      'en-EN': 'Add Capital',
      'it-IT': 'Aggiungi Capitale'
    },
    text: {
      'en-EN': 'Set up a recurring addition or add liquidity',
      'it-IT': 'Imposta un’aggiunta ricorrente o aggiungi liquidità'
    }
  },
  referral: {
    title: {
      'en-EN': 'Earn 50 USDT',
      'it-IT': 'Guadagna 50 USDT'
    },
    title_BLACK: {
      'en-EN': 'Earn 100 USDC',
      'it-IT': 'Guadagna 100 USDC'
    },
    text: {
      'en-EN': 'The more friends, the more you earn!',
      'it-IT': 'Più amici inviti, più guadagni!'
    }
  },
  feedback: {
    title: {
      'en-EN': 'Do you need some help?',
      'it-IT': 'Hai bisogno di aiuto?'
    },
    text: {
      'en-EN': 'Find out here how you can contact us',
      'it-IT': 'Scopri qui come puoi contattarci'
    }
  },
  pickUpWhereYouLeft: {
    'en-EN': 'Pick up where you left',
    'it-IT': 'Riprendi da dove hai lasciato'
  }
};

export const LinePayment = {
  infopoint0: {
    'en-EN': 'The ',
    'it-IT': 'Il guadagno del bot '
  },
  infopoint1: {
    'en-EN': '’s earnings since the ',
    'it-IT': ' dal giorno '
  },
  infopoint2: {
    'en-EN': ' up to now',
    'it-IT': ' fino ad adesso'
  },
  noData: {
    'en-EN': 'No data currently available',
    'it-IT': 'Attualmente nessun dato è disponibile'
  },
  lastDatum: {
    'en-EN': 'Now',
    'it-IT': 'Adesso'
  },
  invested: {
    'en-EN': 'Invested',
    'it-IT': 'Investito'
  },
  equivalent: {
    'en-EN': 'Equivalent',
    'it-IT': 'Controvalore'
  },
  goto: {
    'en-EN': 'Go to your investments',
    'it-IT': 'Vai ai tuoi investimenti'
  }
};

export const login = {
  noAccount: {
    'en-EN': "Don't have an account?",
    'it-IT': 'Non hai un account?'
  },
  register: {
    'en-EN': 'Register NOW!',
    'it-IT': 'Registrati ADESSO!'
  },
  account: {
    'en-EN': 'Do you already have an account?',
    'it-IT': 'Hai già un account?'
  },
  login: {
    'en-EN': 'login',
    'it-IT': 'Effettua il login!'
  }
};

export const LoginForm = {
  email: {
    'en-EN': 'Email address',
    'it-IT': 'Indirizzo Email'
  },
  forgotPwd: {
    'en-EN': 'Forgot Password?',
    'it-IT': 'Hai dimenticato la password?'
  },
  login: {
    'en-EN': 'Login',
    'it-IT': 'Entra'
  },
  or: {
    'en-EN': 'or',
    'it-IT': 'oppure'
  },
  googleButtonText: {
    'en-EN': 'Sign in with Google',
    'it-IT': 'Accedi con Google'
  }
};

export const MainLayout = {
  congrats: {
    'en-EN': 'Congratulations!',
    'it-IT': 'Congratulazioni!'
  },
  winningText: {
    'en-EN': 'You have won',
    'it-IT': 'Hai vinto'
  },
  getDaily: {
    'en-EN': 'Get your daily <font color=' + goldColor + '>Reward</font>!',
    'it-IT':
      'Riscuoti il tuo <font color=' + goldColor + '>Bonus</font> Giornaliero!'
  },
  waiting: {
    'en-EN': 'Wait for it...',
    'it-IT': 'Attendi...'
  },
  getIt: {
    'en-EN': 'Get it!',
    'it-IT': 'Riscuoti!'
  },
  closing: {
    'en-EN': 'Close',
    'it-IT': 'Chiudi'
  },
  maintenance: {
    'en-EN': 'maintenance',
    'it-IT': 'in manutenzione'
  },
  maintenanceText: {
    'en-EN':
      "Platform is currently under maintenance, don't worry, your bots are still working!",
    'it-IT':
      'La piattaforma attualmente è in manutenzione, non ti preoccupare perchè i tuoi Bot stanno continuando a lavorare!'
  },
  headerText: {
    'en-EN': 'Earn <strong>50 USDT</strong> for every friend you invite!',
    'it-IT': 'Guadagna <strong>50 USDT</strong> per ogni amico che inviti!'
  },
  headerText_1: {
    'en-EN': 'Click to earn <strong>50 USDT</strong>!',
    'it-IT': 'Clicca per guadagnare <strong>50 USDT</strong>!'
  },
  headerText_blackBTC: {
    'en-EN': 'Earn <strong>100 USDC</strong> for every friend you invite!',
    'it-IT': 'Guadagna <strong>100 USDC</strong> per ogni amico che inviti!'
  },
  headerText_1_blackBTC: {
    'en-EN': 'Click to earn <strong>100 USDC</strong>!',
    'it-IT': 'Clicca per guadagnare <strong>100 USDC</strong>!'
  }
};

export const MarketItem = {
  price: {
    'en-EN': '24h price difference on Binance',
    'it-IT': 'Variazione di prezzo rispetto a 24 ore prima su Binance'
  },
  volume: {
    'en-EN': 'Last 24h volume on Binance',
    'it-IT': 'Volumi spostati nelle ultime 24h su Binance'
  },
  chartInfo: {
    'en-EN': 'Last 24h data on Binance',
    'it-IT': 'Dati relativi alle ultime 24 ore su Binance'
  },
  chartInfoMarkets: {
    'en-EN': 'Latest market data',
    'it-IT': 'Ultimi dati di mercato'
  }
};

export const monthsList = {
  months: {
    'en-EN': [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ],
    'it-IT': [
      'Gen',
      'Feb',
      'Mar',
      'Apr',
      'Mag',
      'Giu',
      'Lug',
      'Ago',
      'Set',
      'Ott',
      'Nov',
      'Dec'
    ]
  },
  monthsLong: {
    'en-EN': [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    'it-IT': [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre'
    ]
  }
};

export const MostBots = {
  title: {
    'en-EN': 'Your Investment',
    'it-IT': 'I tuoi investimenti'
  },
  noData: {
    'en-EN': 'No investment data is available at the moment',
    'it-IT': 'Non sono disponibili dati relativi ai tuoi investimenti'
  },
  noAssets: {
    'en-EN': 'No assets. ',
    'it-IT': 'Nessun asset. '
  },
  start: {
    'en-EN': 'Start your first AI Strategy!',
    'it-IT': 'Lancia la tua prima strategia!'
  },
  noBotDescription: {
    'en-EN': 'Statistics for your strategies are not available.',
    'it-IT': 'Non sono disponibili statistiche per le tue strategie.'
  },
  wallet: {
    'en-EN': 'Wallet',
    'it-IT': 'Wallet'
  },
  assets: {
    'en-EN': 'Assets',
    'it-IT': 'Asset'
  },
  noBots: {
    'en-EN': 'No stats',
    'it-IT': 'Nessuna statistica'
  },
  Invested: {
    'en-EN': 'Invested',
    'it-IT': 'Investito'
  },
  'Not Invested': {
    'en-EN': 'Not Invested',
    'it-IT': 'Non Investito'
  },
  Equivalent: {
    'en-EN': 'Equivalent',
    'it-IT': 'Controvalore'
  },
  allocated: {
    'en-EN': 'Allocated',
    'it-IT': 'Allocato'
  },
  notAllocated: {
    'en-EN': 'Not Allocated',
    'it-IT': 'Non Allocato'
  }
};

export const NavbarVerticalMenuItem = {
  connected: {
    'en-EN': 'Connected',
    'it-IT': 'Connesso'
  },
  notConnected: {
    'en-EN': 'Not Connected',
    'it-IT': 'Non Connesso '
  }
};

export const NewRelease = {
  title: {
    'en-EN': 'New Release!',
    'it-IT': 'Nuovo Aggiornamento!'
  },
  text: {
    'en-EN': `The wait is over: Solana (SOL) is now available for all Explorers.<br><br>
    From this moment, <b><font color='${goldColor}'>you can activate your Dynamo on SOLUSDT</font></b>, seizing the opportunity to invest in one of the most promising digital currencies at the moment.<br><br>
    Don't wait! Start your Dynamo now, also available in virtual mode!`,
    'it-IT': `
      L'attesa è finita: Solana (SOL) è ora disponibile per tutti gli utenti Explorer.<br><br>
      A partire da questo momento, <b><font color='${goldColor}'>puoi attivare il tuo Dynamo su SOLUSDT</font></b>, cogliendo l'opportunità di investire in una delle valute digitali più promettenti del momento.<br><br>
      Non aspettare! Avvia ora il tuo Dynamo, disponibile anche in modalità virtuale!`
  },
  endText: {
    'en-EN': 'Hurry up and check it out on the dedicated page!',
    'it-IT': 'Cosa state aspettando? Correte ad esplorare tutte le novità!'
  },
  closing: {
    'en-EN': 'Close',
    'it-IT': 'Chiudi'
  }
};

export const OrderTable = {
  price: {
    'en-EN': 'Price',
    'it-IT': 'Prezzo'
  },
  priceInfo: {
    'en-EN': 'Current price and percentage compared to 24 hours ago',
    'it-IT': 'Prezzo attuale e percentuale rispetto a 24h prima'
  },
  last24HVol: {
    'en-EN': 'Last 24h Volume',
    'it-IT': 'Volume nelle ultime 24h'
  },
  last24HVolInfo: {
    'en-EN':
      'Volumes moved in the last 24 hours and percentage compared to 24 hours ago',
    'it-IT': 'Volumi mossi nelle ultime 24h e percentuale rispetto a 24h prima'
  },
  volBid: {
    'en-EN': 'Bid Volume',
    'it-IT': 'Volume Offerta'
  },
  volAsk: {
    'en-EN': 'Ask Volume',
    'it-IT': 'Volume Domanda'
  },
  total: {
    'en-EN': 'Total',
    'it-IT': 'Totale'
  },
  ask: {
    'en-EN': 'Ask',
    'it-IT': 'Domanda'
  },
  bid: {
    'en-EN': 'Bid',
    'it-IT': 'Offerta'
  },
  nextIn: {
    'en-EN': 'Next Update in ',
    'it-IT': 'Prossimo aggiornamento in '
  },
  markets: {
    'en-EN': 'Markets',
    'it-IT': 'Mercati'
  }
};

export const Onboard = {
  virtualMode: {
    'en-EN': 'Virtual Mode',
    'it-IT': 'Modalità Virtuale'
  },
  virtualWalletTxt: {
    'en-EN':
      "Do you want to test Hodlie's Artificial Intelligence? Try out some trading patterns on a demo account.",
    'it-IT':
      'Sei impaziente di testare l’Intelligenza Artificiale di Hodlie? Prova alcuni modelli di trading su un account demo.'
  },
  goVirtualWallet: {
    'en-EN': 'Go to your virtual wallet',
    'it-IT': 'Vai al tuo portafoglio virtuale'
  }
};

export const ProAlert = {
  title: {
    'en-EN': 'Upgrade now',
    'it-IT': "Effettua l'upgrade ora"
  },
  description: {
    'en-EN':
      'Choose between Explorer and Investor and start your subscription, the first 7 days are free for you!',
    'it-IT':
      'Scegli tra Explorer e Investor e avvia il tuo abbonamento, per te i primi 7 giorni sono gratis!'
  },
  titleEvent: {
    'en-EN': 'Upgrade to Private!',
    'it-IT': 'Passa a Private!'
  },
  descriptionEvent: {
    'en-EN':
      'Want to get more from your trading experience? Click here for more information!',
    'it-IT':
      'Vuoi ottenere di più dalla tua esperienza di trading? Clicca qui per maggiori informazioni!'
  },
  convertMonthlyToYearlyTitle: {
    'en-EN': 'Only for you!',
    'it-IT': 'Solo per te!'
  },
  convertMonthlyToYearlyDescription: {
    'en-EN':
      'Upgrade to the annual plan with an additional 10% discount by 12/02.<br/>Click here and enter the code <b>{COUPON}</b> at checkout',
    'it-IT':
      'Passa al piano annuale con un ulteriore sconto del 10% entro il 12/02.<br/>Clicca qui e inserisci il codice <b>{COUPON}</b> in fase di checkout'
  },
  convertMonthlyToYearly2Title: {
    'en-EN': 'Only for you!',
    'it-IT': 'Solo per te!'
  },
  convertMonthlyToYearly2Description: {
    'en-EN':
      'Upgrade to the annual plan with an additional 35% discount by 12/02.<br/>Click here and enter the code <b>{COUPON}</b> at checkout',
    'it-IT':
      'Passa al piano annuale con un ulteriore sconto del 35% entro il 12/02.<br/>Clicca qui e inserisci il codice <b>{COUPON}</b> in fase di checkout'
  },
  convert_active_usersTitle: {
    'en-EN': 'You are among our most active users!',
    'it-IT': 'Sei tra i nostri utenti più attivi!'
  },
  convert_active_usersDescription: {
    'en-EN':
      'To thank you, just for you a 10% discount on the subscription!<br/>Click here and start the <b>free trial!</b> Promo available until 26/01!',
    'it-IT':
      'Per ringraziarti, solo per te uno sconto del 10% sull’abbonamento!<br/>Clicca qui ed inizia la <b>prova gratuita!</b> Promo disponibile fino al 26/01!'
  },
  churn_discountTitle: {
    'en-EN': 'Come back to us: one month for you at €0.99',
    'it-IT': 'Torna con noi: per te un mese a 0,99€'
  },
  churn_discountDescription: {
    'en-EN':
      'Continue to enjoy PRO features, click here: the discount is already applied at checkout!<br>Promo valid until {END_DATE}',
    'it-IT':
      'Continuare a godere delle funzionalità PRO, clicca qui: lo sconto già applicato in fase di checkout!<br>Promo valida fino al {END_DATE}'
  },
  unlockSOLdescription: {
    'en-EN':
      'To celebrate the arrival of Solana, <b>we are offering you an exclusive 10% discount</b> on the annual PRO subscription. The discount is already applied at checkout, valid until 05/10/24',
    'it-IT':
      "Per festeggiare l'arrivo di Solana, <b>ti offriamo uno sconto esclusivo del 10%</b> sull'abbonamento PRO annuale. Lo sconto è già applicato in fase di checkout, promo valida fino al 10/05/24"
  }
};

export const Pricing = {
  title: {
    'en-EN': 'Pricing',
    'it-IT': 'Prezzo'
  },
  traderInfo: {
    'en-EN': 'Your actual plan',
    'it-IT': 'Il tuo piano attuale'
  },
  accordionHeader: {
    'en-EN': 'Details',
    'it-IT': 'Dettagli'
  },
  error: {
    'en-EN': 'There was an error, reload the page or contact us.',
    'it-IT': "C'è stato un errore ricarica la pagina o contattaci."
  },
  pricingHeader: {
    title: { 'en-EN': 'Pricing', 'it-IT': 'Abbonamento' },
    badge: { 'en-EN': 'Discounted', 'it-IT': 'Sconto' },
    monthly: { 'en-EN': 'Monthly', 'it-IT': 'Mensile' },
    yearly: { 'en-EN': 'Yearly', 'it-IT': 'Annuale' },
    quarterly: { 'en-EN': 'Quarterly', 'it-IT': 'Trimestrale' }
  },
  goToYearly: {
    'en-EN': 'Go to yearly',
    'it-IT': 'Passa ad annuale'
  },
  discount: {
    'en-EN': 'Save 33% with annual subscription',
    'it-IT': "Risparmia il 33% con l'abbonamento annuale"
  },
  bodyText: {
    'en-EN':
      'Before starting your subscription, you must complete the above steps',
    'it-IT':
      'Prima di avviare il tuo abbonamento, devi completare gli step precedenti'
  },
  changeBilling: {
    'en-EN': 'Upgrade',
    'it-IT': 'Upgrade'
  },
  freeTrial: {
    'en-EN': 'Start your free trial',
    'it-IT': 'Avvia la prova gratuita'
  },
  start: {
    'en-EN': 'Start subscription',
    'it-IT': 'Avvia abbonamento'
  },
  back: {
    'en-EN': 'Back',
    'it-IT': 'Indietro'
  },
  upgradeTitle: {
    'en-EN': 'Upgrade to Investor',
    'it-IT': "Effettua l'upgrade a Investor"
  },
  freeUpgradeTitle: {
    'en-EN': 'Upgrade to Investor for free, pay after your trial period ends',
    'it-IT':
      "Effettua gratuitamente l'upgrade a Investor, pagherai al termine del tuo periodo di prova"
  },
  upgradeButton: {
    'en-EN': 'Upgrade',
    'it-IT': 'Upgrade'
  },
  freeUpgradeButton: {
    'en-EN': 'Free upgrade',
    'it-IT': 'Upgrade gratuito'
  },
  selectText: {
    'en-EN': 'Select your subscription plan',
    'it-IT': 'Seleziona il tuo piano di abbonamento'
  },
  upgradeOkText: {
    'en-EN':
      'Upgrade completed successfully, within a few seconds the page will update automatically!',
    'it-IT':
      'Upgrade effettuato correttamente, entro pochi secondi la pagina si aggiornerà automaticamente!'
  },
  upgradeTextButton: {
    'en-EN': 'Next',
    'it-IT': 'Vai'
  },
  upgradePreviewText: {
    'en-EN': 'Upgrade TODAY for only ',
    'it-IT': "Effettua l'upgrade OGGI a soli "
  },
  virtualTitle: {
    'en-EN': 'Virtual Mode',
    'it-IT': 'Modalità virtuale'
  },
  virtualBadge: {
    'en-EN': 'No debit/credit card required',
    'it-IT': 'Nessuna carta di debito/credito richiesta'
  },
  virtualSub: {
    'en-EN': '7-day free trial',
    'it-IT': 'Prova gratis per 7 giorni'
  },
  virtualText: {
    'en-EN':
      'While you wait for your deposit on Binance, start an AI Portfolio in virtual mode and see how Hodlie can manage your cryptocurrency investments. The simulation will last 7 days, with no risk to your capital.',
    'it-IT':
      'Mentre aspetti il tuo deposito su Binance, avvia un AI Portfolio in modalità virtuale e scopri come Hodlie può gestire i tuoi investimenti in criptovalute. La simulazione durerà 7 giorni, senza rischi per il tuo capitale​​.'
  },
  virtualTextKyc: {
    'en-EN':
      'Start an AI Portfolio in virtual mode and see how Hodlie can manage your cryptocurrency investments. The simulation will last 7 days, with no risk to your capital.',
    'it-IT':
      'Avvia un AI Portfolio in modalità virtuale e scopri come Hodlie può gestire i tuoi investimenti in criptovalute. La simulazione durerà 7 giorni, senza rischi per il tuo capitale​​.'
  },
  alert: {
    bot: {
      'en-EN': 'Maximum allocable capital $10,000',
      'it-IT': 'Massimo capitale allocabile 10.000 $'
    },
    portfolio: {
      'en-EN': 'This subscription does not allow you to start an AI Portfolio.',
      'it-IT': 'Questo abbonamento non permette di avviare un AI Portfolio.'
    },
    noThisPortfolio: {
      'en-EN':
        'This subscription does not allow you to start this AI Portfolio.',
      'it-IT': 'Questo abbonamento non permette di avviare questo AI Portfolio.'
    }
  },
  pricingCard: {
    month: { 'en-EN': 'month', 'it-IT': 'mese' },
    year: { 'en-EN': 'year', 'it-IT': 'anno' },
    more: { 'en-EN': 'More about ', 'it-IT': 'Di più circa ' },
    buttonTextActualLevel: {
      'en-EN': 'Your actual plan',
      'it-IT': 'Il tuo piano attuale'
    },
    buttonTextUpgrade: {
      'en-EN': 'Start now',
      'it-IT': 'Inizia ora'
    },
    buttonTextUpgradeFree: {
      'en-EN': 'Start your free trial',
      'it-IT': 'Inizia la prova gratuita'
    },
    buttonTextDowngrade: {
      'en-EN': 'Downgrade your plan',
      'it-IT': 'Torna a questo piano'
    },
    buttonTextRenew: {
      'en-EN': 'Renew your plan',
      'it-IT': 'Rinnova il tuo piano'
    },
    buttonTextManage: {
      'en-EN': 'Manage your plan',
      'it-IT': 'Gestisci il tuo piano'
    },
    buttonTextContactUs: {
      'en-EN': 'Start now',
      'it-IT': 'Inizia ora'
    },
    buttonTextBecomeTrader: {
      'en-EN': 'Become Private',
      'it-IT': 'Diventa Private'
    },
    annualPayment: {
      'en-EN': 'with annual payment',
      'it-IT': 'con pagamento annuale'
    }
  },
  ambassadorText: {
    'en-EN':
      'Do you wanna be an <font color=' +
      goldColor +
      '><b>Ambassador</b></font>?',
    'it-IT':
      'Vuoi diventare un <font color=' +
      goldColor +
      '><b>Ambassador</b></font>?'
  },
  ambassadorLink: {
    'en-EN':
      'Find how <a href="/rewards" target="_blank" rel="noreferrer">here</a>',
    'it-IT':
      'Scopri come <a href="/rewards" target="_blank" rel="noreferrer">qui</a>'
  },
  faqPricing: [
    {
      question: {
        'en-EN': 'How do Hodlie subscription plans work?',
        'it-IT': 'Come funzionano i piani di abbonamento di Hodlie?'
      },
      answer: {
        'en-EN':
          'Access to the platform is free, but if you want to use the AI Agents with your wallet, you need to upgrade to Explorer or Investor. Once you have upgraded, you will be entitled to a 7-day free trial to use the AI models. And if you want to maximize your trading experience, we have the Private plan available, which offers a discounted price for the first three months of subscription.',
        'it-IT':
          'L’accesso alla piattaforma è gratuito, ma se vuoi utilizzare i AI Agents con il tuo wallet è necessario eseguire l’upgrade a Explorer o Investor. Una volta effettuato l’upgrade, avrai diritto a una prova gratuita di 7 giorni per utilizzare i modelli di AI. E se vuoi vivere al massimo la tua esperienza di trading abbiamo a disposizione il piano Private, che vanta un prezzo scontato per i primi tre mesi di sottoscrizione.'
      },
      active: true
    },
    {
      question: {
        'en-EN': 'Are my funds safe?',
        'it-IT': 'I miei fondi sono al sicuro?'
      },
      answer: {
        'en-EN':
          "Hodlie does not directly manage your capital, all cryptocurrencies remain deposited in your wallet at the Exchange. Access to the wallet, through the API Key, is necessary to place buy/sell orders on behalf of the user, but Hodlie does not have and will never have the ability to deposit and/or withdraw funds from users' wallets.",
        'it-IT':
          'Hodlie non gestisce direttamente il tuo capitale, tutte le criptovalute rimangono depositate nel tuo wallet presso l’Exchange. L’accesso al wallet, tramite API Key, è necessario per poter effettuare ordini di acquisto/vendita per conto dell’utente, ma Hodlie non ha e non avrà mai, in alcun modo, la possibilità di depositare e/o prelevare fondi dal wallet degli utenti.'
      },
      active: true
    },
    {
      question: {
        'en-EN': 'Can I get a discount?',
        'it-IT': 'Si può ottenere uno sconto?'
      },
      answer: {
        'en-EN':
          'Currently, there is a 33% discount for those who choose to subscribe to the Explorer or Investor plan on an annual basis instead of monthly.',
        'it-IT':
          'Attualmente è previsto uno sconto del 33% per chi decide di sottoscrivere l’abbonamento Explorer o Investor su base annuale anziché mensile.'
      },
      active: true
    },
    {
      question: {
        'en-EN': 'Does Hodlie charge additional fees?',
        'it-IT': 'Hodlie applica delle commissioni aggiuntive?'
      },
      answer: {
        'en-EN':
          'Regarding the Explorer or Investor plan, Hodlie does not apply any transaction fees. The only fee required is the monthly or annual subscription fee for the use of the platform and underlying technology. However, with the Private plan, management and performance fees are applicable.',
        'it-IT':
          "Per quanto riguarda i piani Explorer e Investor, Hodlie non applica nessuna commissione sulle transazioni. L’unica quota prevista è quella dell’abbonamento mensile o annuale per l'utilizzo della piattaforma e della tecnologia sottostante. Mentre con il piano Private sono previste delle commissioni di gestione e di performance."
      },
      active: true
    },
    {
      question: {
        'en-EN': 'What happens if I cancel my paid plan?',
        'it-IT': 'Cosa succede se interrompo il mio piano a pagamento?'
      },
      answer: {
        'en-EN':
          'If you decide to cancel your payment plan, you will automatically revert to the Free plan, losing all the benefits of your subscription. For more details on canceling the Private plan, click on <i>"Start Now"</i>.',
        'it-IT':
          'Qualora decidessi di interrompere il tuo piano a pagamento, automaticamente tornerai al piano Free, perdendo tutti i vantaggi del tuo abbonamento. Per avere maggiori dettagli sul recesso dal piano Private, clicca su <i>“Inizia Ora”</i>.'
      },
      active: true
    },
    {
      question: {
        'en-EN': 'What if I need help?',
        'it-IT': 'E se avessi bisogno di aiuto?'
      },
      answer: {
        'en-EN':
          'If you need help or need more information, please <b>contact us at <a href="mailto:' +
          supportEmail +
          '">' +
          supportEmail +
          '</a></b>.',
        'it-IT':
          'Se hai bisogno di aiuto o necessiti di più informazioni, <b>contattaci a <a href="mailto:' +
          supportEmail +
          '">' +
          supportEmail +
          '</a></b>.'
      },
      active: true
    }
  ]
};

export const Profile = {
  title: {
    'en-EN': 'Account',
    'it-IT': 'Account'
  },
  titleProfile: {
    'en-EN': 'Profile',
    'it-IT': 'Profilo'
  },
  titleSettings: {
    'en-EN': 'Settings',
    'it-IT': 'Impostazioni'
  },
  subtitle: {
    'en-EN': 'Here you can look at your personal information',
    'it-IT': 'Qui puoi vedere le tue informazioni personali'
  },
  subtitleProfile: {
    'en-EN': 'Here you can look at your personal information',
    'it-IT': 'Qui puoi vedere le tue informazioni personali'
  },
  subtitleSettings: {
    'en-EN': 'Here you can look at your personal information',
    'it-IT': 'Qui puoi vedere le tue informazioni personali'
  },
  basicUser: {
    'en-EN': 'Basic user',
    'it-IT': 'Utente Basic'
  },
  goPro: {
    'en-EN': 'Go PRO',
    'it-IT': 'Passa a PRO'
  },
  manageYourPlan: {
    'en-EN': 'Manage your plan',
    'it-IT': 'Gestisci il tuo piano'
  },
  language: {
    'en-EN': 'Language',
    'it-IT': 'Lingua'
  },
  notifications: {
    'en-EN': 'Pro Notifications',
    'it-IT': 'Notifiche Pro'
  },
  changeUsername: {
    'en-EN': 'Change your username',
    'it-IT': 'Modifica il tuo username'
  },
  changePassword: {
    'en-EN': 'Change your password',
    'it-IT': 'Modifica la tua password'
  },
  copyReferralCode: {
    'en-EN': 'Copy referral code',
    'it-IT': 'Copia il referral code'
  },
  darkMode: {
    'en-EN': 'Dark mode',
    'it-IT': 'Tema scuro'
  },
  disable: {
    'en-EN': 'Disable',
    'it-IT': 'Disabilita'
  },
  enable: {
    'en-EN': 'Enable',
    'it-IT': 'Abilita'
  },
  from: {
    'en-EN': 'from',
    'it-IT': 'da'
  },
  footerMsg: {
    'en-EN':
      '*We are working on this page, more features will be coming soon. Join our community ',
    'it-IT':
      '*Stiamo lavorando a questa pagina, presto arriveranno nuove funzionalità. Unisciti alla nostra community '
  },
  updateMsg: {
    'en-EN': 'to stay tuned.',
    'it-IT': 'per restare aggiornato.'
  },
  copy: {
    'en-EN': 'Copy to clipboard',
    'it-IT': 'Copia negli appunti'
  },
  copied: {
    'en-EN': 'Copied!',
    'it-IT': 'Copiato!'
  },
  proNotificationInfo: {
    'en-EN':
      'Only for paying users: stay updated on the performance of your bots',
    'it-IT':
      'Solo per utenti paganti: resta aggiornato sulle performance dei tuoi bot'
  },
  logout: {
    'en-EN': 'Logout of the app',
    'it-IT': "Disconnettiti dall'applicazione"
  },
  mailNotVerified: {
    'en-EN': 'Click to verify',
    'it-IT': 'Clicca per verificare'
  },
  mailVerified: {
    'en-EN': 'The email has been verified',
    'it-IT': 'La mail è verificata'
  },
  notConfirmed: {
    'en-EN': 'not confirmed',
    'it-IT': 'non confermata'
  },
  confirmed: {
    'en-EN': 'Mail confirmed',
    'it-IT': 'Mail confermata'
  },
  edit: {
    'en-EN': 'Edit',
    'it-IT': 'Modifica'
  },
  copyButton: {
    'en-EN': 'Copy',
    'it-IT': 'Copia'
  },
  mailAddress: {
    'en-EN': 'Mail Address',
    'it-IT': 'Indirizzo Mail'
  },
  createdAt: {
    'en-EN': 'Registration date',
    'it-IT': 'Data di registrazione'
  },
  connect: {
    'en-EN': 'Connect',
    'it-IT': 'Collega'
  },
  connectBinance: {
    'en-EN': 'Connect you Binance Account',
    'it-IT': 'Collega il tuo account Binance'
  },
  connected: {
    'en-EN': 'Connected',
    'it-IT': 'Connesso'
  },
  notConnected: {
    'en-EN': 'Not Connected',
    'it-IT': 'Non Connesso '
  },
  personalInfo: {
    'en-EN': 'Personal Information',
    'it-IT': 'Informazioni personali'
  },
  nameAndSurname: {
    'en-EN': 'Name and Surname',
    'it-IT': 'Nome e Cognome'
  },
  kyc: {
    'en-EN': 'Identity Verification',
    'it-IT': 'Verifica identità'
  },
  kycText: {
    'en-EN': 'Complete the identity verification',
    'it-IT': "Effettua la verifica dell'identità"
  }
};

export const RecentActivity = {
  title: {
    'en-EN': 'Recent Activity',
    'it-IT': 'Attività Recenti'
  },
  noActivities: {
    'en-EN': 'You have no recent activity yet!',
    'it-IT': 'Non hai ancora attività recenti!'
  },
  years: {
    'en-EN': ' years ago',
    'it-IT': ' anni fa'
  },
  months: {
    'en-EN': ' months ago',
    'it-IT': ' mesi fa'
  },
  days: {
    'en-EN': ' days ago',
    'it-IT': ' giorni fa'
  },
  hours: {
    'en-EN': ' hours ago',
    'it-IT': ' ore fa'
  },
  minutes: {
    'en-EN': ' minutes ago',
    'it-IT': ' minuti fa'
  },
  seconds: {
    'en-EN': ' seconds ago',
    'it-IT': ' secondi fa'
  },
  buy: {
    'en-EN': 'Buy',
    'it-IT': 'Comprati'
  },
  sell: {
    'en-EN': 'Sell',
    'it-IT': 'Venduti'
  }
};

export const referredFriends = {
  noUsers: {
    'en-EN': 'You have no invited friend!',
    'it-IT': 'Non hai ancora invitato amici!'
  },
  inviteAFriend: {
    'en-EN': 'Invite a new friend!',
    'it-IT': 'Invita un nuovo amico!'
  },
  title: {
    'en-EN': 'Invited friend',
    'it-IT': 'Amici invitati'
  },
  years: {
    'en-EN': ' years ago',
    'it-IT': ' anni fa'
  },
  months: {
    'en-EN': ' months ago',
    'it-IT': ' mesi fa'
  },
  days: {
    'en-EN': ' days ago',
    'it-IT': ' giorni fa'
  },
  hours: {
    'en-EN': ' hours ago',
    'it-IT': ' ore fa'
  },
  minutes: {
    'en-EN': ' minutes ago',
    'it-IT': ' minuti fa'
  },
  seconds: {
    'en-EN': ' seconds ago',
    'it-IT': ' secondi fa'
  },
  completed: {
    'en-EN': 'Completed',
    'it-IT': 'Completato'
  },
  inProgress: {
    'en-EN': 'In progress',
    'it-IT': 'In corso'
  }
};

export const RegistrationForm = {
  email: {
    'en-EN': 'Email address',
    'it-IT': 'Indirizzo Email'
  },
  name: {
    'en-EN': 'Name',
    'it-IT': 'Nome'
  },
  optional: {
    'en-EN': 'Optional',
    'it-IT': 'Opzionale'
  },
  confirm: {
    'en-EN': 'Enter',
    'it-IT': 'Registrati'
  },
  thanks: {
    'en-EN': 'Thanks',
    'it-IT': 'Grazie'
  },
  join: {
    'en-EN': 'for joining Hodlie!',
    'it-IT': 'per esserti unito a Hodlie'
  },
  joinWaiting: {
    'en-EN': 'for joining Hodlie Waiting List!',
    'it-IT': "per esserti unito alla Lista d'attesa di Hodlie"
  },
  soon: {
    'en-EN':
      'You will soon receive an email with all the necessary information to confirm the email address.',
    'it-IT':
      "A breve riceverai una email con tutte le informazioni necessarie per confermare l'indirizzo mail."
  },
  not: {
    'en-EN': "If you can't find the email, check your spam.",
    'it-IT': "Se non riesci a trovare l'email, controlla nello spam."
  },
  now: {
    'en-EN': 'Now you can already enter the platform',
    'it-IT': 'Adesso puoi già entrare nella piattaforma'
  },
  only: {
    'en-EN': 'Name can only contain letters, numbers and space',
    'it-IT': 'Il nome può contenere solo lettere, numeri e spazi'
  },
  mailFormat: {
    'en-EN': 'Mail is not in the correct format',
    'it-IT': "L'email non è nel formato corretto"
  },
  usernameFormat: {
    'en-EN':
      'Username must contain at least 6 characters or numbers (no spaces).',
    'it-IT':
      "L'username deve contenere almeno 6 caratteri o numeri (senza spazi)"
  },
  referral: {
    'en-EN': 'Referral code is not in the corret format',
    'it-IT': 'Il referral code non è nel formato corretto'
  },
  accept: {
    'en-EN':
      'I have read and accepted the <a href="/termsandconditions" target="_blank" rel="noreferrer">Terms & Conditions</a>',
    'it-IT':
      'Ho letto e accettato i <a href="/termsandconditions" target="_blank" rel="noreferrer">Termini e Condizioni</a>'
  },
  mktAccept: {
    'en-EN': 'I agree to receive updates, news, and communications from Hodlie',
    'it-IT':
      'Accetto di ricevere aggiornamenti, novità e comunicazioni da Hodlie'
  },
  title: {
    'en-EN': 'Signup',
    'it-IT': 'Registrazione'
  },
  titleReg: {
    'en-EN': 'Signed up!',
    'it-IT': 'Registrato!'
  },
  insert: {
    'en-EN': 'added!',
    'it-IT': 'aggiunto!'
  },
  enter: {
    'en-EN': 'Start',
    'it-IT': 'Entra'
  },
  googleSignup: {
    'en-EN': 'Signup with Google',
    'it-IT': 'Registrati con Google'
  },
  continueWithMail: {
    'en-EN': 'Continue with mail address',
    'it-IT': 'Continua con indirizzo mail'
  },
  or: {
    'en-EN': 'or',
    'it-IT': 'oppure'
  },
  googleButtonText: {
    'en-EN': 'Sign in with Google',
    'it-IT': 'Accedi con Google'
  }
};

export const RemoveKeys = {
  title: {
    'en-EN': 'Are you sure you want to delete the API key?',
    'it-IT': 'Sei sicuro di voler eliminare la chiave API?'
  },
  body: {
    'en-EN':
      'If you decide to unplug it, all your active strategies will stop operating and you will no longer be able to recover them.',
    'it-IT':
      'Se decidi di scollegarlo, tutte le tue strategie attive smetteranno di operare e non potrai più recuperarle.'
  },
  wantProcede: {
    'en-EN': 'Do you want to proceed?',
    'it-IT': 'Vuoi procedere?'
  },
  toProcede: {
    'en-EN':
      "To proceed you need to delete the following active strategies. Click the bot's icon or name to check its details",
    'it-IT':
      "Per procedere è necessario eliminare le seguenti strategie. Clicca sull'icona o sul nome del bot per controllarne i dettagli"
  },
  confirm: {
    'en-EN': 'Confirm',
    'it-IT': 'Conferma'
  },
  checkTitle: {
    'en-EN': 'Wallet has been successfully disconnected',
    'it-IT': 'Il wallet è stato correttamente disconnesso'
  },
  close: {
    'en-EN': 'Close',
    'it-IT': 'Chiudi'
  }
};

export const Rewards = {
  betaTesterDescription: {
    'en-EN': "You're already a Beta Tester!",
    'it-IT': 'Sei già un Beta Tester!'
  },
  betaTesterStatus: {
    'en-EN': 'Status: Completed',
    'it-IT': 'Stato: Completato'
  },
  earn: {
    'en-EN': 'Earn',
    'it-IT': 'Guadagna'
  },
  dailyRewardTitle: {
    'en-EN': 'Daily Bonus',
    'it-IT': 'Bonus Giornaliero'
  },
  dailyRewardDescription: {
    'en-EN': 'Every 24h you can get your Daily Bonus by logging into Hodlie',
    'it-IT':
      'Ogni 24 ore puoi ottenere il tuo Bonus Giornaliero accedendo a Hodlie'
  },
  getIt: {
    'en-EN': 'Get it!',
    'it-IT': 'Ottieni!'
  },
  notAvailable: {
    'en-EN': 'Not available',
    'it-IT': 'Non disponibile'
  },
  inviteAFriend: {
    'en-EN': 'Invite a friend',
    'it-IT': 'Invita un amico'
  },
  rateHodlie: {
    'en-EN': 'Rate Hodlie',
    'it-IT': 'Valuta Hodlie'
  },
  copy: {
    'en-EN': 'Copy to clipboard',
    'it-IT': 'Copia negli appunti'
  },
  copied: {
    'en-EN': 'Copied!',
    'it-IT': 'Copiato!'
  },
  shareWithFriend: {
    'en-EN': 'Share with friend',
    'it-IT': 'Condividi con gli amici'
  },
  shareReferral: {
    'en-EN':
      'Share your referral code to your friends: you will earn 50 HODS and he 25!',
    'it-IT':
      'Condividi il tuo referral code con i tuoi amici: riceverai 50 HODS e lui 25!'
  },
  ending: {
    'en-EN':
      '* to get the reward, the invited user must have connected their Binance wallet and run an AI bot',
    'it-IT':
      "* per usufruire del premio, l'utente invitato deve aver collegato il proprio wallet di Binance e far partire un bot di AI"
  },
  tooltipText1: {
    'en-EN': 'Referred <font color=' + blueColor + '><b>friends</b></font>',
    'it-IT': '<font color=' + blueColor + '><b>Amici</b></font> affiliati'
  },
  tooltipText2: {
    'en-EN': 'friend out of',
    'it-IT': 'amici su'
  },
  faqRewards: [
    {
      question: {
        'en-EN': 'What are HODS? What do I need them for?',
        'it-IT': 'Cosa sono gli HODS? Per cosa mi servono?'
      },
      answer: {
        'en-EN':
          'HODS are <b>credits</b> you can earn on Hodlie by achieving certain goals. The more HODS you earn, the more benefits and features you can take advantage of within the platform. Our goal is to reward the <b>most active users</b> who bring the most value to Hodlie!',
        'it-IT':
          'Gli HODS sono <b>crediti</b> che puoi accumulare su Hodlie raggiungendo determinati obiettivi. Più HODS guadagni, più vantaggi e funzionalità potrai sfruttare all’interno della piattaforma. Il nostro scopo è quello di premiare i nostri <b>utenti più attivi</b>, e che portano maggiore valore a Hodlie!'
      },
      active: true
    },
    {
      question: {
        'en-EN':
          'Are you an influencer? Do you want to collaborate with Hodlie?',
        'it-IT': 'Sei un influencer? Vuoi collaborare con Hodlie?'
      },
      answer: {
        'en-EN':
          'If you would like to propose a collaboration, write to us at <a href="mailto:' +
          email +
          '">' +
          email +
          '</a>: our team is ready to evaluate your ideas!',
        'it-IT':
          'Se vuoi proporci una collaborazione, scrivici a <a href="mailto:' +
          email +
          '">' +
          email +
          '</a>: il nostro team è pronto a valutare le tue idee!'
      },
      active: true
    },
    {
      question: {
        'en-EN': 'I want more, what can I do that?',
        'it-IT': 'Voglio di più, cosa posso fare?'
      },
      answer: {
        'en-EN':
          'Become an <b>Ambassador</b> and get Hodlie for free! Invite as many crypto enthusiast friends as you can: if at least 5 of them connect their wallet and start at least one AI bot, you can request Ambassador status recognition by emailing us at <a href="mailto:ambassador@hodlie.net">ambassador@hodlie.net</a>.<br>As such, you will be entitled to a Welcome pack with our merchandise; you will be able to use Hodlie for <b>free</b>; you will unlock all the features of the platform; and you will get access to our private group, where you can learn about new investment models in advance.',
        'it-IT':
          'Diventa un <b>Ambassador</b> e ottieni Hodlie gratis! Invita quanti più amici appassionati di crypto possibile: se almeno 5 di loro collegheranno il loro wallet e avvieranno almeno un bot di AI potrai richiedere il riconoscimento dello status di Ambassador tramite email a <a href="mailto:ambassador@hodlie.net">ambassador@hodlie.net</a>, al seguito della quale seguirà una interview con il team.<br>In quanto tale, avrai diritto a un Welcome pack con il nostro merchandising; potrai utilizzare Hodlie <b>gratuitamente</b>; sbloccherai tutte le funzionalità della piattaforma e avrai accesso al nostro gruppo privato, dove potrai conoscere in anticipo i nuovi modelli di investimento.'
      },
      active: true
    }
  ],
  affiliationTitle: {
    'en-EN': 'Referral Program',
    'it-IT': 'Referral Program'
  },
  firstCardText: {
    'en-EN': `<b>For every friend who signs up using your Referral Code, you will receive <span class='fs-2 text-success'>50 USDC</span> directly in your Binance wallet.<br>
And that's not all: your friend will earn <span class='fs-2 text-success'>25 USDC</span>!<b>`,
    'it-IT': `<b>Per ogni amico che si iscrive tramite il tuo Referral Code, riceverai <span class='fs-2 text-success'>50 USDC</span> direttamente sul tuo portafoglio Binance.<br>
E non è tutto: il tuo amico guadagnerà <span class='fs-2 text-success'>25 USDC</span>!<b>`
  },
  firstCardText1: {
    'en-EN': "Here's how it works:",
    'it-IT': 'Ecco come funziona:'
  },
  firstCardLi0bold: {
    'en-EN': 'Share your Referral code with your friends: ',
    'it-IT': 'Condividi il tuo Referral code con i tuoi amici: '
  },
  firstCardLi0: {
    'en-EN': 'You can find it below!',
    'it-IT': 'Lo trovi qui sotto!'
  },
  firstCardLi1bold: {
    'en-EN': 'Follow your progress on the progress bar: ',
    'it-IT': 'Segui i tuoi progressi sulla barra di caricamento: '
  },
  firstCardLi1: {
    'en-EN':
      'Each invited friend, once one of the plans has been started and the first payment has been made, will complete a step within your progression bar.',
    'it-IT':
      'Ogni amico invitato, una volta avviato uno dei piani ed effettuato il primo pagamento, completerà uno step all’interno della tua barra di progressione.'
  },
  firstCardFooter: {
    'en-EN': 'But it does not end here: ',
    'it-IT': 'Ma non è finita qui: '
  },
  firstCardFooterBold: {
    'en-EN':
      'each invited friend will get an immediate 10% discount on the first start of a Explorer or Investor subscription, available automatically during checkout (not combinable with other discounts or promotions)',
    'it-IT':
      'ogni amico invitato otterrà uno sconto immediato del 10% sul primo avvio di un abbonamento Explorer o Investor, disponibile automaticamente in fase di checkout (non cumulabile con altri sconti o promozioni)'
  },
  getDeep: {
    'en-EN': 'Get the Deep Scalper',
    'it-IT': 'Ottieni il Deep Scalper'
  },
  invite: {
    'en-EN': 'Invite',
    'it-IT': 'Invita'
  },
  friends: {
    'en-EN': 'Friends invited via referral',
    'it-IT': 'Amici invitati tramite referral'
  },
  userProgress: {
    'en-EN': 'Your Progress',
    'it-IT': 'I tuoi Progressi'
  },
  becomeTrader: {
    'en-EN': 'Become Private!',
    'it-IT': 'Diventa Private!'
  },
  becomeTrader1: {
    'en-EN': 'Click the button to get more information',
    'it-IT': 'Clicca sul pulsante per ottenere maggiori informazioni'
  },
  becomeTraderButton: {
    'en-EN': 'More',
    'it-IT': 'Scopri di più'
  },
  followProcedure: {
    'en-EN': 'Follow this procedure:',
    'it-IT': 'Segui questo procedimento:'
  },
  soon: {
    'en-EN': 'Do you want to test the Deep Scalper now?',
    'it-IT': 'Vuoi testare subito il Deep Scalper?'
  },
  fillOut: {
    'en-EN': 'Fill out this ',
    'it-IT': 'Compila questo '
  },
  form: {
    'en-EN': 'form',
    'it-IT': 'questionario'
  },
  getInTouch: {
    'en-EN': "Join a call with us, let's get to know each other better!",
    'it-IT': 'Partecipa a una call con noi, conosciamoci meglio!'
  },
  step3_0: {
    'en-EN': "Don't waste a second, upgrade to ",
    'it-IT': 'Non perdere nemmeno un secondo, passa a '
  },
  step3_1: {
    'en-EN': ' and launch your first Deep Scalper now!',
    'it-IT': ' e lancia il tuo primo Deep Scalper!'
  },
  wantMore: {
    'en-EN': 'Want more? Become an Ambassador and enjoy huge benefits!',
    'it-IT': 'Vuoi di più? Diventa un Ambassador e accedi a enormi vantaggi!'
  },
  lastParTitle: {
    'en-EN': 'Program Specifications',
    'it-IT': 'Specifiche del programma'
  },
  p1Title: {
    'en-EN': 'Discounts and Promotions:',
    'it-IT': 'Sconti e Promozioni:'
  },
  p1_1: {
    'en-EN':
      "You will get a 10% discount by signing up with a friend's Referral Code.",
    'it-IT':
      'Otterrai uno sconto del 10% iscrivendoti con il Referral Code di un amico.'
  },
  p1_3: {
    'en-EN':
      'The discount is automatically applied at checkout and cannot be combined with other offers or promotions.',
    'it-IT':
      'Lo sconto è applicato automaticamente al momento del checkout e non è cumulabile con altre offerte o promozioni.'
  },
  p2Title: {
    'en-EN': 'Progress:',
    'it-IT': 'Avanzamento:'
  },
  p2_1: {
    'en-EN':
      'The advancement of a box occurs only when the invited friend registers with your Referral Code, starts a subscription between Explorer or Investor (monthly or annual), and makes the first payment after the 7 day trial.',
    'it-IT':
      "L’avanzamento di una casella avviene solo quando l'amico invitato si iscrive con il tuo Referral Code, avvia un abbonamento tra Explorer o Investor (mensile o annuale), ed effettua il primo pagamento dopo i 7 giorni di prova."
  },
  p2_2: {
    'en-EN':
      'If you join Hodlie via Referral Code, you count as 1 completed step in your progression bar.',
    'it-IT':
      'Se ti unisci a Hodlie tramite Referral Code, vali come 1 step completato nella tua barra di progressione.'
  },
  p2_3: {
    'en-EN':
      'The progression is retroactive, so friends already invited via your Referral Code will be counted if the steps have already been completed.',
    'it-IT':
      'La progressione è retroattiva, quindi gli amici già invitati tramite il tuo Referral Code verranno conteggiati se gli step sono già stati completati.'
  },
  p4Title: {
    'en-EN': 'Redemption of Discounts and Rewards:',
    'it-IT': 'Riscatto degli Sconti e Premi:'
  },
  p4_1: {
    'en-EN':
      'If you already have an active subscription at the time of the invitation, the related discount can be redeemed and used for the next renewal.',
    'it-IT':
      "Se hai già un abbonamento attivo al momento dell'invito, il relativo sconto potrà essere riscattato e utilizzato per il rinnovo successivo."
  },
  p4_2: {
    'en-EN':
      'You decide when and which DISCOUNT to redeem for the next start or renewal of your subscription. If you redeem a DISCOUNT, you will no longer be able to redeem the following ones.',
    'it-IT':
      'Decidi tu quando e quale SCONTO riscattare per l’avvio o il rinnovo successivo del tuo abbonamento. Se riscatti uno SCONTO, non potrai più riscattare quelli successivi.'
  },
  p4_3: {
    'en-EN':
      'If you reach the GIFTS goal, you can also get the following ones (e.g. if you receive the t-shirt at step 4, you can also receive the sweatshirt at step 7).',
    'it-IT':
      "Se raggiungi l'obiettivo dei REGALI, potrai invece ottenere anche quelli successivi (es. se ricevi la t-shirt allo step 4, potrai ricevere anche la felpa allo step 7)."
  },
  p4_4: {
    'en-EN':
      'Once the GIFTS goals have been reached, we will contact you to agree on the shipping address and the size of the merchandise.',
    'it-IT':
      "Una volta raggiunti gli obiettivi REGALO, ti contatteremo per accordarci sull'indirizzo di spedizione e la taglia del merchandising."
  },
  lastCardTextHtml: {
    'en-EN': `
    <h5>
  Here's how it works:</h5>
  <p className="pt-3">
    <ol>
      <li>Share your Referral Code with friends. You can find the code nearby!</li>
      <li>Invite your friends to sign up and start an annual subscription.
      </li>
      <li>In return, you will receive 50 USDC in your Binance wallet, and your friend will receive 25 USDC as a reward!
      </li>
    </ol>
    Once you receive your USDC, you can use it directly on Hodlie to boost your investments. The more you invite, the more you earn!
    <br>
    <br>
    <b>Don't miss the opportunity: invite now and start earning with your friends!</b>
  </p>`,
    'it-IT': `
    <h5>
  Ecco come funziona:</h5>
  <p className="pt-3">
    <ol>
      <li>Condividi il tuo Referral Code con gli amici. Trovi il codice qui di fianco!</li>
      <li>Invita i tuoi amici ad iscriversi e avviare un <b>abbonamento annuale</b>.
      </li>
      <li>In cambio riceverai 50 USDC sul tuo wallet Binance e il tuo amico riceverà 25 USDC come ricompensa!
      </li>
    </ol>
    Una volta ottenuti i tuoi USDC, potrai usarli direttamente su Hodlie per potenziare i tuoi investimenti. Più inviti, più guadagni!
    <br>
    <br>
    <b>Non perdere l’occasione: invita ora e inizia a guadagnare con i tuoi amici!</b>
  </p>`
  },
  free: {
    'en-EN': ' in free trial',
    'it-IT': ' in prova gratuita'
  },
  redeem: {
    'en-EN': 'Redeem Discount',
    'it-IT': 'Riscatta Sconto'
  },
  confirm: {
    'en-EN': 'Confirm',
    'it-IT': 'Conferma'
  },
  modalHead: {
    'en-EN': 'Redeem your discount',
    'it-IT': 'Riscatta il tuo Sconto'
  },
  modalBody: {
    'en-EN': 'You have been dedicated a discount of',
    'it-IT': 'Ti è stato dedicato uno sconto del'
  },
  modalBody1: {
    'en-EN': 'on your next payment of a Explorer or Investor subscription.',
    'it-IT': 'sul tuo prossimo pagamento di un abbonamento Explorer o Investor.'
  },
  modalBody2: {
    'en-EN': 'Click below to redeem it!',
    'it-IT': 'Clicca qui sotto per riscattarlo!'
  },
  modalHeadRedeem: {
    'en-EN': 'Discount redeemed!',
    'it-IT': 'Regalo riscattato!'
  },
  close: {
    'en-EN': 'Close',
    'it-IT': 'Chiudi'
  },
  chartTitle: {
    'en-EN': 'Subscribed friends',
    'it-IT': 'Amici abbonati'
  },
  noFriends: {
    'en-EN': "You haven't invited any friends yet.",
    'it-IT': 'Non hai ancora invitato nessun amico.'
  },
  terms: {
    'en-EN': `
  <p>
    The Referral Program is not retroactive.
    <br />
    To participate and earn the 50 USDC, you must have an active
    annual Explorer, Investor, or Private subscription.
    <br />The 50 USDC will be credited only after your friend
    confirms the
    <b>annual subscription</b> at the end of the free trial.
    <br />
    <br /> Valid for users who sign up through referral from October 9 to December 31, 2024 and who purchase the annual subscription by 31 January 2025.
  </p>`,
    'it-IT': `
  <p>
    Il Referral Program non è retroattivo.
    <br />
    Per partecipare e guadagnare i 50 USDC, devi avere un
    abbonamento annuale Explorer, Investor o Private attivo.
    <br />I 50 USDC saranno accreditati solo dopo che il tuo amico
    avrà confermato
    <b> l'abbonamento annuale</b> al termine della prova gratuita.
    <br />
    <br /> Valido per gli utenti che si iscrivono tramite referral dal 9 ottobre al 31 Dicembre 2024 e che effettuano l'abbonamento annuale entro il 31 Gennaio 2025.
  </p>`
  },
  faqList: {
    'en-EN': `<ol>
  <li>
    <b>When will I receive my 50 USDC?</b>
    <br />
    You will receive the 50 USDC in your Binance wallet only when
    your friend confirms the annual subscription at the end of the
    7-day free trial.
  </li>
  <li>
    <b>
      What happens if my friend doesn't confirm the subscription
      after the free trial?
    </b>
    <br />
    In this case, neither you nor your friend will receive the
    USDC, as the annual subscription has not been confirmed.
  </li>
  <li>
    <b>Can I invite more friends and earn multiple times?</b>
    <br />
    Yes, you can invite as many friends as you want! For each
    friend who signs up and confirms the subscription, you will
    receive 50 USDC, while your friend will get 25.
  </li>
  <li>
    <b>Is there a limit to the number of friends I can invite?</b>
    <br />
    No, there is no limit! You can invite as many friends as you
    wish and earn USDC for each of them.
  </li>
  <li>
    <b>Can I participate if I only have a monthly subscription?</b>
    <br />
    No, to participate in the Referral Program and earn the 50
    USDC, you must have an active annual Explorer, Investor, or
    Private subscription.
  </li>
  <li>
    <b>
      What does it mean that the Referral Program is not
      retroactive?
    </b>
    <br />
    It means that you cannot receive the 50 USDC for friends who
    signed up before you started participating in the program.
  </li>
</ol>`,
    'it-IT': `<ol>
  <li>
    <b>Quando riceverò i miei 50 USDC?</b>
    <br />
    Riceverai i 50 USDC sul tuo wallet Binance solo quando il tuo
    amico confermerà l’abbonamento annuale, al termine dei 7
    giorni di prova gratuita.
  </li>
  <li>
    <b>
      Cosa succede se il mio amico non conferma l’abbonamento dopo
      la prova gratuita?
    </b>
    <br />
    In questo caso, né tu né il tuo amico riceverete i USDC,
    poiché l’abbonamento annuale non è stato confermato.
  </li>
  <li>
    <b>Posso invitare più amici e guadagnare più volte?</b>
    <br />
    Sì, puoi invitare tutti gli amici che vuoi! Per ogni amico che
    si iscrive e conferma l’abbonamento, riceverai 50 USDC, mentre
    il tuo amico ne otterrà 25.
  </li>
  <li>
    <b>C’è un limite al numero di amici che posso invitare?</b>
    <br />
    No, non c'è limite! Puoi invitare quanti amici desideri e
    guadagnare USDC per ciascuno di loro.
  </li>
  <li>
    <b>Posso partecipare se ho solo un abbonamento mensile?</b>
    <br />
    No, per poter partecipare al Referral Program e guadagnare i
    50 USDC, è necessario avere un abbonamento annuale Explorer,
    Investor o Private attivo.
  </li>
  <li>
    <b>
      Cosa significa che il Referral Program non è retroattivo?
    </b>
    <br />
    Significa che non puoi ricevere i 50 USDC per amici che si
    sono iscritti prima che tu iniziassi a partecipare al
    programma.
  </li>
</ol>`
  },
  date: {
    'en-EN': 'Registration',
    'it-IT': 'Registrazione'
  },
  sub: {
    'en-EN': 'Subscription',
    'it-IT': 'Abbonamento'
  },
  annual: {
    'en-EN': 'Annual',
    'it-IT': 'Annuale'
  },
  monthly: {
    'en-EN': 'Monthly',
    'it-IT': 'Mensile'
  },
  notValid: {
    'en-EN': 'Not valid',
    'it-IT': 'Non valido'
  },
  notValidInfo: {
    'en-EN':
      'The 50 USDT will be credited only after your friend confirms the annual subscription (not monthly)',
    'it-IT':
      "I 50 USDT saranno accreditati solo se il tuo amico avrà confermato l'abbonamento annuale (non mensile)"
  },
  notValidStart: {
    'en-EN': 'The user signed up before of 09/10/2024 o after 12/31/2024',
    'it-IT': "L'utente si è iscritto prima del 09/10/2024 o dopo il 31/12/2024"
  },
  processed: {
    'en-EN': 'Processed',
    'it-IT': 'Elaborato'
  },
  processing: {
    'en-EN': 'Processing',
    'it-IT': 'In elaborazione'
  },
  dashModalTitle: {
    'en-EN': 'earn <strong>50 USDC</strong>!',
    'it-IT': 'Guadagna <strong>50 USDC</strong>!'
  },
  dashModalText: {
    'en-EN': `<b>For every friend who signs up using your Referral Code and subscribes an annual subscription, you will receive <span class='fs-2 text-success'>50 USDC</span> directly into your Binance wallet!<br>
      And that's not all: your friend will earn <span class='fs-2 text-success'>25 USDC</span>!<b>`,
    'it-IT': `<b>Per ogni amico che si iscrive tramite il tuo Referral Code e attiva un abbonamento annuale, riceverai <span class='fs-2 text-success'>50 USDC</span> direttamente sul tuo portafoglio Binance!<br>
      E non è tutto: il tuo amico guadagnerà <span class='fs-2 text-success'>25 USDC</span>!<b>`
  },
  alertText: {
    'en-EN':
      'The promotion is valid for users who sign up through referral from October 9 to December 31, 2024 and who purchase the annual subscription by 31 January 2025.',
    'it-IT':
      "La promozione è valida per gli utenti che si iscrivono tramite referral dal 9 ottobre al 31 Dicembre 2024 e che effettuano l'abbonamento annuale entro il <b>31 Gennaio 2025.</b>"
  }
};

export const RewardsAmbassadorCard = {
  ambassadorTitle: {
    'en-EN': 'Be an <font color=' + goldColor + '>Ambassador</font>',
    'it-IT': 'Diventa un <font color=' + goldColor + '>Ambassador</font>'
  },
  title: {
    'en-EN': '<font color=' + goldColor + '>Referral</font> Program',
    'it-IT': 'Programma di <font color=' + goldColor + '>Affiliazione</font>'
  },
  tooltipText1: {
    'en-EN': 'Referred <font color=' + blueColor + '><b>friends</b></font>',
    'it-IT': '<font color=' + blueColor + '><b>Amici</b></font> affiliati'
  },
  tooltipText2: {
    'en-EN': 'friend out of',
    'it-IT': 'amici su'
  }
};

export const ShareReferralOnSocial = {
  shareUrl: 'https://' + platform + '/signup?ref=',
  shareTitle: {
    'en-EN': 'Join me on Hodlie Platform',
    'it-IT': 'Raggiungimi su Hodlie'
  },
  shareText: {
    'en-EN': 'Join Hodlie: the 1° AI Trading Platform in Italy!',
    'it-IT': 'Unisciti a Hodlie: la 1° AI Trading Platform in Italia!'
  }
};

export const ShareOnSocialModal = {
  shareOnSocial: {
    'en-EN': 'Share with Friends!',
    'it-IT': 'Condividi con gli Amici!'
  }
};

export const RewardsWalletCard = {
  amount: {
    'en-EN': 'Your amount of',
    'it-IT': 'Il totale dei tuoi'
  },
  amount1: {
    'en-EN': '<font color=' + goldColor + '>HODS</font> is',
    'it-IT': '<font color=' + goldColor + '>HODS</font> è'
  },
  gainCredits: {
    'en-EN': 'Collect HODS to unlock exclusive features',
    'it-IT': 'Accumula HODS per sbloccare funzionalità esclusive!'
  },
  findBelow: {
    'en-EN': 'Find out below how to earn more Hods',
    'it-IT': 'Scopri qui sotto come guadagnare più Hods'
  }
};

export const routes = {
  createBot: {
    'en-EN': 'Create Bot',
    'it-IT': 'Creazione Bot'
  },
  invest: {
    'en-EN': 'Invest',
    'it-IT': 'Investi'
  },
  usageGuide: {
    'en-EN': 'Tutorial',
    'it-IT': 'Guida Rapida'
  },
  virtualMode: {
    'en-EN': 'Virtual Mode',
    'it-IT': 'Modalità Virtuale'
  },
  createBotMobile: {
    'en-EN': 'Invest',
    'it-IT': 'Investi'
  },
  marketData: {
    'en-EN': 'Market Data',
    'it-IT': 'Dati di Mercato'
  },
  activities: {
    'en-EN': 'Activities',
    'it-IT': 'Attività'
  },
  account: {
    'en-EN': 'Account',
    'it-IT': 'Account'
  },
  rewards: {
    'en-EN': 'Rewards',
    'it-IT': 'Premi'
  },
  announcements: {
    'en-EN': 'Announcements',
    'it-IT': 'Annunci'
  },
  support: {
    'en-EN': 'Support',
    'it-IT': 'Supporto'
  },
  insights: {
    'en-EN': 'Insights',
    'it-IT': 'Insights'
  },
  new: {
    'en-EN': 'New',
    'it-IT': 'Nuovo'
  },
  goPro: {
    'en-EN': 'Go PRO',
    'it-IT': 'Passa a PRO'
  },
  goTrader: {
    'en-EN': 'Become Private',
    'it-IT': 'Diventa Private'
  },
  TC: {
    'en-EN': 'Terms And Conditions',
    'it-IT': 'Termini e Condizioni'
  },
  referralBadge: {
    'en-EN': 'Earn 50 USDT',
    'it-IT': 'Guadagna 50 USDT'
  },
  referralBadge_BLACKBTC: {
    'en-EN': 'Earn 100 USDC',
    'it-IT': 'Guadagna 100 USDC'
  }
};

export const SaasCard = {
  notAvailable: {
    'en-EN': 'NA',
    'it-IT': 'ND'
  },
  notAvailable1WeekPopOver: {
    'en-EN':
      'This value will be available after two weeks from the Agent start or capital increase',
    'it-IT':
      "Questo valore sarà disponibile dopo due settimane dall'avvio dell'Agent o dall'aumento di capitale"
  },
  notAvailablePopOver: {
    'en-EN': 'This data will be available soon',
    'it-IT': 'Prossimamente questo dato sarà disponibile'
  },
  title: {
    'en-EN': 'Agent Revenue',
    'it-IT': 'Profitto Agent'
  },
  sharpeGenericInfo: {
    'en-EN': "Indicates the portfolio's return in relation to risk",
    'it-IT':
      'indica il rendimento di un portafoglio in rapporto al rischio assunto'
  },
  volumeInfo: {
    'en-EN': 'Indicates the amount of capital moved during transactions',
    'it-IT': 'Indica la quantità di capitale spostato durante le transazioni'
  },
  profitInfo: {
    'en-EN': 'Indicates the general profit of the Agent',
    'it-IT': "Indica il profitto generale dell'Agent"
  },
  invest: {
    'en-EN': 'Total Invested',
    'it-IT': 'Totale Investito'
  },
  investInfo: {
    'en-EN': 'Indicates the total amount of capital invested in the Bot',
    'it-IT': 'Comprende il capitale iniziale e le eventuali immissioni'
  }
};

export const SelectBot = {
  ai: {
    'en-EN': 'Artificial Intellingence',
    'it-IT': 'Intelligenza Artificiale'
  },
  management: {
    'en-EN': 'management',
    'it-IT': 'management'
  },
  unlimited: {
    'en-EN': 'Unlimited',
    'it-IT': 'Illimitato'
  },
  upTo: {
    'en-EN': 'Up To ',
    'it-IT': 'Fino a '
  },
  onA: {
    'en-EN': 'on a ',
    'it-IT': 'con '
  },
  day: {
    'en-EN': 'day',
    'it-IT': 'giorno'
  },
  month: {
    'en-EN': 'month',
    'it-IT': 'mese'
  },
  unlockable: {
    'en-EN': 'Unlockable Bot',
    'it-IT': 'Bot Sbloccabile'
  },
  unlockableNotProSanta: {
    'en-EN':
      ' days until Deep Scalper Day: become PRO to start it on Christmas!',
    'it-IT': ' giorni al Deep Scalper Day: diventa PRO per avviarlo a Natale!'
  },
  unlockableNotProSantaIs: {
    'en-EN': ' is Deep Scalper Day: become PRO to start it on Christmas!',
    'it-IT': ' è il Deep Scalper Day: diventa PRO per avviarlo a Natale!'
  },
  unlockableProSanta: {
    'en-EN':
      ' days until Deep Scalper Day: come back to Christmas to unlock and start it!',
    'it-IT':
      ' giorni al Deep Scalper Day: torna a Natale per sbloccarlo ed avviarlo!'
  },
  unlockableProSantaIs: {
    'en-EN':
      ' is Deep Scalper Day: come back to Christmas to unlock and start it!',
    'it-IT':
      ' è il Deep Scalper Day: torna a Natale per sbloccarlo ed avviarlo!'
  },
  today: {
    'en-EN': 'Today',
    'it-IT': 'Oggi'
  },
  tomorrow: {
    'en-EN': 'Tomorrow',
    'it-IT': 'Domani'
  },
  unlocked: {
    'en-EN': 'Unlocked Bot',
    'it-IT': 'Bot Sbloccato'
  },
  howTo: {
    'en-EN': 'How to Unlock It?',
    'it-IT': 'Come sbloccarlo?'
  },
  goTo: {
    'en-EN': 'Go to the ',
    'it-IT': 'Vai alla pagina '
  },
  follow: {
    'en-EN': ' and become Private!',
    'it-IT': ' e diventa Private!'
  },
  or: {
    'en-EN': 'or',
    'it-IT': 'oppure'
  },
  virtual: {
    'en-EN': 'Start Virtual',
    'it-IT': 'Avvia Virtuale'
  },
  clickHere: {
    'en-EN': 'Click here to discover more',
    'it-IT': 'Clicca qui per scoprire di più'
  },
  new: {
    'en-EN': 'New',
    'it-IT': 'Nuovo'
  },
  mostLoved: {
    'en-EN': 'Most Loved',
    'it-IT': 'Il più scelto'
  },
  unavailableBot: {
    'en-EN': 'Unavailable',
    'it-IT': 'Non disponibile'
  },
  notAvailableDeep: {
    'en-EN': 'Unavailable',
    'it-IT': 'Attualmente non disponibile'
  },
  newSol: {
    'en-EN': ' SOL available now',
    'it-IT': ' SOL ora disponibile'
  }
};

export const Sentiment = {
  date: {
    'en-EN': 'date',
    'it-IT': 'data'
  },
  subtitle: {
    'en-EN': 'Real-time analysis of sentiment associated with news',
    'it-IT': 'Analisi in tempo reale del sentimento associato alle news'
  },
  sentiment: {
    'en-EN': 'Sentiment',
    'it-IT': 'Sentimento'
  },
  impact: {
    'en-EN': 'Relevance',
    'it-IT': 'Rilevanza'
  },
  generalScore: {
    'en-EN': 'General Score',
    'it-IT': 'Score Generale'
  },
  neutral: {
    'en-EN': 'Neutral',
    'it-IT': 'Neutro'
  },
  negative: {
    'en-EN': 'Negative',
    'it-IT': 'Negativo'
  },
  positive: {
    'en-EN': 'Positive',
    'it-IT': 'Positivo'
  },
  very: {
    'en-EN': 'Very',
    'it-IT': 'Molto'
  },
  scoreInfo: {
    'en-EN':
      'General indicator of news evaluation taken as the centroid of all points in the graph and given by the formula relevance (from 0 to 1)*sentiment (from -1 to 1)',
    'it-IT':
      'Indicatore generale di valutazione delle notizie preso come baricentro di tutti i punti presenti nel grafico e dato dalla formula rilevanza (da 0 a 1)*sentimento (da -1 a 1)'
  },
  scoreExplain: {
    'en-EN':
      'Indicator given by the formula relevance (from 0 to 1)*sentiment (from -1 to 1)',
    'it-IT':
      'Indicatore dato dalla formula rilevanza (da 0 a 1)*sentimento (da -1 a 1)'
  },
  sentimentInfo: {
    'en-EN': 'Counter of positive and negative sentiments related to news',
    'it-IT': 'Contatore di sentimenti positivi e negativi relativi alle notizie'
  },
  positiveArticles: {
    'en-EN': 'Positive Articles',
    'it-IT': 'Articoli Positivi'
  },
  negativeArticles: {
    'en-EN': 'Negative Articles',
    'it-IT': 'Articoli Negativi'
  },
  fearAndGreed: {
    'en-EN': 'Fear & Greed Index',
    'it-IT': 'Indice Fear & Greed'
  },
  fearInfo: {
    'en-EN':
      'Fear & Greed Index, calculated by weighing various parameters including: Volatility, Momentum, Social Media, Surveys, Bitcoin Dominance and Google Trends.',
    'it-IT':
      'Indice di paura e avidità, calcolato ponderando vari parametri tra cui: Volatility, Momentum, Social Media, Surveys, Bitcoin Dominance e Google Trends.'
  },
  source: {
    'en-EN': 'Source',
    'it-IT': 'Fonte'
  },
  close: {
    'en-EN': 'Close',
    'it-IT': 'Chiudi'
  },
  sourceBig: {
    'en-EN': 'Click here to read the article',
    'it-IT': "Clicca per leggere l'articolo"
  },
  multipleSources: {
    'en-EN': 'multiple sources',
    'it-IT': 'pubblicato da più fonti'
  },
  order: {
    'en-EN': 'order by:',
    'it-IT': 'ordina per:'
  },
  noData: {
    'en-EN': 'Currently there is no news available, please try again later!',
    'it-IT': 'Attualmente non è presente nessuna notizia, riprova più tardi!'
  },
  readLatestNews: {
    'en-EN': 'Read latest News',
    'it-IT': 'Leggi le ultime notizie'
  },
  onlySub: {
    'en-EN': 'Only for sub',
    'it-IT': 'Solo per abbonati'
  }
};

export const SimpleWallet = {
  walletInfo: {
    'en-EN': 'The displayed trend may not be up-to-date',
    'it-IT': "L'andamento visualizzato potrebbe non essere aggiornato"
  },
  real: {
    'en-EN': 'Real',
    'it-IT': 'Reale'
  },
  virtual: {
    'en-EN': 'Virtual',
    'it-IT': 'Virtuale'
  }
};

export const StaticRevenue = {
  botSimulation: {
    'en-EN': 'Agent Simulation',
    'it-IT': 'Simulazione Agent'
  },
  minWallet: {
    'en-EN': 'Minimum Wallet',
    'it-IT': 'Wallet Minimo'
  },
  maxWallet: {
    'en-EN': 'Maximum Wallet',
    'it-IT': 'Wallet Massimo'
  },
  nOperations: {
    'en-EN': 'Number of Operations',
    'it-IT': 'Numero di Operazioni'
  }
};

export const SuccessfullyDeleteBotModal = {
  text: {
    'en-EN': 'Your AI Agent has been successfully deleted!',
    'it-IT': 'Il tuo AI Agent è stato eliminato con successo!'
  },
  textPortfolio: {
    'en-EN': 'Your AI Portfolio has been successfully deleted!',
    'it-IT': 'Il tuo AI Portfolio è stato eliminato con successo!'
  },
  close: {
    'en-EN': 'Close',
    'it-IT': 'Chiudi'
  }
};

export const Support = {
  title: {
    'en-EN': 'Support',
    'it-IT': 'Supporto'
  },
  titleCard: {
    'en-EN': 'Do you need help? Ask our ChatBot!',
    'it-IT': 'Hai bisogno di aiuto? Chiedi al nostro ChatBot!'
  },
  titleCard3: {
    'en-EN': 'Need more assistance?',
    'it-IT': 'Serve ulteriore aiuto?'
  },
  text0: {
    'en-EN':
      "If you're having any issues or just need some support, don't hesitate to",
    'it-IT':
      'Se stai riscontrando qualche problema o hai bisogno di aiuto, non esitare a'
  },
  text1: {
    'en-EN': 'join our Telegram channel',
    'it-IT': 'unirti al nostro canale Telegram'
  },
  text2: {
    'en-EN':
      ". There's a good chance that other users have had the same problem and found a solution.",
    'it-IT':
      ". C'è una buona probabilità che altri utenti abbiano avuto il tuo stesso problema e che abbiano già trovato una soluzione."
  },
  text3: {
    'en-EN': 'You can also reach out to us directly to',
    'it-IT': "Puoi contattarci direttamente all'indirizzo"
  },
  text4: {
    'en-EN':
      'with the email used for registration. Our team will be happy to assist you',
    'it-IT':
      "con l'email utilizzata per la registrazione. Il nostro team sarà lieto di assisterti."
  },
  here: {
    'en-EN': 'here',
    'it-IT': 'qui'
  },
  text5: {
    USER: {
      'en-EN':
        'Need assistance performing any of the steps? You can book a call with our support team ',
      'it-IT':
        'Hai bisogno di assistenza per svolgere uno degli step? Puoi prenotare una chiamata con il nostro team di supporto '
    },
    PRIVATE: {
      'en-EN':
        'Otherwise, if you need direct assistance you can book a call with our team ',
      'it-IT':
        'Altrimenti, se necessiti di assistenza diretta puoi prenotare una chiamata con il nostro team '
    }
  }
};

export const SupportInfo = {
  whatsAppText: {
    'en-EN': 'Write to us on WhatsApp',
    'it-IT': 'Scrivici su WhatsApp'
  },
  mailText: {
    'en-EN': 'Send an email to support@hodlie.net',
    'it-IT': 'Invia una mail a support@hodlie.net'
  },
  videoCallText: {
    'en-EN': 'Check available slots and book a videocall',
    'it-IT': 'Consulta gli slot disponibili e prenota una videocall'
  },
  title: {
    'en-EN': 'Speak directly with the Hodlie team',
    'it-IT': 'Parla direttamente col team di Hodlie'
  },
  description: {
    'en-EN': 'Need support? We can help. Here is how you can contact us:',
    'it-IT':
      'Hai bisogno di supporto? Possiamo aiutarti. Ecco come contattarci:'
  }
};

export const SwitchRealModeCard = {
  realMode: {
    'en-EN': '<font color=' + goldColor + '>Real</font> Mode',
    'it-IT': 'Modalità <font color=' + goldColor + '>Reale</font>'
  },
  virtualMode: {
    'en-EN': '<font color=' + blueColor + '>Virtual</font> Mode',
    'it-IT': 'Modalità <font color=' + blueColor + '>Virtuale</font>'
  },
  realModeSimpleText: {
    'en-EN': 'Real Mode',
    'it-IT': 'Modalità Reale'
  },
  virtualModeSimpleText: {
    'en-EN': 'Virtual Mode',
    'it-IT': 'Modalità Virtuale'
  },
  goTo: {
    'en-EN': 'Go to',
    'it-IT': 'Passa a'
  }
};

export const ToDoList = {
  continue: {
    'en-EN': 'Continue',
    'it-IT': 'Continua'
  },
  yourProgress: {
    'en-EN': 'Your Progress',
    'it-IT': 'I tuoi Progressi'
  },
  mailTask: {
    'en-EN': 'Confirm your email address',
    'it-IT': 'Conferma il tuo indirizzo email'
  },
  mailDescr: {
    'en-EN':
      'To use your account on Hodlie, you have to confirm your email address',
    'it-IT':
      'Per utilizzare il tuo account su Hodlie, devi confermare il tuo indirizzo email'
  },
  kycTask: {
    'en-EN': 'Verify your identity',
    'it-IT': 'Verifica la tua identità'
  },
  kycDescr: {
    'en-EN':
      'Prepare your ID and mobile phone camera and start the KYC (Know Your Customer) identity verification process',
    'it-IT':
      "Prepara il tuo documento d'identità e la fotocamera del tuo cellulare e avvia la procedura di verifica della tua identità KYC (Know Your Customer)"
  },
  exchangeTask: {
    'en-EN': 'Connect your Wallet',
    'it-IT': 'Collega il tuo Wallet'
  },
  exchangeDescr: {
    'en-EN': `Connect your Binance wallet, this will allow you to operate safely and easily! Don't have a Binance wallet? You can create one <a href="https://accounts.binance.com/register" target="_blank">here</a>.`,
    'it-IT':
      'Collega il tuo portafoglio Binance, questo ti permetterà di operare in sicurezza e con facilità! Non possiedi un portafoglio Binance? Puoi crearlo <a href="https://accounts.binance.com/register" target="_blank">qui</a>.'
  },
  proTierTask: {
    'en-EN': 'Start a subscription',
    'it-IT': 'Avvia un abbonamento'
  },
  proTierDescr: {
    'en-EN':
      'Choose from our subscription tiers based on your investment goals.',
    'it-IT':
      'Scegli tra le nostre fasce di abbonamento in base ai tuoi obiettivi di investimento. '
  },
  realBotTask: {
    'en-EN': 'Start your AI strategy',
    'it-IT': 'Avvia la tua strategia di AI'
  },
  realBotDescr: {
    'en-EN': "Here we go: you're ready to launch your first AI strategy.",
    'it-IT': 'Ci siamo: sei pronto per avviare la tua prima strategia AI.'
  },
  lastStepTask: {
    'en-EN': 'Choose your strategy and start ',
    'it-IT': 'Scegli la tua strategia e avvia '
  },
  lastStepTaskTrialAllowed: {
    'en-EN': 'your 7-day trial',
    'it-IT': 'i 7 giorni di prova'
  },
  lastStepTaskTrialNOTAllowed: {
    'en-EN': 'a subscription',
    'it-IT': 'un abbonamento'
  },
  lastStepDescr: {
    'en-EN':
      'Choose from our AI strategies based on your investment goals and start a subscription',
    'it-IT':
      'Scegli tra le nostre strategie AI in base ai tuoi obiettivi di investimento e avvia un abbonamento'
  },
  lastStepDescrTrialAllowed: {
    'en-EN': ', the first 7 days are free!',
    'it-IT': ', i primi 7 giorni sono gratuiti!'
  },
  more: {
    'en-EN': 'show more',
    'it-IT': 'vedi tutto'
  },
  hide: {
    'en-EN': 'hide',
    'it-IT': 'nascondi'
  },
  next: {
    'en-EN': 'Next Step',
    'it-IT': 'Prossimo Step'
  },
  completed: {
    'en-EN': 'completed',
    'it-IT': 'completato'
  }
};

export const TopNavRightSideNavItem = {
  rewardsLink: {
    'en-EN': 'Go to Rewards',
    'it-IT': 'Vai ai Premi'
  },
  feedback: {
    'en-EN': 'Join us on telegram',
    'it-IT': 'Unisciti al canale telegram'
  },
  light: {
    'en-EN': 'Switch to light theme',
    'it-IT': 'Passa al tema chiaro'
  },
  dark: {
    'en-EN': 'Switch to dark theme',
    'it-IT': 'Passa al tema scuro'
  },
  download: {
    'en-EN': 'Download on your device',
    'it-IT': 'Scarica sul tuo dispositivo'
  },
  goPro: {
    'en-EN': 'Go PRO',
    'it-IT': 'Passa a PRO'
  },
  rewards: {
    'en-EN': 'Rewards',
    'it-IT': 'Premi'
  }
};

export const TopScorers = {
  filter: {
    'en-EN': 'Order',
    'it-IT': 'Ordina'
  },
  all: {
    'en-EN': 'All',
    'it-IT': 'Tutti'
  },
  interval: {
    'en-EN': 'Interval',
    'it-IT': 'Intervallo'
  },
  translations: {
    'it-IT': {
      seconds: 'pochi secondi fa',
      hour: "meno di un'ora fa",
      today: 'oggi',
      threeDays: 'tre giorni fa',
      twoWeeks: 'due settimane fa',
      oneMonth: 'un mese fa',
      moreThanOneMonth: 'più di un mese fa',
      threeMonths: 'tre mesi fa',
      moreThanThreeMonths: 'più di tre mesi fa',
      sixMonths: 'sei mesi fa',
      moreThanSixMonths: 'più di sei mesi fa',
      moreThanMonths: 'più di %s mesi fa',
      moreThanYear: 'più di un anno fa'
    },
    'en-EN': {
      seconds: 'a few seconds ago',
      hour: 'less than an hour ago',
      today: 'today',
      threeDays: 'three days ago',
      twoWeeks: 'two weeks ago',
      oneMonth: 'a month ago',
      moreThanOneMonth: 'more than a month ago',
      threeMonths: 'three months ago',
      moreThanThreeMonths: 'more than three months ago',
      sixMonths: 'six months ago',
      moreThanSixMonths: 'more than six months ago',
      moreThanMonths: 'more than %s months ago',
      moreThanYear: 'more than a year ago'
    }
  }
};

export const ToWalletWidget = {
  title: {
    'en-EN': 'Connect your wallet!',
    'it-IT': 'Connetti il tuo wallet!'
  },
  text: {
    'en-EN':
      'To start investing with our AI connect your Binance wallet via API.',
    'it-IT':
      'Per iniziare a investire con le nostre AI collega il tuo wallet Binance tramite API.'
  },
  learn: {
    'en-EN': 'Learn more',
    'it-IT': 'Scopri di più'
  }
};

export const TraderModal = {
  title: {
    'en-EN': 'Become Private?',
    'it-IT': 'Diventa Private!'
  },
  titleSendMail: {
    'en-EN': 'Email sent!',
    'it-IT': 'Mail inviata!'
  },
  text: {
    'en-EN':
      'You are about to enter the section dedicated to Hodlie Private, the exclusive investment experience dedicated to managing capital over $25K. By proceeding, you will be able to complete all the steps necessary to subscribe to this service. Click on <b>Continue</b>, you can stop at each step and contact us for any doubts or questions.',
    'it-IT': `Stai per entrare nella sezione dedicata a Hodlie Private, l’esclusiva esperienza d'investimento dedicata alla gestione di capitali superiori a $25K.
Proseguendo potrai completare tutti gli step necessari per la sottoscrizione di questo abbonamento.
Clicca su <b>Continua</b>, potrai fermarti a ciascuno step e contattarci per eventuali dubbi o domande.`
  },
  textMailSend: {
    'en-EN':
      'We have sent you an email. Please check your inbox to continue the procedure.',
    'it-IT':
      "Ti abbiamo inviato un'email. Controlla la tua casella di posta per continuare la procedura."
  },
  close: {
    'en-EN': 'Close',
    'it-IT': 'Chiudi'
  },
  continue: {
    'en-EN': 'Continue',
    'it-IT': 'Continua'
  }
};

export const TraderProcedure = {
  title: {
    'en-EN': 'Private',
    'it-IT': 'Private'
  },
  subtitle: {
    'en-EN': 'Complete the procedure to become a Private',
    'it-IT': 'Completa la procedura per diventare Private'
  },
  docuTitle: {
    'en-EN': 'Contract',
    'it-IT': 'Contratto'
  },
  docuText: {
    'en-EN':
      'You will receive the contract via email to sign and become a Private.',
    'it-IT': 'Riceverai via mail il contratto da firmare per diventare Private.'
  },
  docuText1: {
    'en-EN':
      'As soon as we have verified the documents, you will receive an email and you can proceed with the procedure in this page.',
    'it-IT':
      'Appena avremo verificato i documenti riceverai una email e potrai andare avanti con la procedura in questa pagina.'
  },
  payTitle: {
    'en-EN': 'Payment',
    'it-IT': 'Pagamento'
  },
  payText: {
    'en-EN': 'Make the payment to complete the procedure.',
    'it-IT': 'Effettua il pagamento per completare la procedura.'
  },
  payButton: {
    'en-EN': 'Go to checkout',
    'it-IT': 'Vai al checkout'
  },
  approvationTitle: {
    'en-EN': 'Approval',
    'it-IT': 'Approvazione'
  },
  approvationText: {
    'en-EN': 'We are creating a dedicated infrastructure for you.',
    'it-IT': "Stiamo creando un'infrastruttura a te dedicata."
  },
  approvationText1: {
    'en-EN':
      'Within 2 hours it will be complete and you will become a full-fledged Hodlie Private!',
    'it-IT':
      'Entro 2 ore sarà completa e diventerai un Hodlie Private a tutti gli effetti!'
  },
  doneTitle: {
    'en-EN': 'Done',
    'it-IT': 'Fatto'
  },
  doneText: {
    'en-EN': 'Thank you for your payment!',
    'it-IT': 'Ti ringraziamo per il tuo pagamento!'
  },
  doneText1: {
    'en-EN':
      'Our team will contact you in the coming days: the Private plan is designed for specific needs, and we want to make sure it is the right solution for you.',
    'it-IT':
      'Il nostro team ti contatterà nei prossimi giorni: il piano Private è pensato per esigenze specifiche, e vogliamo assicurarci che rappresenti la soluzione adatta per te.'
  },
  doneText2: {
    'en-EN': 'If you wish, you can already schedule a call with our team ',
    'it-IT': 'Se vuoi, puoi già prenotare una call con il nostro team '
  },
  here: {
    'en-EN': 'HERE',
    'it-IT': 'QUI'
  },
  pricesText: {
    'en-EN':
      'Below you can find the prices related to the Private subscription.',
    'it-IT': "Qui sotto puoi trovare i costi legati all'abbonamento Private."
  },
  sign: {
    'en-EN': 'View Private Contract',
    'it-IT': 'Visualizza Contratto Private'
  },
  textLinkContract: {
    'en-EN':
      'Click on the button below to view the Private Contract (non-binding)',
    'it-IT':
      'Clicca sul pulsante qui sotto per visualizzare il Contratto Private (senza impegno)'
  },
  traderInfrastructureText: {
    'en-EN': 'The fixed costs are ',
    'it-IT': 'I costi fissi sono di '
  },
  traderInfrastructureText1: {
    'en-EN':
      ' euros per year. The payment is made at the time of subscription and includes the use of dedicated servers for greater computational power and operational speed.',
    'it-IT':
      ' euro l’anno. Il pagamento viene effettuato al momento della sottoscrizione e include l’utilizzo dei server dedicati per una maggiore potenza computazionale e velocità operativa.'
  },
  managementFeeText: {
    'en-EN': 'Management fee is calculated as ',
    'it-IT': 'La commissione di gestione è calcolata come lo '
  },
  managementFeeText1: {
    'en-EN':
      '% of the average quarterly balance. It is free for the first quarter, and then calculated at the end of each three-month period.',
    'it-IT':
      '% della giacenza media trimestrale. Per il primo trimestre è gratuita, successivamente viene calcolata alla fine di ogni periodo di tre mesi.'
  },
  performanceFeeText: {
    'en-EN': 'The performance fee is ',
    'it-IT': 'La commissione di performance è del '
  },
  performanceFeeText1: {
    'en-EN':
      '% and is calculated on the increase in the value of the capital under management. The payment is quarterly.',
    'it-IT':
      '% e viene calcolata sull’incremento del controvalore del capitale in gestione. Il pagamento avviene trimestralmente.'
  },
  requestContractText: {
    'en-EN':
      'Click below to request the contract via email, once signed you can proceed with the payment of the infrastructure.',
    'it-IT':
      "Clicca qui sotto per richiedere il contratto via mail, una volta firmato potrai procedere con il pagamento dell'infrastruttura."
  },
  requestContractButton: {
    'en-EN': 'Request contract',
    'it-IT': 'Richiedi contratto'
  },
  contractModalHead: {
    'en-EN': 'Requested Contract',
    'it-IT': 'Contratto richiesto'
  },
  contractModalBody: {
    'en-EN':
      'Within 24 hours you will receive an email with the contract to sign.',
    'it-IT': 'Entro 24 ore riceverai una mail con il contratto da firmare.'
  },
  error: {
    'en-EN': 'There was an error, reload the page or contact us.',
    'it-IT': "C'è stato un errore ricarica la pagina o contattaci."
  },
  wallet: {
    'en-EN': 'Spot Wallet',
    'it-IT': 'Portafoglio Spot'
  },
  onExchangePre: {
    'en-EN': 'Available capital on the ',
    'it-IT': 'Capitale disponibile sul Wallet '
  },
  onExchangePost: {
    'en-EN': ' Wallet',
    'it-IT': ''
  },
  errorWallet: {
    'en-EN': 'Error',
    'it-IT': 'Errore'
  },
  connected: {
    'en-EN': 'Connected',
    'it-IT': 'Connesso'
  },
  notConnected: {
    'en-EN': 'Not Connected',
    'it-IT': 'Non Connesso '
  },
  errorText: {
    'en-EN':
      'Your API key is not valid, check the restrictions and if the error persists, create a new one',
    'it-IT':
      "La tua API key non è valida, controlla le restizioni e se l'errore persiste creane una nuova"
  },
  walletErrorTitle: {
    'en-EN': 'It looks like your Wallet has an error: your API Key is invalid',
    'it-IT':
      'Sembra che il tuo Wallet sia in errore: la tua Api-Key non è valida'
  },
  walletErrorText: {
    'en-EN':
      "We recommend that you delete your key and recreate it. Then link it to Hodlie. Need help? You can find our dedicated guide <a target='_blank' href='/connect-binance-wallet' style='text-decoration: underline'>here</a>!",
    'it-IT':
      "Ti consigliamo di eliminare la tua chiave e di ricrearla. Successivamente, collegala a Hodlie. Hai bisogno di aiuto? Trovi <a target='_blank' href='/connect-binance-wallet' style='text-decoration: underline'>qui</a> la nostra guida dedicata!"
  },
  noWalletTitle: {
    'en-EN':
      "It looks like you haven't connected your Binance Wallet to Hodlie yet",
    'it-IT':
      'Sembra che tu non abbia ancora collegato il tuo Wallet di Binance a Hodlie'
  },
  noWalletText: {
    'en-EN':
      "If you need help, you can find our dedicated guide <a target='_blank' href='/connect-binance-wallet' style='text-decoration: underline'>here</a>!",
    'it-IT':
      "Se hai bisogno di aiuto, trovi <a target='_blank' href='/connect-binance-wallet' style='text-decoration: underline'>qui</a> la nostra guida dedicata!"
  },
  under25Title: {
    'en-EN':
      'The Private plan is intended for managing assets of $25,000 or more',
    'it-IT':
      'Il piano Private è dedicato alla gestione di capitali pari o superiori a $25.000'
  },
  under25Text: {
    'en-EN':
      "Below you can find your current availability on Binance. If you need to make a deposit, you can follow our <a target='_blank' href='/deposit-binance' style='text-decoration: underline'>dedicated guide</a>.",
    'it-IT':
      "Di seguito trovi la tua disponibilità attuale su Binance. Se necessiti di effettuare un deposito, puoi seguire la nostra <a target='_blank' href='/deposit-binance' style='text-decoration: underline'>guida dedicata</a>."
  },
  sendMsg: {
    'en-EN': 'Send Message',
    'it-IT': 'Invia Messaggio'
  },
  titlePayment: {
    'en-EN': 'Need help?',
    'it-IT': 'Hai bisogno di aiuto?'
  },
  textPayment: {
    'en-EN': 'We are here to help you, contact us!',
    'it-IT': 'Siamo qui per aiutarti, contattaci!'
  },
  titleDocument: {
    'en-EN': 'Any questions? We are here for you!',
    'it-IT': 'Hai domande? Siamo qui per te!'
  },
  textDocument: {
    'en-EN':
      'If you have any doubts about the contract or want to understand if the Private plan reflects your investment objectives, our team is ready to answer all your questions. Do not hesitate to contact us!',
    'it-IT':
      'Se hai qualche dubbio sul contratto o vuoi capire se il piano Private rispecchia i tuoi obiettivi di investimento, il nostro team è pronto a rispondere a tutte le tue domande. Non esitare a contattarci!'
  },
  successfullEndSession: {
    'en-EN':
      'The document has been sent successfully! Within a few seconds you will be taken to the next step.',
    'it-IT':
      'Il documento è stato inviato con successo! Entro pochi secondi verrai portato al prossimo step.'
  },
  errorOccurred: {
    'en-EN': 'We encountered an error, please reload the page or contact us!',
    'it-IT': 'Abbiamo riscontrato un errore, ricarica la pagina o contattaci!'
  },
  mobileText: {
    'en-EN': 'Your document is ready to be signed.',
    'it-IT': 'Il tuo documento è pronto per essere firmato.'
  },
  mobileButton: {
    'en-EN': 'Document',
    'it-IT': 'Vai al documento'
  },
  waitingDocument: {
    'en-EN': 'The document is almost ready',
    'it-IT': 'Il documento è quasi pronto'
  },
  documentText: {
    'en-EN':
      'Below you will find the contract with all the details regarding the Private subscription. You can read it without obligation; if you decide to proceed with the subscription, scroll to the last page and click on <b>Sign</b>.',
    'it-IT':
      'Di seguito trovi il contratto con tutte le specifiche in merito all’abbonamento Private. Puoi leggerlo senza impegno; se decidi di procedere con la sottoscrizione, scorri fino all’ultima pagina e clicca su <b>Firma</b>.'
  }
};

export const TransactionInfoBotDetails = {
  date: {
    'en-EN': 'Date',
    'it-IT': 'Data'
  },
  order: {
    'en-EN': 'Order',
    'it-IT': 'Ordine'
  },
  amount: {
    'en-EN': 'Coin Quantity',
    'it-IT': 'Importo'
  },
  price: {
    'en-EN': 'Price',
    'it-IT': 'Prezzo'
  },
  fee: {
    'en-EN': 'Fee',
    'it-IT': 'Commissione'
  },
  noTrans: {
    'en-EN': 'Agent has not made any transactions yet',
    'it-IT': 'Il tuo Agent non ha ancora effettuato transazioni'
  }
};

export const Tutorials = {
  title: {
    'en-EN': 'Hodlie Tutorials',
    'it-IT': 'Ti spieghiamo Hodlie'
  },
  others: {
    'en-EN': 'See other tutorials',
    'it-IT': 'Vedi Altri Tutorial'
  },
  more: {
    'en-EN': 'show more',
    'it-IT': 'vedi di più'
  },
  hide: {
    'en-EN': 'hide',
    'it-IT': 'nascondi'
  }
};

export const UpToPro = {
  title: {
    'en-EN': 'Go Explorer',
    'it-IT': 'Diventa Explorer'
  },
  text: {
    'en-EN':
      'To access this feature you need to be a Explorer or Investor user<br>Complete all the steps to be able to start one',
    'it-IT':
      'Per visualizzare il contenuto della pagina devi avere un abbonamento Explorer o Investor<br>Completa tutti gli step per poterne avviare uno'
  },
  buttonText: {
    'en-EN': 'Dashboard',
    'it-IT': 'Dashboard'
  }
};

export const VideoCarousel = {
  title: {
    'en-EN': 'Start with',
    'it-IT': 'Per cominciare'
  },
  resume: {
    'en-EN': 'Resume',
    'it-IT': 'Riprendi'
  },
  alreadySeen: {
    'en-EN': 'Already Seen',
    'it-IT': 'Già Visto'
  },
  length: {
    'en-EN': 'Length',
    'it-IT': 'Durata'
  },
  kyc: {
    'en-EN': 'Why do we verify your identity?',
    'it-IT': 'Perchè verifichiamo la tua identità'
  },
  ai: {
    'en-EN': 'What is Hodlie AI and how does it work?',
    'it-IT': "Cos'è e come funziona l'AI di Hodlie?"
  },
  binance: {
    'en-EN': 'Why did we choose Binance?',
    'it-IT': 'Perchè abbiamo scelto Binance?'
  },
  portfolio: {
    'en-EN': 'What is the AI Portfolio and how does it work?',
    'it-IT': "Cos'è e come funziona l'AI Portfolio?"
  },
  hodlie: {
    'en-EN': 'Hodlie in 1 minute',
    'it-IT': 'Hodlie in 1 minuto'
  },
  un_anno: {
    'en-EN': 'Hodlie turns one year old: let’s revisit it together!',
    'it-IT': 'Hodlie compie un anno: ripercorriamolo insieme!'
  }
};

export const virtualAlert = {
  text: {
    'en-EN': `<span class="text-600 fs--2">Virtual Mode is an educational tool that allows investors to familiarize themselves with our strategies. Therefore, it does not fully replicate the real operation of the Agents on the Exchanges. <a href="/virtual-explain">DISCOVER MORE</a></span>`,
    'it-IT': `<span class="text-600 fs--2">La Modalità Virtuale va intesa come uno strumento formativo che permette agli investitori di familiarizzare con le nostre strategie. Pertanto, questo non riproduce pienamente l'operatività reale dei Agent sugli Exchange. <a href="/virtual-explain">SCOPRI DI PIÙ</a></span>`
  },
  title: {
    'en-EN': 'Differences between virtual and real trading',
    'it-IT': 'Differenze tra trading virtuale e trading reale'
  },
  description: {
    'en-EN': `
    This disclaimer attempts to address the major differences between trading conducted through Virtual Wallet on Hodlie, and the Real one conducted on the Exchange. 
    <br>It is, however, not all encompassing of all the possible differences between the two.
    <br>Therefore, the results of the Virtual Trading are not intended to be relied upon as a track record or validation of any trading strategies, and past performance is no guarantee of future performance or success.

    <br><br>Before engaging in any form of trading, it is crucial to understand and acknowledge the distinctions between paper trading (simulated or virtual trading) and real trading (actual financial transactions)

    <br><br>Below, we outline the reasons why the operation of Agents in Virtual Mode may not reflect the operation of their counterparts operating on Exchanges:
    <br><br>
    <b>Order Execution Time:</b>
    <br>
    <br>In the virtual environment (Virtual Mode), orders are executed instantly, as soon as the decision of making the order is taken. In the real environment (on the Exchange), instead, market conditions can vary, affecting order execution speed and the ability to execute trades at desired prices, especially during periods of high volatility.
    <br>Between the decision to place the order, the communication of the order to the Exchange and the effective execution, there could be a delay. 
    <br>This means that a real Agent may execute an order moments later than its virtual counterpart, or it may not execute it at all.
    <br><br>
    <b>Order Price:</b>
    <br>
    <br>In the Virtual environment, when a MARKET order is emitted, Agents take the instantaneous price and buy/sell at that given price, so two different orders that are executed at the same time will have the same price.
    <br>On the Exchange, two MARKET orders executed at the same time, could have different prices due to the market volatility,  and the disparity will depend on how frequently the price fluctuates at each moment.
    <br>
    <br>This means that a MARKET order placed by a Virtual Agent, may have a different price compared to its Real counterpart.
    <br><br>
    <b>Market Liquidity:</b>
    <br>
    <br>In the Virtual environment, when a LIMIT order is emitted, the Exchange (Binance) last minute candlestick is taken as reference. In the real environment, the market liquidity could not be enough to fill all the limit orders inside the order book at that price even if the price touches the limit order price. 
    <br>In the Real environment, the market liquidity could not be enough to fill all the limit orders inside the order book at that price, even if the price touches the limit order price. If multiple limit orders are placed at the same price, the Exchange will prioritize those that arrived first.
    <br>
    <br>This means that a limit order placed by a Real Agent may go unexecuted due to lack of liquidity, while its Virtual counterpart would be completed.
    <br><br>
    <b>Fees:</b>
    <br>
    <br>Commissions can significantly impact Real trading results and should be considered when transitioning from Virtual to Exchange trading. Hodlie gives its users the possibility to select the fees to apply to Virtual mode transactions, before starting Agent simulation (Users can choose from 0.025%, 0.05%, 0.075%, and 0.1%, based on the fees applied to their portfolio at the reference Exchange.)
    <br>It is essential that the Virtual Mode on Hodlie is used as a simulation tool, recognizing the distinctions mentioned above.
    <br>
    <br>In the end, past performance, whether actual or indicated by historical tests of strategies, is no guarantee of future performance or success. There is a possibility that you may sustain a loss equal to or greater than your entire investment regardless of which asset class you trade. Therefore, you should not risk money that you cannot afford to lose.
    `,
    'it-IT': `Questa pagina cerca di mettere in luce le principali differenze tra il Trading Virtuale effettuato da Hodlie e quello Reale effettuato sull' Exchange.
    <br><br>Tuttavia, non è esaustivo di tutte le possibili differenze tra i due. Pertanto, i risultati del trading effettuato in Modalità Virtual, non devono essere considerati come un track record o una validazione delle strategie di trading effettuato dai Agent di Hodlie su Exchange, e le performance passate non sono garanzia di guadagni futuri.
    <br>Prima di affidarsi a qualsiasi forma di trading automatico, è fondamentale comprendere e riconoscere le differenze tra il Paper trading (simulato o virtuale) e il Trading Reale (transazioni finanziarie effettive). 
    <br><br>Di seguito, riportiamo le ragioni per cui l'operatività dei Agent in Modalità Virtual, potrebbe non rispecchiare l'operatività dei corrispettivi Agent che operano su Exchange:
    <br><br>
    <b>Tempo di esecuzione degli ordini:</b>
    <br><br>In un ambiente simulato (Modalità Virtual), gli ordini vengono eseguiti istantaneamente, nello stesso momento in cui la decisione di effettuare l'ordine viene presa.
    <br>Nel trading su Exchange invece, le condizioni di mercato possono variare, influenzando la velocità di esecuzione degli ordini e la capacità di eseguire operazioni ai prezzi desiderati, specialmente durante periodi di elevata volatilità. 
    <br>Infatti, tra la decisione di effettuare l’ordine, la comunicazione dell'ordine all'Exchange e la sua effettiva esecuzione, possono passare diversi istanti. Questo comporta che un Agent reale potrebbe eseguire un ordine qualche istante dopo rispetto al corrispettivo Agent virtuale, o potrebbe non eseguirlo affatto.
    <br><br>
    <b>Prezzo di esecuzione degli ordini:</b>
    <br><br>Nell'ambiente Virtuale, quando viene effettuato un ordine a MERCATO, i Agent prendono il "prezzo istantaneo" e comprano/vendono a quel dato prezzo, quindi due ordini contemporanei verranno eseguiti allo stesso prezzo. Sull’Exchange invece, due ordini a MERCATO effettuati nello stesso istante, potrebbero avere un prezzo differente a causa delle fluttuazioni repentine di mercato, e la disparità dipenderà esclusivamente dalla frequenza con cui il prezzo oscilla ad ogni istante.
    <br>Questo comporta che un ordine a MERCATO effettuato da un Agent simulato, abbia un prezzo differente rispetto al corrispettivo Agent reale.
    <br><br>
    <b>Liquidità di Mercato:</b>
    <br><br>Altro discorso vale invece per gli ordini LIMITE, che implicano l'esecuzione a un prezzo specifico e vengono eseguiti quando il prezzo dell’asset tocca quello a cui i Agent vogliono vendere o comprare.
    <br>Infatti nell'ambiente Virtuale, quando viene effettuato un ordine LIMITE, viene usata come riferimento la candela dell'ultimo minuto. Se durante l'ultimo minuto il mercato tocca il prezzo di esecuzione dell'ordine limite, questo viene considerato come Eseguito.
    <br>Nell'ambiente Reale, la liquidità di mercato potrebbe non essere sufficiente ad eseguire l'ordine limite, anche se il prezzo prestabilito venisse raggiunto. Infatti, se più ordini limite vengono posizionati allo stesso prezzo, l'Exchange prioritizzerà i primi arrivati.
    <br>Questo comporta che un ordine limite di un Agent reale potrebbe risultare non eseguito per mancanza di liquidità, mentre il corrispettivo in modalità Virtuale verrebbe completato.
    <br><br>
    <b>Fees:</b>
    <br><br>
    Le commissioni possono influire significativamente sui risultati effettivi del trading e dovrebbero essere considerate durante la transizione dal trading Virtual a quello su Exchange. 
    <br>Hodlie permette ai suoi utenti di selezionare le commissioni da applicare alle transazioni in modalità Virtual prima di avviare la simulazione del Agent (a scelta tra 0.025%, 0.05%, 0.075% e 0.1%, in base alle fee applicate sul portafoglio dell’utente presso l’Exchange di riferimento)
    <br><br>È essenziale che la Modalità Virtual su Hodlie venga utilizzata come strumento di simulazione, riconoscendo le distinzioni sopra riportate.
    <br><br>In conclusione, le performance passate, che siano effettive o simulate, non garantiscono performance o successi futuri. Ogni investimento comporta la possibilità di subire una perdita pari o superiore all'intero investimento, indipendentemente dall’asset. 
    <br>Pertanto, ti ricordiamo sempre di non mettere a rischio capitale che non puoi permetterti di perdere. 
    `
  }
};

export const riskLevelExplain = {
  title: {
    'en-EN': 'Introduction to Risk Level',
    'it-IT': 'Introduzione al livello di rischio'
  },
  description: {
    'en-EN': `
    <p>The Risk Level is a fundamental parameter in the context of investment strategies, especially in a highly volatile market like cryptocurrencies. It represents an overall measure of the likelihood that an investment could incur significant losses. Risk assessment is essential to enable investors to make informed decisions, balancing potential returns with the acceptable risk level based on their risk profile. 
    In Hodlie, the Risk Level of the proposed investment strategies is determined through a meticulous analysis of various key aspects. This analysis is based on a structured model that takes into account a series of detailed criteria, divided into four main macro categories: Data & Infrastructure, Strategy & Operation Management, Asset Allocation, and Exchange.</p>
    <h5>Data & Infrastructure</h5><br>
    <p>The amount of data required for the execution of the strategy is a crucial element. 
    A strategy that relies on a large amount of data can benefit from better performance of the predictive model, thus reducing the risk of error. However, a greater amount of data also requires a robust infrastructure and adequate computational resources, which can entail operational complexity.</p>
    <p>The number of sources from which data is acquired is another important factor. A wide range of diversified data sources increases the robustness of the analysis, reducing the risk that errors or inaccuracies in a single source could negatively affect investment decisions. 
    This helps mitigate systematic risk related to data quality and reliability.</p>
    <p>The architecture of the model used to analyze the data is essential. Advanced and well-designed models can provide more accurate and reliable predictions. However, complex models can be more difficult to implement and maintain, requiring high technical skills and significant resources for continuous management and updating.</p>
    <h5>Strategy & Operation Management</h5><br>
    <p>The time horizon of the investment strategy significantly affects the risk profile. Short-term strategies can be highly volatile and sensitive to market fluctuations, while long-term strategies tend to be more stable but may require a longer time commitment to mature.</p>
    <p>The duration of orders and their division are additional key elements. Short-duration orders can incur higher transaction costs, increasing the risk of greater exposure to market volatility. Correct partitioning of orders can help distribute risk, optimizing transaction efficiency.</p>
    <p>The implementation of stop-loss mechanisms is a common practice to limit potential losses. Strategies that use well-calibrated stop losses can reduce the risk of large losses, providing an additional level of protection in adverse market conditions.</p>
    <p>The technological advancement of the strategy represents a competitive advantage. However, more innovative strategies, while offering potentially higher returns, may carry greater risks due to their relative novelty and lack of long-term testing.</p>
    <h5>Asset Allocation</h5>
    <p>The score assigned to the selected assets, based on <a href='https://weissratings.com/en/crypto/coins' target="_blank">Weiss Rating</a> evaluations, is an indicator of the quality and security of investments. Assets with high scores are generally considered safer and less volatile, helping to reduce the overall risk of the strategy.</p>
    <p>The average asset allocation, determined through historical simulations, is a crucial parameter. A good asset allocation allows for more efficient risk distribution, increasing the strategy’s “resistance” to market variations.</p>
    Historical Statistics:
    <ul>
    <br> 
    <li>Sharpe: a ratio that measures risk-adjusted investment performance. A higher Sharpe ratio indicates that the strategy offers better returns for each unit of risk taken.</li><br>
    <li>Average monthly returns: provide a measure of the consistent performance of the strategy. Positive and consistent returns are indicative of a solid and less risky strategy.</li><br>
    <li>Maximum drawdown: a crucial risk indicator, it is the maximum loss recorded by the investment during a specific period. A lower max drawdown indicates that the strategy is less susceptible to significant losses.</li><br>
    <li>BETA: measures the volatility of the strategy relative to Bitcoin. A lower beta suggests that the strategy is less volatile compared to the cryptocurrency market in general, reducing the risk of exposure to market fluctuations.</li><br>
    </ul>
    <h5>Exchange Score</h5>
    <p>The exchange score, based on <a href='https://www.coingecko.com/en/exchanges' target="_blank">Coingecko</a> and <a href='https://www.kaiko.com/products/rates-and-indices/exchange-ranking' target="_blank">Kaiko</a> evaluations, represents the security and reliability of the platforms on which operations are carried out. Exchanges with high scores are considered safer, reducing the risk of operational or security issues that could negatively affect investments.</p>
    <h5>Conclusion</h5>
    <p>The process of determining the Risk Level of strategies is complex and multifactorial. Through an in-depth and thoughtful analysis of various aspects, an overall score is assigned that accurately reflects the risk associated with each strategy. This systematic and rigorous approach enables investors to make informed decisions, appropriately balancing the desire for returns with the need for risk management.</p>
    `,
    'it-IT': `
    <p>Il Livello di Rischio è un parametro fondamentale nell'ambito delle strategie di investimento, soprattutto in un mercato altamente volatile come quello delle criptovalute. Esso rappresenta una misura complessiva della probabilità che un investimento possa subire perdite significative. La valutazione del rischio è essenziale per consentire agli investitori di prendere decisioni informate, bilanciando potenziali rendimenti con il livello di rischio accettabile in base al proprio profilo di rischio. 
    In Hodlie, il Livello di Rischio delle strategie di investimento proposte è determinato attraverso una meticolosa analisi di vari aspetti chiave. Questa analisi si basa su un modello strutturato che prende in considerazione una serie di criteri dettagliati, suddivisi in quattro macro categorie principali: Data & Infrastructure, Strategy & Operation Management, Asset Allocation e Exchange.</p>
    <h5>Data & Infrastructure</h5><br>
    <p>La quantità di dati richiesti per l'esecuzione della strategia rappresenta un elemento cruciale. 
    Una strategia che si basa su una grande mole di dati può beneficiare di migliori performance del modello previsionale, riducendo così il rischio di errore. Tuttavia, una maggiore quantità di dati richiede anche un'infrastruttura robusta e risorse computazionali adeguate, il che può comportare complessità operativa.</p>
    <p>Il numero di fonti da cui vengono acquisiti i dati è un altro fattore importante. Un ampio ventaglio di fonti di dati diversificate aumenta la robustezza dell'analisi, riducendo il rischio che errori o inesattezze in una singola fonte possano influenzare negativamente le decisioni di investimento. 
    Questo contribuisce a mitigare il rischio sistematico legato alla qualità e all'affidabilità dei dati.</p>
    <p>L'architettura del modello utilizzato per analizzare i dati è essenziale. Modelli avanzati e ben progettati possono fornire previsioni più accurate e affidabili. Tuttavia, modelli complessi possono essere più difficili da implementare e mantenere, richiedendo competenze tecniche elevate e risorse significative per la gestione e l'aggiornamento continuo.</p>
    <h5>Strategy & Operation Management</h5><br>
    <p>L'orizzonte temporale della strategia di investimento incide notevolmente sul profilo di rischio. Strategie a breve termine possono essere altamente volatili e sensibili alle fluttuazioni di mercato, mentre strategie a lungo termine tendono ad essere più stabili ma possono richiedere un maggiore impegno temporale per maturare.</p>
    <p>La durata degli ordini e la loro suddivisione rappresentano ulteriori elementi chiave. Ordini di breve durata possono incorrere in costi di transazione più elevati, aumentando il rischio di una maggiore esposizione alla volatilità di mercato. La corretta partizione degli ordini può aiutare a distribuire il rischio, ottimizzando l'efficienza delle transazioni.</p>
    <p>L'implementazione di meccanismi di stop loss è una pratica comune per limitare le perdite potenziali. Strategie che utilizzano stop loss ben calibrati possono ridurre il rischio di grandi perdite, fornendo un livello di protezione aggiuntivo in condizioni di mercato avverse.</p>
    <p>L'avanzamento tecnologico della strategia rappresenta un vantaggio competitivo. Tuttavia, le strategie più innovative, pur offrendo potenziali rendimenti superiori, possono comportare rischi maggiori a causa della loro relativa novità e della mancanza di test su lunghi periodi di tempo.</p>
    <h5>Asset Allocation</h5>
    <p>Il punteggio assegnato agli asset selezionati, basato sulle valutazioni di <a href='https://weissratings.com/en/crypto/coins' target="_blank">Weiss Rating</a>, è un indicatore della qualità e della sicurezza degli investimenti. Asset con punteggi elevati sono generalmente considerati più sicuri e meno volatili, contribuendo a ridurre il rischio complessivo della strategia.</p>
    <p>La media dell'allocazione degli asset, determinata attraverso simulazioni storiche, è un parametro cruciale. Una buona allocazione degli asset permette di distribuire il rischio in modo più efficiente, aumentando la “resistenza” della strategia alle variazioni di mercato.</p>
    Storico delle Statistiche:
    <ul>
    <br> 
    <li>Sharpe: rapporto che misura la performance dell'investimento corretta per il rischio. Un rapporto Sharpe più alto indica che la strategia offre un rendimento migliore per ogni unità di rischio assunta.</li><br>
    <li>Rendimenti medi mensili: forniscono una misura della performance costante della strategia. Rendimento positivo e costante è indicativo di una strategia solida e meno rischiosa.</li><br>
    <li>Perdita massima registrata: indicatore cruciale del rischio, si tratta della massima perdita registrata dall’investimento durante un periodo specifico. Un max drawdown inferiore indica che la strategia è meno suscettibile a perdite significative.</li><br>
    <li>BETA: misura la volatilità della strategia rispetto a Bitcoin. Un beta inferiore suggerisce che la strategia è meno volatile rispetto al mercato delle criptovalute in generale, riducendo il rischio di esposizione alle fluttuazioni di mercato.</li><br>
    </ul>
    <h5>Exchange Score</h5>
    <p>Il punteggio degli exchange, basato sulle valutazioni di <a href='https://www.coingecko.com/en/exchanges' target="_blank">Coingecko</a> e <a href='https://www.kaiko.com/products/rates-and-indices/exchange-ranking' target="_blank">Kaiko</a>, rappresenta la sicurezza e l'affidabilità delle piattaforme su cui vengono eseguite le operazioni. Exchange con punteggi elevati sono considerati più sicuri, riducendo il rischio di problemi operativi o di sicurezza che potrebbero influenzare negativamente gli investimenti.</p>
    <h5>Conclusione</h5>
    <p>Il processo di determinazione del Livello di Rischio delle strategie è complesso e multifattoriale. Attraverso un'analisi approfondita e ponderata di vari aspetti, viene assegnato un punteggio complessivo che riflette accuratamente il rischio associato a ciascuna strategia. Questo approccio sistematico e rigoroso permette agli investitori di prendere decisioni informate, bilanciando opportunamente il desiderio di rendimento con la necessità di gestione del rischio.</p>

    `
  },
  disclaimer: {
    'en-EN':
      'Investing in cryptocurrencies involves a very high level of risk. Cryptocurrencies are highly volatile and can experience significant price changes in short periods. The invested capital is at risk, and there is a possibility of losing a substantial part of it. Before undertaking any investment, it is crucial to fully understand the risks and carefully consider your level of tolerance for them. The "Risk Level" assessment provided by our platform is intended as an informative tool and should not be considered a guarantee of future results. It is strongly recommended to consult a qualified financial advisor before making investments in cryptocurrencies',
    'it-IT':
      'Investire in criptovalute comporta un altissimo livello di rischio. Le criptovalute sono altamente volatili e possono subire variazioni di prezzo significative in brevi periodi di tempo. Il capitale investito è a rischio, ed esiste la possibilità di perderne una parte sostanziale. Prima di intraprendere qualsiasi investimento, è fondamentale comprenderne appieno i rischi e considerare attentamente il proprio livello di tolleranza ad essi. La valutazione del "Livello di Rischio" fornita dalla nostra piattaforma è intesa come strumento informativo e non deve essere considerata come una garanzia di risultati futuri. Si raccomanda vivamente di consultare un consulente finanziario qualificato prima di effettuare investimenti in criptovalute.'
  }
};

export const VirtualMode = {
  monday: {
    'en-EN': 'Monday',
    'it-IT': 'Lunedì'
  },
  tuesday: {
    'en-EN': 'Tuesday',
    'it-IT': 'Martedì'
  },
  wednesday: {
    'en-EN': 'Wednesday',
    'it-IT': 'Mercoledì'
  },
  thursday: {
    'en-EN': 'Thursday',
    'it-IT': 'Giovedì'
  },
  friday: {
    'en-EN': 'Friday',
    'it-IT': 'Venerdì'
  },
  saturday: {
    'en-EN': 'Saturday',
    'it-IT': 'Sabato'
  },
  sunday: {
    'en-EN': 'Sunday',
    'it-IT': 'Domenica'
  },
  title: {
    'en-EN': 'Virtual Mode',
    'it-IT': 'Modalità Virtuale'
  },
  virtualMode: {
    'en-EN': 'Virtual Mode',
    'it-IT': 'Modalità Virtuale'
  },
  subtitle: {
    'en-EN': 'Configure and manage your wallet keys.',
    'it-IT': 'Configura e gestisci le chiavi del tuo portafoglio.'
  },
  add: {
    'en-EN': 'Add a AI Agent to your virtual wallet',
    'it-IT': 'Aggiungi un AI Agent al tuo wallet virtuale'
  },
  addPortfolio: {
    'en-EN': 'Add a AI Portfolio to your virtual wallet',
    'it-IT': 'Aggiungi un AI Portfolio al tuo wallet virtuale'
  },
  addAgents: {
    'en-EN': 'Add a AI Agent to your virtual wallet',
    'it-IT': 'Aggiungi un AI Agent al tuo wallet virtuale'
  },
  addText: {
    'en-EN':
      "You don't have any active strategy yet, try one now and add it to your virtual wallet.",
    'it-IT':
      'Non hai ancora nessun AI Agent attivo, provane subito una ed aggiungila al tuo wallet virtuale.'
  },
  addTextPortfolio: {
    'en-EN':
      "You don't have any active AI Portfolio yet, try one now and add it to your virtual wallet.",
    'it-IT':
      'Non hai ancora nessun AI Portfolio attivo, provane subito uno ed aggiungilo al tuo wallet virtuale.'
  },
  addButton: {
    'en-EN': 'Add',
    'it-IT': 'Aggiungi'
  },
  amount: {
    'en-EN': 'Current Amount',
    'it-IT': 'Portafoglio Attuale'
  },
  wallet: {
    'en-EN': 'Spot Wallet',
    'it-IT': 'Portafoglio Spot'
  },
  onHodlie: {
    'en-EN': 'Allocated',
    'it-IT': 'In Gestione'
  },
  capValue: {
    'en-EN': 'Value of capital allocated to Hodlie Strategies',
    'it-IT': 'Valore del capitale allocato sulle strategie di Hodlie'
  },
  onExchangePre: {
    'en-EN': 'Available capital on the ',
    'it-IT': 'Capitale disponibile sul Wallet '
  },
  onExchangePost: {
    'en-EN': ' Wallet',
    'it-IT': ''
  },
  available: {
    'en-EN': 'Available on ',
    'it-IT': 'Disponibile su '
  },
  name: {
    'en-EN': 'Name',
    'it-IT': 'Nome'
  },
  quantity: {
    'en-EN': 'Coin Quantity',
    'it-IT': 'Quantità'
  },
  equivalent: {
    'en-EN': 'Equivalent',
    'it-IT': 'Controvalore'
  },
  walletPerc: {
    'en-EN': 'Wallet Percentage',
    'it-IT': 'Percentuale'
  },
  invested: {
    'en-EN': 'Invested',
    'it-IT': 'Investito'
  },
  managed: {
    'en-EN': 'Managed',
    'it-IT': 'Gestito'
  },
  performance: {
    'en-EN': 'Performance',
    'it-IT': 'Performance'
  },
  total: {
    'en-EN': 'Total',
    'it-IT': 'Totale'
  },
  active: {
    'en-EN': 'active',
    'it-IT': 'attivo'
  },
  paused: {
    'en-EN': 'paused',
    'it-IT': 'pausa'
  },
  error: {
    'en-EN': 'error',
    'it-IT': 'errore'
  },
  avail: {
    'en-EN': 'Available',
    'it-IT': 'Disponibile'
  },
  riskLevel: {
    'en-EN': 'Risk Level',
    'it-IT': 'Livello di Rischio'
  },
  monthlyReturn: {
    'en-EN': 'Monthly Avg. Return',
    'it-IT': 'Rendimento medio mensile'
  },
  return: {
    'en-EN': 'Return',
    'it-IT': 'Rendimento'
  },
  filter: {
    'en-EN': 'Filter',
    'it-IT': 'Filtro'
  },
  order: {
    'en-EN': 'Order',
    'it-IT': 'Ordina'
  },
  generic: {
    'en-EN': 'General',
    'it-IT': 'Generico'
  },
  all: {
    'en-EN': 'All',
    'it-IT': 'Tutti'
  },
  asset: {
    'en-EN': 'Asset',
    'it-IT': 'Asset'
  },
  bot_statistics: {
    'en-EN': 'Statistics',
    'it-IT': 'Statistiche'
  },
  orders: {
    'en-EN': 'Orders',
    'it-IT': 'Ordini'
  },
  delete: {
    'en-EN': 'Delete',
    'it-IT': 'Elimina'
  },
  addBots: {
    'en-EN': 'Add a AI Agent to your wallet',
    'it-IT': 'Aggiungi un AI Agent al tuo wallet'
  },
  now: {
    'en-EN': 'Now',
    'it-IT': 'Adesso'
  },
  eventPending: {
    'en-EN':
      'An increase in capital is underway, the data for your AI Portfolio will be available shortly',
    'it-IT':
      'Un aumento di capitale è in corso, a breve saranno disponibili i dati del tuo AI Portfolio'
  },
  goToGuide: {
    'en-EN': 'Go to tutorial',
    'it-IT': 'Vai alla guida'
  },
  capitalAlignementInfo: {
    'en-EN':
      'To ensure the operational effectiveness of the strategy, the following asset reductions have been made: ',
    'it-IT':
      "Per garantire l'operatività della strategia sono state effettuate le seguenti diminuzioni di asset in gestione: "
  },
  capitalAlignementInfo_2: {
    'en-EN':
      'Due to the payment of the stamp duty on the Binance portfolio, the following asset reductions have been made: ',
    'it-IT':
      "A causa del pagamento dell'imposta di bollo sul portafoglio Binance sono state effettuate delle seguenti diminuzioni di asset in gestione: "
  },
  bolloText: {
    'en-EN':
      'This type of error may be due to the payment of the <b>imposta di bollo</b> on Binance. In this case, the operation of your AI Portfolio will be automatically restored by 31/01/2025. If you wish to avoid service interruptions, proceed by deleting and recreating the strategy now.',
    'it-IT':
      "Questo tipo di errore potrebbe essere dovuto al pagamento dell'<b>imposta di bollo</b> su Binance. In tal caso, l'operatività del tuo AI Portfolio sarà automaticamente ripristinata entro il 31/01/2025. Se desideri invece evitare interruzioni del servizio, procedi eliminando e ricreando la strategia ora."
  }
};

export const Wallet = {
  amount: {
    'en-EN': 'Amount',
    'it-IT': 'Quantità'
  },
  fee: {
    'en-EN': 'Fee Virtual Exchange',
    'it-IT': 'Commissione Exchange Virtuale'
  },
  feeText: {
    'en-EN': 'Fee applied in Virtual Mode',
    'it-IT': 'Commissione applicata in Modalità Virtuale'
  },
  bnbDiscountLabel: {
    'en-EN': 'BNB Discount',
    'it-IT': 'Sconto BNB'
  },
  bnbDiscountText: {
    'en-EN':
      'I agree to the use of BNB to take advantage of Binance fee discount. Option only for Binance Exchange',
    'it-IT':
      "Acconsento l'uso di BNB per usufruire delle scontistiche Binance. Opzionabile solo per l'Exchange Binance"
  },
  back: {
    'en-EN': 'Back',
    'it-IT': 'Indietro'
  },
  runBot: {
    'en-EN': 'Run',
    'it-IT': 'Avvia'
  },
  portfolioError: {
    'en-EN':
      'As custom investment strategies, you can activate only one AI Portfolio at a time.',
    'it-IT':
      'Attenzione: in quanto strategie di investimento su misura, puoi attivare al massimo un AI Portfolio.'
  },
  portfolioErrorNumber: {
    'en-EN': 'As custom investment strategies, you can activate only ',
    'it-IT': 'Attenzione: puoi creare al massimo '
  },
  max_bot_qty_reached: {
    'en-EN':
      'Warning: The maximum number of active strategies at the same time has been reached. Remove one before creating a new one.',
    'it-IT':
      'Attenzione: è stato raggiunto il numero massimo di strategie attive nello stesso momento. Eliminarne una prima di crearne una nuova.'
  },
  default_error: {
    'en-EN':
      'Warning: There was an error, please contact the support team at support@hodlie.net',
    'it-IT':
      "Attenzione: c'è stato un errore, contattare il team di supporto a support@hodlie.net"
  },
  available: {
    'en-EN': 'Available',
    'it-IT': 'Disponibile'
  },
  allocated: {
    'en-EN': 'Allocated',
    'it-IT': 'Già allocato'
  },
  chooseWallet: {
    'en-EN': 'Choose your Wallet',
    'it-IT': 'Seleziona il Wallet'
  },
  connectWallet: {
    'en-EN': 'Connect new Wallet',
    'it-IT': 'Aggiungi un Wallet'
  },
  incorrectWalletError: {
    'en-EN': 'Wallet not available',
    'it-IT': 'Wallet non disponibile'
  },
  incorrectLiquidityError: {
    'en-EN': 'Liquidity not available',
    'it-IT': 'Liquidità non disponibile'
  },
  minimum: {
    'en-EN': 'Minimum amount',
    'it-IT': 'Capitale minimo'
  },
  tos: {
    'en-EN':
      'I have read and accepted the <a href="/termsandconditions" target="_blank" rel="noreferrer">Terms & Conditions</a>',
    'it-IT':
      'Ho letto e accettato i <a href="/termsandconditions" target="_blank" rel="noreferrer">Termini e Condizioni</a>'
  },
  notEnoughAmount: {
    'en-EN':
      "You do not have enough liquidity on the Exchange. If you already have funds, you may need to convert your euros into crypto. See the <a href='https://app.hodlie.ai/convert-on-binance'>guide</a>.<br /><b>Required:</b> ",
    'it-IT':
      "Non hai liquidità sufficiente sull'Exchange. Se hai già dei fondi, potresti dover convertire i tuoi euro in crypto. Guarda qui la <a href='https://app.hodlie.ai/convert-on-binance'>guida</a>.<br /><b>Richiesto:</b> "
  },
  notTos: {
    'en-EN': 'To continue accept our Terms and Conditions',
    'it-IT': 'Per proseguire accetta i nostri Termini e Condizioni'
  },
  botCantStart: {
    'en-EN':
      'Want to unlock it? Go to the <a href="/rewards">referral</a> page and follow the procedure.',
    'it-IT':
      'Vuoi sbloccarlo? Vai alla pagina <a href="/rewards">referral</a> e segui la procedura.'
  },
  walletError: {
    'en-EN': `It's not possible to load your wallet. Please check the <a href="/wallet">wallet</a> page for more information`,
    'it-IT':
      'Non è possibile caricare il contenuto del tuo wallet. Controlla la pagina  <a href="/wallet">wallet</a> per maggiori informazioni.'
  },
  notCanRunRealCoin: {
    'en-EN': `Solana is available on Binance in preview for Private and PRO+. <a href="/pricing">Discover our subscription plans now!</a>`,
    'it-IT':
      'Solana è disponibile su Binance in anteprima per Private e PRO+. <a href="/pricing">Scopri ora i nostri piani di abbonamento!</a>'
  },
  addStrat: {
    'en-EN': 'Add a strategy',
    'it-IT': 'Aggiungi una strategia'
  },
  manage: {
    'en-EN': 'Manage your Wallet',
    'it-IT': 'Gestisci il tuo Wallet'
  },
  manageText: {
    'en-EN':
      'Go to your wallet management page to get an overview of the coins you own',
    'it-IT':
      'Vai alla pagina di gestione del tuo wallet per avere una panoramica delle coin in tuo possesso'
  },
  goto: {
    'en-EN': 'Go to Wallet',
    'it-IT': 'Vai al Wallet'
  },
  noWalletTitle: {
    'en-EN': 'You cannot launch your strategy yet',
    'it-IT': 'Non puoi ancora lanciare la tua strategia'
  },
  noWalletText: {
    'en-EN':
      'To start your first strategy, you must have completed all the steps first.',
    'it-IT':
      'Per avviare la tua prima strategia, devi prima aver completato tutti gli step.'
  },
  noWalletButton: {
    'en-EN': 'Resume from where you left off',
    'it-IT': 'Riprendi da dove hai lasciato'
  },
  noSubTitle: {
    'en-EN': 'You are just one step away!',
    'it-IT': 'Ti manca solo uno step!'
  },
  noSubText: {
    'en-EN':
      'To start your first strategy, you need to activate a subscription (the first 7 days are free!)',
    'it-IT':
      'Per avviare la tua prima strategia, è necessario attivare un abbonamento (i primi 7 giorni sono gratis!)'
  },
  noSubButton: {
    'en-EN': 'Start your subscription now',
    'it-IT': 'Avvia ora il tuo abbonamento'
  },
  buttonTextUpgradeFree: {
    'en-EN': 'Start your free trial',
    'it-IT': 'Inizia la prova gratuita'
  },
  otherPlans: {
    'en-EN': 'Discover other plans',
    'it-IT': 'Scopri gli altri piani'
  },
  investorDescr: {
    'en-EN':
      'For long-term investors, who want to entrust the management of their crypto portfolio to Hodlie',
    'it-IT':
      'Per investitori a lungo termine, che vogliono affidare la gestione del proprio portafoglio crypto a Hodlie'
  },
  explorerDescr: {
    'en-EN':
      'For investors who want to independently choose the assets in which to invest',
    'it-IT':
      'Per investitori che vogliono scegliere autonomamente gli asset su cui investire'
  },
  annual: {
    'en-EN': 'with annual payment',
    'it-IT': 'con pagamento annuale'
  },
  month: {
    'en-EN': 'month',
    'it-IT': 'mese'
  },
  includes: {
    'en-EN': 'Includes',
    'it-IT': 'Include'
  },
  add: {
    'en-EN': 'Add a AI Agent to your wallet',
    'it-IT': 'Aggiungi un AI Agent al tuo wallet'
  },
  addPortfolio: {
    'en-EN': 'Add a AI Portfolio to your wallet',
    'it-IT': 'Aggiungi un AI Portfolio al tuo wallet'
  },
  addBots: {
    'en-EN': 'Add a AI Agent to your wallet',
    'it-IT': 'Aggiungi un AI Agent al tuo wallet'
  },
  addText: {
    'en-EN':
      "You don't have any active strategy yet, try one now and add it to your wallet.",
    'it-IT':
      'Non hai ancora nessun AI Agent attivo, provane subito una ed aggiungila al tuo wallet.'
  },
  addTextPortfolio: {
    'en-EN':
      "You don't have any active AI Portfolio yet, try one now and add it to your wallet.",
    'it-IT':
      'Non hai ancora nessun AI Portfolio attivo, provane subito uno ed aggiungilo al tuo wallet.'
  },
  addButton: {
    'en-EN': 'Add',
    'it-IT': 'Aggiungi'
  },
  virtualMode: {
    'en-EN': 'Virtual Mode',
    'it-IT': 'Modalità Virtuale'
  },
  showMore: {
    'en-EN': 'show more',
    'it-IT': 'mostra di più'
  },
  chooseCoin: {
    'en-EN': 'Select your assets',
    'it-IT': 'Seleziona asset da gestire'
  },
  startTrialTitle: {
    'en-EN':
      'There are no sufficient assets in your Binance account to create your first AI Portfolio.',
    'it-IT':
      'Sul tuo account Binance non sono presenti assets sufficienti per creare il tuo primo AI Portfolio'
  },
  startTrialTitleKyc: {
    'en-EN':
      'While you wait to create your account on Binance, you can try the virtual mode.',
    'it-IT':
      'Mentre aspetti di creare il tuo account su Binance, puoi provare la modalità virtuale.'
  },
  startTrialTitleSmall: {
    'en-EN': '(minimum required capital $200)',
    'it-IT': '(capitale minimo richiesto $200)'
  },
  startTrialText: {
    'en-EN':
      "Follow our <a href='https://support.hodlie.ai/deposit-fiat-on-binance' target='_blank'>guide</a> to deposit. Meanwhile, you can try our virtual mode for free for 7 days. No debit/credit card required.",
    'it-IT':
      "Segui la nostra <a href='https://support.hodlie.ai/depositare-fiat-su-binance' target='_blank'>guida</a> per depositare. Nel frattempo puoi provare gratuitamente la nostra modalità virtuale per 7 giorni. Nessuna carta di debito/credito richiesta."
  },
  startTrialTextKyc: {
    'en-EN':
      "Don't know how to create your account on Binance? <a href='https://www.binance.com/' target='_blank'>Click here</a>.<br> Meanwhile, you can try our virtual mode free for 7 days. No debit/credit card required.",
    'it-IT':
      "Non sai come creare il tuo account su Binance? <a href='https://www.binance.com/' target='_blank'>Clicca qui</a>.<br> Nel frattempo puoi provare gratuitamente la nostra modalità virtuale per 7 giorni. Nessuna carta di debito/credito richiesta."
  },
  startTrialButton: {
    'en-EN': 'Try Virtual Mode',
    'it-IT': 'Prova la modalità virtuale'
  },
  managed: {
    'en-EN': 'Managed',
    'it-IT': 'Gestito'
  },
  botsTitle: {
    'en-EN': 'Select one or more Agents to add capital to your Portfolio',
    'it-IT':
      'Seleziona uno o più Agent per aggiungere capitale al tuo Portfolio'
  },
  botsTitle_add: {
    'en-EN': 'The following AI Agents will be added to your Portfolio',
    'it-IT': 'I seguenti AI Agent verranno aggiunti al tuo Portfolio'
  },
  infoBots: {
    'en-EN':
      'Selecting one or more Agents, they will be removed and the capital available to them will be transferred to the AI Portfolio you are creating. No action is required on your part.',
    'it-IT':
      "Selezionando uno o più Agents, questi saranno eliminati e il capitale a loro disposizione verrà spostato sull'AI Portfolio che stai creando. Non è necessaria alcuna azione da parte tua."
  },
  botPendingTitle: {
    'en-EN': 'Your AI Portfolio is being prepared!',
    'it-IT': 'Il tuo AI Portfolio è in fase di preparazione!'
  },
  botPendingText: {
    'en-EN':
      'You will receive an email within few minutes to confirm the successful creation of your Portfolio.',
    'it-IT':
      "Riceverai un'email entro qualche minuto per confermare la buona riuscita della creazione del tuo Portfolio."
  },
  waitText: {
    'en-EN': 'Please wait a few seconds...',
    'it-IT': 'Attendi qualche secondo...'
  }
};

export const WalletPage = {
  title: {
    'en-EN': 'Wallet Management',
    'it-IT': 'Gestione Wallet'
  },
  subtitle: {
    'en-EN': 'Configure and manage your wallet keys.',
    'it-IT': 'Configura e gestisci le chiavi del tuo portafoglio.'
  },
  amount: {
    'en-EN': 'Current Amount',
    'it-IT': 'Portafoglio Attuale'
  },
  wallet: {
    'en-EN': 'Spot Wallet',
    'it-IT': 'Portafoglio Spot'
  },
  onHodlie: {
    'en-EN': 'Allocated',
    'it-IT': 'In Gestione'
  },
  capValue: {
    'en-EN': 'Value of capital allocated to Hodlie Agents',
    'it-IT': 'Valore del capitale allocato sugli Agent di Hodlie'
  },
  connect: {
    'en-EN': 'Connect your Wallet using ',
    'it-IT': 'Connetti il tuo Wallet usando le chiavi API '
  },
  onExchangePre: {
    'en-EN': 'Available capital on the ',
    'it-IT': 'Capitale disponibile sul Wallet '
  },
  onExchangePost: {
    'en-EN': ' Wallet',
    'it-IT': ''
  },
  api: {
    'en-EN': ' API key',
    'it-IT': ''
  },
  bnbDiscountBadge: {
    'en-EN': 'BNB Discount',
    'it-IT': 'Sconto BNB'
  },
  follow: {
    'en-EN': 'Follow our ',
    'it-IT': 'Segui la nostra guida '
  },
  toConn: {
    'en-EN': ' guide to connect ',
    'it-IT': ' per connettere '
  },
  conn: {
    'en-EN': 'connect ',
    'it-IT': 'connettere '
  },
  convert: {
    'en-EN': 'convert ',
    'it-IT': 'convertire '
  },
  yourWall: {
    'en-EN': 'your wallet or upgrade your keys',
    'it-IT': 'il tuo portafoglio o aggiornare le tue chiavi'
  },
  connWall: {
    'en-EN': 'your wallet',
    'it-IT': 'il tuo portafoglio'
  },
  to: {
    'en-EN': ' to ',
    'it-IT': ' per '
  },
  deposit: {
    'en-EN': ' deposit',
    'it-IT': ' depositare'
  },
  or: {
    'en-EN': ' or ',
    'it-IT': ' o '
  },
  wall: {
    'en-EN': 'your wallet',
    'it-IT': 'il tuo portafoglio'
  },
  connectButton: {
    'en-EN': 'Verify your identity',
    'it-IT': 'Verifica la tua identità'
  },
  addKeys: {
    'en-EN': 'Add',
    'it-IT': 'Aggiungi'
  },
  changeKeys: {
    'en-EN': 'Change',
    'it-IT': 'Cambia'
  },
  remove: {
    'en-EN': 'Remove',
    'it-IT': 'Rimuovi'
  },
  connected: {
    'en-EN': 'Connected',
    'it-IT': 'Connesso'
  },
  error: {
    'en-EN': 'Error',
    'it-IT': 'Errore'
  },
  notConnected: {
    'en-EN': 'Not Connected',
    'it-IT': 'Non Connesso '
  },
  hear: {
    'en-EN': 'Want to hear more? Contact us at ',
    'it-IT': 'Vuoi saperne di più? Contattaci a '
  },
  contactSupport: {
    'en-EN':
      'We are verifying the documents you provided, and we will proceed to finalize your identity verification (KYC) as soon as possible. If you do not receive confirmation within 24 hours, please contact us at ',
    'it-IT':
      "Stiamo verificando i documenti che ci ha fornito e procederemo il prima possibile a finalizzare la tua verifica dell'identità (KYC). Se non dovessi ricevere la conferma entro 24 ore, ti preghiamo di contattaci a "
  },
  contactSupport1: {
    'en-EN': '',
    'it-IT': ''
  },
  processing: {
    'en-EN':
      'Your KYC is actually under review. Our team will verify your identity in the next few hours. In the meantime, you can proceed with the next step. We will contact you when your KYC will be verified.',
    'it-IT':
      "Il tuo KYC è attualmente in fase di revisione. Il nostro team si occuperà di verificarne l'idoneità a breve. Nel frattempo, puoi procedere con lo step successivo. Ti contatteremo solo se il tuo KYC non verrà verificato."
  },
  errorText: {
    'en-EN':
      'Your API key is not valid, check the restrictions and if the error persists, create a new one',
    'it-IT':
      "La tua API key non è valida, controlla le restizioni e se l'errore persiste creane una nuova"
  },
  hodlieUndermanagement: {
    'en-EN':
      'Contains the list of all the assets managed by Agents with the respective equivalent and percentage in the Hodlie wallet',
    'it-IT':
      'Contiene la lista di tutti gli asset gestiti dagli Agent con rispettivo controvalore e percentuale nel wallet di Hodlie'
  },
  exchangeUndermanagement: {
    'en-EN':
      'Contains the list of all the remaining assets on the exchange (among those with the highest capitalization), without counting the assets managed by Hodlie Agents. The data may not be updated in real time',
    'it-IT':
      "Contiene la lista degli asset rimanenti sull'exchange (tra quelli con più capitalizzazione), senza contare gli asset gestiti dagli Agent di Hodlie. I dati potrebbero non essere aggiornati in tempo reale"
  },
  hodlieText: {
    'en-EN': 'Assets managed by Hodlie strategies',
    'it-IT': 'Asset gestiti dalle strategie di Hodlie'
  },
  exchangeText: {
    'en-EN': 'Remaining assets on ',
    'it-IT': 'Asset rimanenti su '
  },
  available: {
    'en-EN': 'Available on ',
    'it-IT': 'Disponibile su '
  },
  name: {
    'en-EN': 'Name',
    'it-IT': 'Nome'
  },
  quantity: {
    'en-EN': 'Coin Quantity',
    'it-IT': 'Quantità'
  },
  equivalent: {
    'en-EN': 'Equivalent',
    'it-IT': 'Controvalore'
  },
  walletPerc: {
    'en-EN': 'Wallet Percentage',
    'it-IT': 'Percentuale'
  }
};

export const WizardLayout = {
  botParams: {
    'en-EN': 'Bot Parameters',
    'it-IT': 'Parametri del Bot'
  },
  done: {
    'en-EN': 'Done',
    'it-IT': 'Fatto'
  },
  profit: {
    'en-EN': 'Revenue',
    'it-IT': 'Profitto'
  },
  notAvailable: {
    'en-EN': 'NA',
    'it-IT': 'ND'
  },
  history: {
    'en-EN': 'Agent History',
    'it-IT': 'Storico Agent'
  },
  executing: {
    'en-EN': 'amount being updated...',
    'it-IT': 'capitale in aggiornamento...'
  }
};

export const PortfolioOrdersModal = {
  goBack: {
    'en-EN': 'Back',
    'it-IT': 'Indietro'
  }
};

export const PortfolioStatisticsModal = {
  goBack: {
    'en-EN': 'Back',
    'it-IT': 'Indietro'
  }
};

export const AddCapital = {
  add: {
    title: {
      'en-EN': 'Increase capital under management',
      'it-IT': 'Aumenta il capitale in gestione'
    }
  },
  pac: {
    title: {
      'en-EN': 'Set a recurring increase',
      'it-IT': 'Imposta un aumento ricorrente'
    }
  },
  back: {
    'en-EN': 'Back',
    'it-IT': 'Indietro'
  },
  continue: {
    'en-EN': 'Continue',
    'it-IT': 'Prosegui'
  },
  confirm: {
    'en-EN': 'Confirm',
    'it-IT': 'Conferma'
  },
  firstSectionTitle: {
    'en-EN': 'Choose the operation',
    'it-IT': 'Scegli l’operazione'
  },
  secondSectionTitle: {
    'en-EN': 'Select the strategy',
    'it-IT': 'Seleziona la strategia'
  },
  noPac: {
    'en-EN':
      'You have already set up a recurring increase for your Portfolio. To modify or delete it, go to Recurring Increase Details in your Portfolio information.',
    'it-IT':
      'Hai già impostato un aumento ricorrente per il tuo Portfolio. Per modificarlo o eliminarlo vai a Dettagli aumento ricorrente nelle informazioni del tuo Portfolio.'
  },
  thirdSectionTitle: {
    'en-EN': 'Set your saving plan',
    'it-IT': 'Imposta il tuo aumento ricorrente'
  },
  goToStart: {
    'en-EN': 'Start a strategy',
    'it-IT': 'Fai partire una strategia'
  },
  noBot: {
    'en-EN': 'You currently have no active strategy',
    'it-IT': 'Attualmente non possiedi alcuna strategia attiva'
  },
  managed: {
    'en-EN': 'Managed',
    'it-IT': 'Gestito'
  },
  active: {
    'en-EN': 'Active',
    'it-IT': 'Attivo'
  },
  paused: {
    'en-EN': 'Paused',
    'it-IT': 'Pausa'
  },
  error: {
    'en-EN': 'Error',
    'it-IT': 'Errore'
  },
  yourPersonal: {
    'en-EN': 'The saving plan for your AI Portfolio ',
    'it-IT': "L'aumento ricorrente per il tuo AI Portfolio "
  },
  success: {
    'en-EN':
      ' has been activated successfully. Within a few seconds the page will reload!',
    'it-IT':
      ' è stato attivato con successo. Entro pochi secondi la pagina verrà ricaricata!'
  },
  dashboardLink: {
    'en-EN': 'Come back to Dashboard',
    'it-IT': 'Torna alla Dashboard'
  },
  amount: {
    'en-EN': 'Amount',
    'it-IT': 'Quantità'
  },
  pacDisclaimer: {
    'en-EN':
      '*Hodlie takes care of setting up a recurring plan to increase capital under management. Please remember, however, that for correct operation, it is necessary to set the "Recurring Purchase" functionality directly on Binance, in order to always have a desired quantity of assets available.',
    'it-IT':
      '*Hodlie si occupa di impostare un piano ricorrente per aumentare il capitale in gestione. Si ricorda però che è necessario, per un corretto funzionamento, impostare la funzionalità di "Acquisto Ricorrente" direttamente su Binance, al fine di avere sempre una quantità desiderata di asset disponibili.'
  },
  monday: {
    'en-EN': 'Monday',
    'it-IT': 'Lunedì'
  },
  tuesday: {
    'en-EN': 'Tuesday',
    'it-IT': 'Martedì'
  },
  wednesday: {
    'en-EN': 'Wednesday',
    'it-IT': 'Mercoledì'
  },
  thursday: {
    'en-EN': 'Thursday',
    'it-IT': 'Giovedì'
  },
  friday: {
    'en-EN': 'Friday',
    'it-IT': 'Venerdì'
  },
  saturday: {
    'en-EN': 'Saturday',
    'it-IT': 'Sabato'
  },
  sunday: {
    'en-EN': 'Sunday',
    'it-IT': 'Domenica'
  },
  selectDay: {
    'en-EN': 'Select the day',
    'it-IT': 'Seleziona il giorno'
  },
  recurrence: {
    'en-EN': 'Recurrence',
    'it-IT': 'Ricorrenza'
  },
  monthly: {
    'en-EN': 'Monthly',
    'it-IT': 'Mensile'
  },
  weekly: {
    'en-EN': 'Weekly',
    'it-IT': 'Settimanale'
  },
  riskLevel: {
    'en-EN': 'Risk Level',
    'it-IT': 'Livello di Rischio'
  },
  minAmountError: {
    'en-EN': 'The amount entered must be greater than $50',
    'it-IT': 'La quantità inserita deve essere superiore a 50$'
  }
};

export const PortfolioRow = {
  avgReturn: {
    'en-EN': 'Monthly Avg. Return',
    'it-IT': 'Ritorno Medio Mensile'
  },
  from: {
    'en-EN': 'from',
    'it-IT': 'da'
  },
  to: {
    'en-EN': 'to ',
    'it-IT': 'a '
  },
  riskLevels: {
    'en-EN': 'Risk Levels',
    'it-IT': 'Livelli di Rischio'
  },
  minCap: {
    'en-EN': 'Minimum Capital',
    'it-IT': 'Capitale minimo'
  },
  bestPerformer: {
    'en-EN': 'Best performer this month',
    'it-IT': 'Best performer del mese'
  },
  bestPerformerMobile: {
    'en-EN': 'Best this month',
    'it-IT': 'Migliore questo mese'
  },
  bestPerformerMonth: {
    'en-EN': 'Best performer December',
    'it-IT': 'Best performer di Dicembre'
  },
  bestPerformerMobileMonth: {
    'en-EN': 'Best in December',
    'it-IT': 'Migliore di Dicembre'
  },
  return: {
    'en-EN': 'Return',
    'it-IT': 'Ritorno'
  }
};

export const PortfolioCol = {
  riskLevel: {
    'en-EN': 'Risk Level',
    'it-IT': 'Livelli di Rischio'
  },
  monthlyReturn: {
    'en-EN': 'Monthly Avg. Return',
    'it-IT': 'Rendimento medio mensile'
  },
  from: {
    'en-EN': 'from',
    'it-IT': 'da'
  },
  to: {
    'en-EN': 'to',
    'it-IT': 'a'
  }
};
